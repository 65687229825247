import React from 'react';
import { Row, Col, SimpleTable, SimpleCard, Heading } from '../index';
import { t } from '../../i18n';
import { formatNumber } from './utils';

const SubOrganizationLimitsTable = ({ data }) => (
  <SimpleCard>
    <Row>
      <Col style={{ marginRight: '12px' }}>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              {t('usage_history.ad_spend', { default: 'Ad spend' })}
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell style={{ textAlign: 'center' }}>
                <Heading spacing={0}>{formatNumber(data?.adSpend?.count, data?.currency)}</Heading>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
      <Col style={{ marginRight: '12px' }}>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              {t('usage_history.products_in_feed_exports', { default: 'Products in feed exports' })}
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell style={{ textAlign: 'center' }}>
                <Heading spacing={0}>{formatNumber(data?.productsInFeedExports?.count)}</Heading>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
      <Col style={{ marginRight: '12px' }}>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              {t('usage_history.domains_in_scraper', { default: 'Domains in Scraper' })}
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell style={{ textAlign: 'center' }}>
                <Heading spacing={0}>{formatNumber(data?.domainsInScraper?.count)}</Heading>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
      <Col>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              {t('usage_history.image_editor_for_feed_exports', { default: 'Image Editor for Feed Exports' })}
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell style={{ textAlign: 'center' }}>
                <Heading spacing={0}>{formatNumber(data?.imageEditorForFeedExports?.count)}</Heading>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
    </Row>
  </SimpleCard>
);

export default SubOrganizationLimitsTable;
