import React from 'react';
import { Row, Col, SimpleTable } from '../index';
import LimitBig from './LimitBig';
import { t } from '../../i18n';

const MotherOrganizationLimitsTable = ({ data }) => (
  <Row>
    <Col style={{ marginRight: '12px' }}>
      <SimpleTable>
        <SimpleTable.Head>
          <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
            {t('usage_history.ad_spend', { default: 'Ad spend' })}
          </SimpleTable.HeadCell>
        </SimpleTable.Head>
        <SimpleTable.Body>
          <SimpleTable.Row>
            <SimpleTable.Cell>
              <LimitBig data={data?.adSpend} currency={data?.currency} />
            </SimpleTable.Cell>
          </SimpleTable.Row>
        </SimpleTable.Body>
      </SimpleTable>
    </Col>
    <Col style={{ marginRight: '12px' }}>
      <SimpleTable>
        <SimpleTable.Head>
          <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
            {t('usage_history.products_in_feed_exports', { default: 'Products in feed exports' })}
          </SimpleTable.HeadCell>
        </SimpleTable.Head>
        <SimpleTable.Body>
          <SimpleTable.Row>
            <SimpleTable.Cell>
              <LimitBig data={data?.productsInFeedExports} />
            </SimpleTable.Cell>
          </SimpleTable.Row>
        </SimpleTable.Body>
      </SimpleTable>
    </Col>
    <Col style={{ marginRight: '12px' }}>
      <SimpleTable>
        <SimpleTable.Head>
          <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
            {t('usage_history.domains_in_scraper', { default: 'Domains in Scraper' })}
          </SimpleTable.HeadCell>
        </SimpleTable.Head>
        <SimpleTable.Body>
          <SimpleTable.Row>
            <SimpleTable.Cell>
              <LimitBig data={data?.domainsInScraper} />
            </SimpleTable.Cell>
          </SimpleTable.Row>
        </SimpleTable.Body>
      </SimpleTable>
    </Col>
    <Col>
      <SimpleTable>
        <SimpleTable.Head>
          <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
            {t('usage_history.image_editor_for_feed_exports', { default: 'Image Editor for Feed Exports' })}
          </SimpleTable.HeadCell>
        </SimpleTable.Head>
        <SimpleTable.Body>
          <SimpleTable.Row>
            <SimpleTable.Cell>
              <LimitBig data={data?.imageEditorForFeedExports} />
            </SimpleTable.Cell>
          </SimpleTable.Row>
        </SimpleTable.Body>
      </SimpleTable>
    </Col>
  </Row>
);

export default MotherOrganizationLimitsTable;
