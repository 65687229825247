import React from 'react';
import { Heading, Text, Row, Col, Icon, cssVariables, SimpleTable } from '../index';
import LimitSmall from './LimitSmall';
import { t } from '../../i18n';

const SubOrganizationsLimitsTable = ({ data }) => {
  const subOrganizationsData = data?.organizationsBreakdown || [];
  return (
    <>
      <Heading tag="h2" size="xl" spacing={24}>
        {t('usage_history.consolidated_sub_organization', { default: 'Usage breakdown for sub-organizations' })}
      </Heading>
      <SimpleTable>
        <SimpleTable.Head>
          <SimpleTable.HeadCell>
            <Row center>
              <Col shrink>
                <Icon kind="building" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textMuted }}>
                  {t('usage_history.organization', { default: 'Organization' })}
                </Text>
              </Col>
            </Row>
          </SimpleTable.HeadCell>
          <SimpleTable.HeadCell>
            <Row center>
              <Col shrink>
                <Icon kind="calendar" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textMuted }}>
                  {t('usage_history.ad_spend', { default: 'Ad spend' })}
                </Text>
              </Col>
            </Row>
          </SimpleTable.HeadCell>
          <SimpleTable.HeadCell>
            <Row center>
              <Col shrink>
                <Icon kind="feed-export" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textMuted }}>
                  {t('usage_history.products_in_feed_exports', { default: 'Products in feed exports' })}
                </Text>
              </Col>
            </Row>
          </SimpleTable.HeadCell>
          <SimpleTable.HeadCell>
            <Row center>
              <Col shrink>
                <Icon kind="earth" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textMuted }}>
                  {t('usage_history.domains_in_scraper', { default: 'Domains in Scraper' })}
                </Text>
              </Col>
            </Row>
          </SimpleTable.HeadCell>
          <SimpleTable.HeadCell>
            <Row center>
              <Col shrink>
                <Icon kind="image-feed" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textMuted }}>
                  {t('usage_history.image_editor_for_feed_exports', { default: 'Image Editor for Feed Exports' })}
                </Text>
              </Col>
            </Row>
          </SimpleTable.HeadCell>
        </SimpleTable.Head>
        <SimpleTable.Body>
          {subOrganizationsData.map(o => (
            <SimpleTable.Row key={o.name} firstCellBorder>
              <SimpleTable.Cell>
                <Text semibold style={{ color: cssVariables.interfaceCommonAccent }}>
                  <a href={`/organizations/${o.id}/subscription/usage_history`}>{o.name}</a>
                </Text>
              </SimpleTable.Cell>
              <SimpleTable.Cell>
                <LimitSmall
                  data={o.adSpend}
                  currency={data.currency}
                  motherOrganizationLimit={data?.adSpend?.allItemsCount}
                />
              </SimpleTable.Cell>
              <SimpleTable.Cell>
                <LimitSmall
                  data={o.productsInFeedExports}
                  motherOrganizationLimit={data?.productsInFeedExports?.allItemsCount}
                />
              </SimpleTable.Cell>
              <SimpleTable.Cell>
                <LimitSmall data={o.domainsInScraper} motherOrganizationLimit={data?.domainsInScraper?.allItemsCount} />
              </SimpleTable.Cell>
              <SimpleTable.Cell>
                <LimitSmall
                  data={o.imageEditorForFeedExports}
                  motherOrganizationLimit={data?.imageEditorForFeedExports?.allItemsCount}
                />
              </SimpleTable.Cell>
            </SimpleTable.Row>
          ))}
        </SimpleTable.Body>
      </SimpleTable>
    </>
  );
};

export default SubOrganizationsLimitsTable;
