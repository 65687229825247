/* eslint-disable no-trailing-spaces */
import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import cs from 'classnames';
import { isEqual, groupBy } from 'lodash';
import { getNestedFieldOptions } from './getNestedFieldOptions';
import { MainRenderer, FunnelRenderer } from './WrapperRenderers';
import { DndSortableContainer, DndSortableElement } from '../components/SortableDnd';
import { Tile, Button, Row, Col, SmallHeading } from '../components';
import AccommodationLocation from './AccommodationLocation';
import AdditionalFields from './FeedExportAdditionalFields';
import AdtextDescription from './AdtextDescription';
import AdtextDisplayLine from './AdtextDisplayLine';
import AdtextHeadline from './AdtextHeadline';
import arrayMove from '../utils/arrayMove';
import Certifications from './Certifications';
import Condition, { ConditionToggleButton } from './Condition';
import ConditionalLabel from './ConditionalLabel';
import ConditionsWithProductSetsAndItemGroups from './ConditionsWithProductSetsAndItemGroups';
import HeurekaParamPrefill from './HeurekaParamPrefill';
import CustomCondition from './CustomCondition';
import Delivery from './Delivery';
import FeedExportFacebookImage from './FeedExportFacebookImage';
import GoogleProductDetail from './GoogleProductDetail';
import GoogleProductHighlight from './GoogleProductHighlight';
import GoogleOtherShipping from './GoogleOtherShipping';
import GoogleLoyaltyProgram from './GoogleLoyaltyProgram';
import GoogleFreeShippingThreshold from './GoogleFreeShippingThreshold';
import FacebookGuestRating from './FacebookGuestRating';
import FacebookNeighborhood from './FacebookNeighborhood';
import FacebookType from './FacebookType';
import HeurekaParam from './HeurekaParam';
import CustomField from './CustomField';
import HeurekaService from './HeurekaService';
import HeurekaGift from './HeurekaGift';
import ImageAsset from './ImageAsset';
import ProductTag from './ProductTag';
import RegexpTemplateModal from './RegexpTemplateModal';
import RemapControllLine from './RemapControllLine';
import RemapMatch from './RemapMatch';
import Replace from './Replace';
import ScraperVariable from './ScraperVariable';
import ScraperWithWizardSelection from './ScraperWithWizardSelection';
import StoreAvailability from './StoreAvailability';
import TermPrices from './TermPrices';
import TourLocation from './TourLocation';
import TourPhotos from './TourPhotos';
import updatePreview from './updatePreview';
import VariationSpecific from './VariationSpecific';
import ExtractorVariable from './ExtractorVariable';
import PriceInclGroup from './PriceInclGroup';
import MallLabel from './MallLabel';
import SpartooSize from './SpartooSize';
import AukroParam from './AukroParam';
import AukroShipment from './AukroShipment';
import ShopCategory from './ShopCategory';
import SizeVariation from './SizeVariation';
import ConditionDescriptor from './ConditionDescriptor';
import Icon from '../components/Icon';
import { CONDITION_AND, CONDITION_OR } from '../components/ConditionsBadge';
import usePrevious from '../hooks/usePrevious';

const triggerFormChange = () => {
  const element = document.querySelector('[id^="campaign_setting_form_"] [name*="_conditions_"]');
  if (element) {
    // eslint-disable-next-line no-undef
    element.dispatchEvent(new Event('change', { bubbles: true }));
  }
};

const NestedComponentWrapper = (Component, opts = {}) => {
  const { hasPosition, hasDelimiter } = opts;
  const NestedComponentWrapperComponent = ({ position, formBase, field, id, _destroy, persisted, ...rest }) => {
    const getSubFiledOptions = getNestedFieldOptions({ index: position, formBase, field });
    const prevGroupType = usePrevious(rest.group_type);
    const prevNegative = usePrevious(rest.negative);

    if ((prevGroupType !== rest.group_type && rest.group_type && position === 0) || prevNegative !== rest.negative) {
      triggerFormChange();
    }

    return (
      <div className={cs('NestedFields-element', { 'NestedFields-element--delimiter': hasDelimiter })}>
        {rest.group_type && <input type="hidden" value={rest.group_type} {...getSubFiledOptions('group_type')} />}
        {(rest.negative === true || rest.negative === false) && (
          <input type="hidden" value={rest.negative.toString() || ''} {...getSubFiledOptions('negative')} />
        )}
        {rest.selected_item_group_id && (
          <input type="hidden" value={rest.selected_item_group_id} {...getSubFiledOptions('selected_item_group_id')} />
        )}
        {persisted && <input type="hidden" value={id} {...getSubFiledOptions('id')} />}
        {hasPosition && <input type="hidden" value={position} {...getSubFiledOptions('position')} />}
        {rest.group_id && <input type="hidden" value={rest.group_id} {...getSubFiledOptions('group_id')} />}
        <input type="hidden" value={_destroy ? 'true' : 'false'} {...getSubFiledOptions('_destroy')} />
        {!_destroy && (
          <Component
            id={id}
            formBase={formBase}
            position={position}
            {...rest}
            getSubFiledOptions={getSubFiledOptions}
          />
        )}
      </div>
    );
  };

  NestedComponentWrapperComponent.propTypes = {
    _destroy: PropTypes.bool,
    field: PropTypes.string,
    formBase: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    persisted: PropTypes.bool,
    position: PropTypes.number,
    group_id: PropTypes.string,
  };

  const FinalComponent = React.memo(
    hasPosition
      ? DndSortableElement(NestedComponentWrapperComponent, { hasDragHandle: true })
      : NestedComponentWrapperComponent,
    (prevProps, nextProps) => isEqual(prevProps, nextProps)
  );
  FinalComponent.WrapperRenderer = Component.WrapperRenderer;
  FinalComponent.hasPosition = hasPosition;
  return FinalComponent;
};

const Remap = NestedComponentWrapper(RemapMatch, { hasPosition: true });
Remap.WrapperRenderer = RemapControllLine;
const Conditions = NestedComponentWrapper(Condition);
Conditions.WrapperRenderer = ConditionsWithProductSetsAndItemGroups;
const Scraper = NestedComponentWrapper(ScraperVariable);
Scraper.WrapperRenderer = ScraperWithWizardSelection;

const ConditionalLabels = NestedComponentWrapper(ConditionalLabel, { hasPosition: true });
ConditionalLabels.WrapperRenderer = FunnelRenderer;

const FieldsMapping = {
  conditions: Conditions,
  dynamic_value_conditions: NestedComponentWrapper(Condition),
  conditional_values: ConditionalLabels,
  custom_conditions: NestedComponentWrapper(CustomCondition),
  replaces: NestedComponentWrapper(Replace, { hasPosition: true }),
  remap_match: Remap,
  remap_exact: Remap,
  adtext_headlines: NestedComponentWrapper(AdtextHeadline),
  fb_adtext_headlines: NestedComponentWrapper(AdtextDisplayLine),
  fb_adtext_primary_texts: NestedComponentWrapper(AdtextDisplayLine),
  display_headlines: NestedComponentWrapper(AdtextDisplayLine),
  adtext_descriptions: NestedComponentWrapper(AdtextDescription),
  display_descriptions: NestedComponentWrapper(AdtextDisplayLine),
  long_headlines: NestedComponentWrapper(AdtextDisplayLine),
  short_headlines: NestedComponentWrapper(AdtextDisplayLine),
  long_descriptions: NestedComponentWrapper(AdtextDisplayLine),
  videos: NestedComponentWrapper(AdtextDisplayLine),
  marketing_images: NestedComponentWrapper(ImageAsset),
  square_marketing_images: NestedComponentWrapper(ImageAsset),
  landscape_marketing_images: NestedComponentWrapper(ImageAsset),
  portrait_marketing_images: NestedComponentWrapper(ImageAsset),
  logo_images: NestedComponentWrapper(ImageAsset),
  landscape_logo_images: NestedComponentWrapper(ImageAsset),
  heureka_params: NestedComponentWrapper(HeurekaParam),
  custom_fields: NestedComponentWrapper(CustomField),
  scraper_variables: Scraper,
  product_details: NestedComponentWrapper(GoogleProductDetail),
  product_highlights: NestedComponentWrapper(GoogleProductHighlight),
  feed_export_facebook_images: NestedComponentWrapper(FeedExportFacebookImage),
  additional_fields: NestedComponentWrapper(AdditionalFields),
  structured_snippet_lines: NestedComponentWrapper(AdtextDisplayLine),
  callout_lines: NestedComponentWrapper(AdtextDisplayLine),
  facebook_neighborhoods: NestedComponentWrapper(FacebookNeighborhood),
  guest_ratings: NestedComponentWrapper(FacebookGuestRating),
  other_deliveries: NestedComponentWrapper(Delivery),
  other_shipping: NestedComponentWrapper(GoogleOtherShipping, { hasDelimiter: true }),
  facebook_types: NestedComponentWrapper(FacebookType),
  heureka_special_services: NestedComponentWrapper(HeurekaService),
  heureka_gifts: NestedComponentWrapper(HeurekaGift),
  variation_specifics: NestedComponentWrapper(VariationSpecific),
  condition_descriptors: NestedComponentWrapper(ConditionDescriptor),
  store_availability: NestedComponentWrapper(StoreAvailability),
  product_tags: NestedComponentWrapper(ProductTag),
  tour_locations: NestedComponentWrapper(TourLocation),
  tour_photos: NestedComponentWrapper(TourPhotos),
  term_prices: NestedComponentWrapper(TermPrices),
  accommodation_location_descs: NestedComponentWrapper(AccommodationLocation),
  extractor_variable: ExtractorVariable,
  price_incl_groups: NestedComponentWrapper(PriceInclGroup),
  labels: NestedComponentWrapper(MallLabel),
  spartoo_size: NestedComponentWrapper(SpartooSize),
  aukro_params: NestedComponentWrapper(AukroParam),
  aukro_shipments: NestedComponentWrapper(AukroShipment, { hasDelimiter: true }),
  shop_categories: NestedComponentWrapper(ShopCategory),
  certifications: NestedComponentWrapper(Certifications),
  size_variation: NestedComponentWrapper(SizeVariation, { hasDelimiter: true }),
  loyalty_program: NestedComponentWrapper(GoogleLoyaltyProgram, { hasDelimiter: true }),
  free_shipping_threshold: NestedComponentWrapper(GoogleFreeShippingThreshold),
};

const FieldsWithPosition = ['remap_match', 'replaces'];

const AdditionalContentMapping = {
  replaces: RegexpTemplateModal,
};

const AdditionalEndContentMapping = {};

const AdditionalButtonMapping = {
  heureka_params: HeurekaParamPrefill,
};

const getDefaultId = () => v4();

const addPosition = items => items.map((item, position) => (item.position === position ? item : { ...item, position }));

const getPersistedForItem = (item, doNotSetPersisted) => {
  if (item.id) {
    return typeof item.persisted === 'undefined' ? !doNotSetPersisted : item.persisted;
  }
  return false;
};

const ensureId = (item, opts = { doNotSetPersisted: false }) => {
  const persisted = getPersistedForItem(item, opts.doNotSetPersisted);
  const id = item.id || getDefaultId();

  if (item.id === id && item.persisted === persisted) {
    return item;
  }
  return { ...item, id, persisted };
};

const ensureDestroyIsBool = item => {
  if (typeof item._destroy === 'string') {
    return {
      ...item,
      _destroy: item._destroy === 'true' || item._destroy === 't' || item._destroy === '1',
    };
  }
  return item;
};

const DoNothingContainer = ({ children }) => children;

const emptyObjectKeys = Object.keys(ensureId({}));

const isEmptyField = field => {
  if (field.id === null) {
    // Invalid field - all fields needs to have ID set
    return true;
  }
  return isEqual(
    Object.keys(field).filter(
      f =>
        !(
          typeof field[f] === 'undefined' ||
          field[f] === null ||
          f === '_errors' ||
          f === '_error_details' ||
          f === '_error_fields' ||
          f === 'position' ||
          f === '_count' ||
          f === '_totalCount' ||
          f === 'group_id' ||
          f === 'group_type' ||
          f === 'negative' ||
          f === 'dynamic_value_aggregation_function' ||
          f === 'dynamic_value_selection' ||
          (Array.isArray(field[f]) && field[f].length === 0) ||
          (f === 'conditions' && getCurrentNotEmpty(field.conditions).length === 0)
        )
    ),
    emptyObjectKeys
  );
};

export const getCurrentNotEmpty = fieldData => fieldData.filter(field => !(isEmptyField(field) || field._destroy));

export const handleRemoveItem = (fieldData = [], id) => {
  const item = fieldData.find(fd => fd.id === id);
  let other = fieldData.filter(fd => fd.id !== id);

  if (item.persisted) {
    // need to set deleted flag
    other = [...other, { ...item, _destroy: true }];
  }
  return other;
};

export const handleRemoveItems = (fieldData = [], ids) => ids.reduce((acc, id) => handleRemoveItem(acc, id), fieldData);

export const clearAll = fieldData => fieldData.filter(x => x.persisted).map(x => ({ ...x, _destroy: true }));
class NestedFields extends React.PureComponent {
  static propTypes = {
    addNewFieldText: PropTypes.string.isRequired,
    addNextFieldText: PropTypes.string.isRequired,
    hideProductViewButton: PropTypes.bool,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    overrideComponent: PropTypes.string,
    fieldData: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onInputGroupAdded: PropTypes.func,
    onAdditionalStateChange: PropTypes.func,
    additionalState: PropTypes.any,
    formBase: PropTypes.string,
    options: PropTypes.object,
    disableAddNewField: PropTypes.bool,
    withTile: PropTypes.bool,
    isNestedInNested: PropTypes.bool,
    handleToggleOrAndParent: PropTypes.func,
    parentGroupType: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!isEqual(prevState.fieldData, this.state.fieldData) && typeof this.props.onChange === 'function') {
      this.props.onChange(this.state.fieldData);
    }
    if (
      !isEqual(prevState.additionalStateUpdated, this.state.additionalStateUpdated) &&
      this.props?.onInputGroupAdded
    ) {
      this.props.onInputGroupAdded(this.state.additionalStateUpdated);
    }
    // if the group type is changed in the parent component, toggle the group type
    if (prevProps?.parentGroupType !== this.props?.parentGroupType) {
      this.toggleGroupType();
    }
  };

  setMainInputRef = el => {
    if (el) {
      this.globalJsonInput = el;
    }
  };

  setAdditionalInputRef = el => {
    if (el) {
      this.globalJsonAdditionalInput = el;
    }
  };

  hasPosition = () => FieldsWithPosition.indexOf(this.props.overrideComponent || this.props.field) !== -1;

  setOptions = newOptions => {
    this.setState({ ...this.state, options: { ...this.state.options, ...newOptions } });
    updatePreview();
  };

  getMaxCount = () => this.props.options.max_count || -1;
  getCurrentCount = fieldData =>
    (fieldData || this.state.fieldData).reduce((acc, field) => acc + (field._destroy ? 0 : 1), 0);

  getCurrentNotEmptyCount = fieldData => this.getCurrentNotEmpty(fieldData).length;

  getDisabledRemoved = () =>
    this.props.isNestedInNested
      ? this.state.fieldData.length === 1
      : this.getCurrentNotEmpty(this.state.fieldData).length === 0 && this.state.fieldData.length === 1;

  getCurrentNotEmpty = fieldData => getCurrentNotEmpty(fieldData || this.state.fieldData);

  reachedMaxCount = fieldData => {
    const maxCount = this.getMaxCount();
    if (maxCount > 0 && maxCount === this.getCurrentCount(fieldData)) {
      return true;
    }
    return false;
  };

  shouldAddEmptyField = fieldData => {
    const { disable_auto_add, disable_auto_add_with_default_item } = this.props.options;
    if (disable_auto_add || (disable_auto_add_with_default_item && this.getCurrentCount(fieldData) >= 1)) {
      return false;
    }

    return (
      !(this.getMaxCount() > 0 && this.reachedMaxCount(fieldData)) &&
      !fieldData.filter(d => !d._destroy).find(d => isEmptyField(d))
    );
  };

  ensureOrGroup = data => {
    if (this.props.isNestedInNested && !data.group_id) {
      return { ...data, group_id: getDefaultId() };
    }
    if (this.props.options.enable_or_groups !== this.props.field || data.group_id) {
      return data;
    }
    return { ...data, group_id: getDefaultId() };
  };

  ensureEmptyRow = newFieldData =>
    !this.props.disableAddNewField && this.shouldAddEmptyField(newFieldData)
      ? newFieldData.concat([
          this.ensureOrGroup({
            ...ensureId(this.props.isNestedInNested ? { group_id: newFieldData[0]?.group_id } : {}),
            group_type: newFieldData[0]?.group_type || CONDITION_OR,
          }),
        ])
      : newFieldData;

  state = {
    fieldData: addPosition(
      this.ensureEmptyRow(
        this.props.fieldData
          .map(ensureId)
          .map(ensureDestroyIsBool)
          .map(this.ensureOrGroup)
      ).sort((a, b) => a.position - b.position)
    ),
    additionalState: this.props.additionalState || this.props.options.additional_state,
    additionalStateUpdated: {},
    computedData: {},
    itemGroups: this.props.options.item_groups,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isNestedInNested) {
      return;
    }
    if (this.props.fieldData !== nextProps.fieldData) {
      this.setState({
        fieldData: addPosition(
          this.ensureEmptyRow(nextProps.fieldData.map(ensureId).map(ensureDestroyIsBool)).sort(
            (a, b) => a.position - b.position
          )
        ).map(this.ensureOrGroup),
      });
    }

    if (JSON.stringify(this.props.additionalState) !== JSON.stringify(nextProps.additionalState)) {
      this.setState({ additionalState: nextProps.additionalState });
    }
  }

  handleUpdateItemGroup = itemGroup => {
    this.setState(prevState => ({
      itemGroups: prevState.itemGroups.map(ig => (ig.id === itemGroup.id ? itemGroup : ig)),
    }));
  };

  handleAddItemGroup = newItemGroup => {
    if (this.state.itemGroups.find(ig => ig.id === newItemGroup.id)) return;
    this.setState(prevState => ({
      itemGroups: [...prevState.itemGroups, newItemGroup],
    }));
  };

  addNewField = (data = {}, callback) => {
    this.addNewFieldWithGroupId(data, callback);
  };

  addNewFieldWithGroupId = (data = {}, callback) => {
    const defaultGroupType = this.state.fieldData[0]?.group_type;
    const newData = { group_type: defaultGroupType, ...data };

    if (this.getMaxCount() > 0 && this.reachedMaxCount()) {
      // eslint-disable-next-line no-console
      console.warn('Already added maximum count of items');
    } else if (this.getCurrentNotEmptyCount(this.state.fieldData) === 0) {
      this.setFieldDataState(
        [this.ensureOrGroup(ensureId(newData)), ...this.state.fieldData],
        undefined,
        undefined,
        callback
      );
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.getCurrentNotEmptyCount(this.state.fieldData) === 0) {
        this.setFieldDataState(
          [this.ensureOrGroup(ensureId(newData)), ...this.state.fieldData],
          undefined,
          undefined,
          callback
        );
      } else {
        const notEmptyData = this.getCurrentNotEmpty(this.state.fieldData);
        const emptyData = this.state.fieldData.filter(d => !notEmptyData.find(dd => dd.id === d.id));
        this.setFieldDataState(
          [...notEmptyData, this.ensureOrGroup(ensureId(newData)), ...emptyData],
          undefined,
          undefined,
          callback
        );
      }
    }
  };

  setFieldDataState = (newData, callback, disableEnsureEmptyRow = false, stateChangeCallback) => {
    const ensureIdData = newData.map(i => this.ensureOrGroup(ensureId(i, { doNotSetPersisted: true })));
    const dataWithPosition = addPosition(disableEnsureEmptyRow ? ensureIdData : this.ensureEmptyRow(ensureIdData));
    if (typeof callback === 'function') callback(dataWithPosition);
    this.setState({ fieldData: dataWithPosition }, stateChangeCallback);
    if (this.globalJsonInput) {
      this.globalJsonInput.value = JSON.stringify(this.getCurrentNotEmpty(dataWithPosition));
      this.globalJsonInput.dispatchEvent(new window.Event('change', { bubbles: true }));
    }
    updatePreview();
  };

  updateFieldData = (id, data) => {
    const { fieldData } = this.state;
    const original = fieldData.find(d => d.id === id);
    const index = fieldData.indexOf(original);
    const newFieldData = [...fieldData];
    newFieldData[index] = { ...original, ...data };
    this.setFieldDataState(newFieldData);
  };

  handleRemove = (id, field) => e => {
    e.preventDefault();

    if (field === 'replaces' && this.state.fieldData.length === 1) {
      return;
    }

    const newFieldData = Array.isArray(id)
      ? handleRemoveItems(this.state.fieldData, id)
      : handleRemoveItem(this.state.fieldData, id);

    this.setFieldDataState(newFieldData, null, true);

    updatePreview();
  };

  handleDuplicate = ids => {
    const group_id = getDefaultId();

    ids.forEach(id => {
      const item = this.state.fieldData.find(({ id: itemId }) => itemId === id) || {};
      this.addNewField({ ...item, id: undefined, group_id });
    });

    updatePreview();
  };

  handleToggleNegative = id => {
    this.setState(prev => {
      const index = prev.fieldData.findIndex(item => item.id === id);
      const newFieldData = [...prev.fieldData];
      newFieldData[index] = { ...prev.fieldData[index], negative: !prev.fieldData[index].negative };

      return {
        ...prev,
        fieldData: newFieldData,
      };
    });

    updatePreview();
  };

  toggleGroupType = () => {
    const { fieldData } = this.state;
    const groupType = fieldData[0]?.group_type;
    const newGroupType = groupType === CONDITION_AND ? CONDITION_OR : CONDITION_AND;
    this.setFieldDataState(
      fieldData.map(data => ({
        ...data,
        group_type: newGroupType,
        dynamic_value_conditions: data.dynamic_value_conditions?.map(dynamicValueCondition => ({
          ...dynamicValueCondition,
          group_type: newGroupType,
        })),
      }))
    );
  };

  handleToggleOrAnd = () => {
    // if has handler - group type is updated only in parent component, child is updated by componentDidUpdate
    if (typeof this.props.handleToggleOrAndParent === 'function') {
      this.props.handleToggleOrAndParent();
      return;
    }
    this.toggleGroupType();
  };

  connectAddNewFieldsToContainer = el => {
    if (el) {
      // eslint-disable-next-line no-param-reassign
      el.addNewField = this.addNewField;
    }
  };

  setComputedState = newComputed => {
    this.setState({ computedData: newComputed });
  };

  setAdditionalState = (additionalState, newIds, group_id) => {
    this.setState({ additionalState: { ...this.state.additionalState, ...additionalState } });
    if (typeof this.props.onAdditionalStateChange === 'function') {
      this.props.onAdditionalStateChange(additionalState);
    }

    if (this.globalJsonInput) {
      this.globalJsonInput.dispatchEvent(new window.Event('change', { bubbles: true }));
    }

    if (this.globalJsonAdditionalInput) {
      this.globalJsonAdditionalInput.value = JSON.stringify(additionalState);
      this.globalJsonAdditionalInput.dispatchEvent(new window.Event('change', { bubbles: true }));
    }

    if (newIds) {
      const setNewField = (index = 0) => {
        if (index < newIds.length) {
          this.addNewField({ selected_item_group_id: newIds[index], id: undefined, group_id }, () =>
            setNewField(index + 1)
          );
        }
      };
      setNewField();
    }

    updatePreview();
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setFieldDataState(arrayMove(this.state.fieldData, oldIndex, newIndex));
      Array.from(document.querySelectorAll('input[name$="[position]"]')).map(input =>
        input.dispatchEvent(new window.Event('change', { bubbles: true }))
      );
    }
  };

  render() {
    const {
      disabled,
      disableAddNewField,
      overrideComponent,
      field,
      formBase,
      options,
      addNextFieldText,
      addNewFieldText,
      children,
      hideProductViewButton,
      withTile,
    } = this.props;

    const { fieldData, additionalState, additionalStateUpdated } = this.state;

    const Component = FieldsMapping[overrideComponent || field];
    const SortableContainerWrapper = Component.hasPosition ? DndSortableContainer : DoNothingContainer;
    const WrapperRenderer = Component.WrapperRenderer || MainRenderer;
    const AdditionalContent = AdditionalContentMapping[overrideComponent || field];
    const AdditionalEndContent = AdditionalEndContentMapping[overrideComponent || field];
    const AdditionalButtons = AdditionalButtonMapping[overrideComponent || field];

    const activeFieldData = fieldData.filter(d => !d._destroy);
    const destroyedFieldData = fieldData.filter(d => d._destroy);
    const groupType = activeFieldData[0]?.group_type;

    const groupedActiveFieldData =
      options.enable_or_groups === this.props.field ? groupBy(activeFieldData, item => item.group_id) : {};

    return (
      <React.Fragment>
        {options.enable_global_input && (
          <input
            type="hidden"
            defaultValue={JSON.stringify(this.getCurrentNotEmpty(fieldData))}
            ref={this.setMainInputRef}
            name={`_${field}_`}
          />
        )}
        {options.enable_global_input && (
          <input
            type="hidden"
            ref={this.setAdditionalInputRef}
            name={`_additional_${field}_`}
            defaultValue={JSON.stringify(additionalState)}
          />
        )}
        {AdditionalContent && (
          <AdditionalContent
            formBase={formBase}
            field={field}
            additionalState={additionalState}
            setAdditionalState={this.setAdditionalState}
            options={options}
            setFieldDataState={this.setFieldDataState}
            fieldData={fieldData}
            activeFieldData={activeFieldData}
            overrideComponent={overrideComponent}
            addNewField={this.addNewField}
          />
        )}
        {children}

        <WrapperRenderer
          {...this.props}
          formBase={formBase}
          field={field}
          activeFieldData={activeFieldData}
          additionalState={additionalState}
          addNewField={!disableAddNewField && this.addNewField}
          addNewFieldText={fieldData.length > 0 ? addNextFieldText || addNewFieldText : addNewFieldText}
          connectAddNewFieldsToContainer={this.connectAddNewFieldsToContainer}
          disabled={disabled || this.reachedMaxCount()}
          fieldData={fieldData}
          getCurrentNotEmptyCount={this.getCurrentNotEmptyCount}
          getMaxCount={this.getMaxCount}
          options={options}
          hideProductViewButton={hideProductViewButton}
          setAdditionalState={this.setAdditionalState}
          setFieldDataState={this.setFieldDataState}
          computedData={this.state.computedData}
          setComputedState={this.setComputedState}
          withNormalAddButton={withTile}
          additionalButtons={
            AdditionalButtons && (
              <AdditionalButtons
                addNewField={this.addNewField}
                options={options}
                fieldData={fieldData}
                additionalState={additionalState}
                activeFieldData={activeFieldData}
                setFieldDataState={this.setFieldDataState}
              />
            )
          }
        >
          <SortableContainerWrapper onSortEnd={this.onSortEnd} items={activeFieldData.map(({ id }) => id)}>
            {(!WrapperRenderer?.maximumChildren || WrapperRenderer?.maximumChildren > fieldData.length) && (
              <React.Fragment>
                {destroyedFieldData.map(data => (
                  <Component
                    disabled={disabled}
                    field={field}
                    formBase={formBase}
                    index={data.position}
                    key={data.id}
                    options={options}
                    {...data}
                  />
                ))}
                {options.enable_or_groups === this.props.field
                  ? Object.keys(groupedActiveFieldData).map((key, groupIndex) => {
                      const groupIds = groupedActiveFieldData[key].map(({ id }) => id);

                      return (
                        <React.Fragment key={key}>
                          <Tile
                            className={cs('mb-8')}
                            data-test-id="condition-group"
                            smallSpaced
                            contentStyle={{
                              paddingLeft: '16px',
                              paddingBottom: options?.twoLine ? '16px' : '24px',
                            }}
                          >
                            <Row center className="mb-8">
                              <Col shrink>
                                <Icon kind="git-branch" className="Icon--grape" colored size="20px" inheritColor />
                              </Col>
                              <Col grow>
                                <SmallHeading spacing={0}>Where</SmallHeading>
                              </Col>
                              <Col shrink>
                                <Button
                                  icon="trash"
                                  tertiary
                                  onlyIcon
                                  type="button"
                                  onClick={this.handleRemove(groupIds)}
                                  className="ml-a"
                                  disabled={groupIds.length === 1 && Object.keys(groupedActiveFieldData).length === 1}
                                />
                              </Col>
                            </Row>
                            <div className="delimiter delimiter-black-light negative-ml-16 negative-mr-8 mb-16" />

                            {groupedActiveFieldData[key].map((data, index) => (
                              <Component
                                disableRemove={this.getDisabledRemoved()}
                                isLast={groupedActiveFieldData[key].length === index + 1}
                                isFirst={index === 0}
                                isOnlyOne={groupedActiveFieldData[key].length === 1}
                                addNewField={!disableAddNewField && this.addNewField}
                                disabled={disabled}
                                field={field}
                                formBase={formBase}
                                index={data.position}
                                key={data.id}
                                onRemove={this.handleRemove(data.id)}
                                options={options}
                                updateFieldData={this.updateFieldData}
                                fieldData={fieldData}
                                additionalState={additionalState}
                                setOptions={this.setOptions}
                                computedData={this.state.computedData[data.id]}
                                onToggleOrAnd={this.handleToggleOrAnd}
                                group_type={data.group_type}
                                group_id={data.group_id}
                                setAdditionalState={this.setAdditionalState}
                                toggleNegative={this.handleToggleNegative}
                                additionalStateUpdated={additionalStateUpdated}
                                parentGroupType={this.state?.fieldData[0]?.group_type}
                                itemGroups={this.state.itemGroups}
                                onItemGroupsUpdate={this.handleUpdateItemGroup}
                                onItemGroupsAdd={this.handleAddItemGroup}
                                setAdditionalStateUpdated={onChange => {
                                  this.setState(prev => ({
                                    ...prev,
                                    additionalStateUpdated: onChange(prev.additionalStateUpdated),
                                  }));
                                }}
                                {...data}
                              />
                            ))}
                          </Tile>
                          {Object.keys(groupedActiveFieldData).length !== groupIndex + 1 && (
                            <Col
                              center
                              justifyCenter
                              className="negative-mt-8"
                              data-test-id="switch-between-condition-groups"
                            >
                              <div className="pt-16 border-left border-color-soft-gray" />
                              <div className="mv-4" style={{ marginLeft: options?.twoLine ? '0' : '1px' }}>
                                <ConditionToggleButton
                                  condition={groupType}
                                  onToggle={this.handleToggleOrAnd}
                                  isReverse
                                  direction={options?.twoLine ? 'column' : 'row'}
                                />
                              </div>
                              <div className="pb-16 border-left border-color-soft-gray" />
                            </Col>
                          )}
                        </React.Fragment>
                      );
                    })
                  : activeFieldData.map((data, index) => (
                      <Component
                        disableRemove={this.getDisabledRemoved()}
                        isLast={activeFieldData.length === index + 1}
                        addNewField={!disableAddNewField && this.addNewField}
                        disabled={
                          disabled || (options?.disable_auto_add_with_default_item && activeFieldData.length === 1)
                        }
                        field={field}
                        formBase={formBase}
                        index={data.position}
                        key={data.id}
                        onRemove={this.handleRemove(data.id, field)}
                        itemsToRender={activeFieldData.length}
                        options={options}
                        updateFieldData={this.updateFieldData}
                        fieldData={fieldData}
                        additionalState={additionalState}
                        setAdditionalState={this.setAdditionalState}
                        setOptions={this.setOptions}
                        computedData={this.state.computedData[data.id]}
                        withTile={withTile}
                        onToggleOrAnd={this.handleToggleOrAnd}
                        toggleNegative={this.handleToggleNegative}
                        group_type={data.group_type}
                        {...data}
                      />
                    ))}
              </React.Fragment>
            )}
          </SortableContainerWrapper>
        </WrapperRenderer>

        {AdditionalEndContent && (
          <AdditionalEndContent
            addNewField={this.addNewField}
            options={options}
            fieldData={fieldData}
            additionalState={additionalState}
            activeFieldData={activeFieldData}
            setFieldDataState={this.setFieldDataState}
          />
        )}
      </React.Fragment>
    );
  }
}

export default NestedFields;
