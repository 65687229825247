import React, { useState } from 'react';
import { SimpleChip, SimpleTooltip } from '../index';

const ChipBoxes = ({ input_name, chips = {}, selected, tooltips = [] }) => {
  const [values, setValues] = useState(selected);

  const clickHandler = key => {
    if (values.includes(key)) {
      setValues(values.filter(val => val !== key));
    } else {
      setValues([...values, key]);
    }
  };

  return (
    <>
      {Object.entries(chips).map(([key]) => (
        <input
          key={key}
          type="checkbox"
          name={input_name}
          value={key}
          checked={values.includes(key)}
          style={{ display: 'none' }}
        />
      ))}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
        {Object.entries(chips).map(([key, value]) => {
          const chipsTooltip = tooltips.find(tooltip => tooltip.key === key);
          return (
            <SimpleTooltip key={key} text={chipsTooltip?.text || ''}>
              <SimpleChip
                kind="primary"
                label={value}
                iconRight={chipsTooltip ? 'info' : ''}
                selected={values.includes(key)}
                onClick={() => {
                  clickHandler(key);
                }}
              />
            </SimpleTooltip>
          );
        })}
      </div>
    </>
  );
};

export default ChipBoxes;
