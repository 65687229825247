import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { addDays, startOfDay, format } from 'date-fns';
import { Heading, Text, Row, Col, cssVariables, InfoBox, RangeDatePicker } from '../index';
import MotherOrganizationLimitsTable from './MotherOrganizationLimitsTable';
import SubOrganizationsLimitsTable from './SubOrganizationsLimitsTable';
import SubOrganizationLimitsTable from './SubOrganizationLimitsTable';
import LoadingState from './LoadingState';
import { t } from '../../i18n';
import { mapDataForUsageHistory } from './utils';

const today = new Date();
today.setHours(0, 0, 0, 0);

const GET_USAGE_HISTORY_QUERY = gql`
  query GetFeaturesUsageHistory($organizationId: BigInt!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    organization(id: $organizationId) {
      id
      name
      parentId
      featuresUsageHistory(startDate: $startDate, endDate: $endDate) {
        adSpend {
          limit
          total
        }
        productsInFeedExports {
          limit
          total
        }
        domainsInScraper {
          limit
          total
        }
        imageEditorForFeedExports {
          limit
          total
        }
        currency
        differentSubscriptionsForDateRange
        organizationsBreakdown {
          organization {
            id
            name
          }
          adSpend {
            limit
            total
          }
          productsInFeedExports {
            limit
            total
          }
          domainsInScraper {
            limit
            total
          }
          imageEditorForFeedExports {
            limit
            total
          }
        }
      }
    }
  }
`;

const UsageHistoryPage = ({ organization_id, organization_name, start_date, end_date }) => {
  const defaultPeriod = {
    startDate: new Date(start_date),
    endDate: new Date(end_date),
    key: 'selection',
  };

  if (defaultPeriod.endDate > today) {
    defaultPeriod.endDate = today;
  }

  const [period, setPeriod] = useState([defaultPeriod]);
  const { data, loading } = useQuery(GET_USAGE_HISTORY_QUERY, {
    variables: {
      organizationId: organization_id,
      startDate: format(period[0]?.startDate, 'yyyy-MM-dd'),
      endDate: format(period[0]?.endDate, 'yyyy-MM-dd'),
    },
  });

  const resetPeriod = () => {
    setPeriod([defaultPeriod]);
  };

  const feauturesUsageHistoryData = data?.organization?.featuresUsageHistory;
  const mappedData = mapDataForUsageHistory(feauturesUsageHistoryData);
  const isSubOrganization = data?.organization?.parentId !== null;

  return (
    <>
      <Row justifyBetween center>
        <Col shrink>
          <Heading spacing={0}>{t('usage_history.page_title', { default: 'Usage History' })}</Heading>
        </Col>
        <Col shrink>
          <Row center>
            <Col shrink>
              <Text style={{ color: cssVariables.textMuted }}>
                {t('usage_history.datepicker_label', { default: 'Display data for:' })}
              </Text>
            </Col>
            <Col shrink>
              <RangeDatePicker
                minDate={addDays(new Date(), -730)}
                maxDate={startOfDay(new Date())}
                defaultPeriod={defaultPeriod}
                period={period}
                onChange={setPeriod}
                onReset={resetPeriod}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {data?.differentSubscriptionsForDateRange && (
        <div className="mt-24">
          <InfoBox withIcon type="warning">
            <Text>
              {t('usage_history.different_limits_warning', {
                default:
                  'The selected time period spans multiple subscription periods with different limits. As a result, we are unable to display the percentage usage accurately. To view this information, please select a time period that falls within a single subscription period with consistent limits.',
              })}
            </Text>
          </InfoBox>
        </div>
      )}

      <div className="mv-24 delimeter dashed" />
      <Heading tag="h2" size="xl" spacing={24}>
        {t('usage_history.overview', { default: 'Feature usage overview for ' })}
        {organization_name}
      </Heading>

      {loading && <LoadingState />}

      {mappedData && !loading && (
        <>
          {!isSubOrganization && (
            <div className="mb-24">
              <MotherOrganizationLimitsTable data={mappedData} />
            </div>
          )}
          {isSubOrganization && <SubOrganizationLimitsTable data={mappedData} />}
          {!!mappedData?.organizationsBreakdown?.length && !isSubOrganization && (
            <SubOrganizationsLimitsTable data={mappedData} />
          )}
        </>
      )}
    </>
  );
};

export default UsageHistoryPage;
