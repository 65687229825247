/* eslint-disable react/no-danger */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Icon } from '../index';

const SimpleTooltip = ({ children, className, infoIcon, placement = 'top', text }) => {
  const [mounted, setMounted] = useState(false); // Ensures mounting before positioning
  const [visible, setVisible] = useState(false); // Controls fade-in effect
  const [tooltipStyle, setTooltipStyle] = useState({ top: 0, left: 0, opacity: 0 });
  const componentRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const component = componentRef.current;

    let showTimeout;
    let hideTimeout;

    const showTooltip = () => {
      setMounted(true); // Mount immediately
      showTimeout = setTimeout(() => {
        setVisible(true); // Fade in after 250ms
      }, 10); // Small timeout so it doesn’t animate on first frame
    };

    const hideTooltip = () => {
      clearTimeout(showTimeout);
      setVisible(false); // Start fade out
      hideTimeout = setTimeout(() => setMounted(false), 300); // Unmount after fade-out
    };

    if (component) {
      component.addEventListener('mouseenter', showTooltip);
      component.addEventListener('mouseleave', hideTooltip);
    }

    return () => {
      if (component) {
        component.removeEventListener('mouseenter', showTooltip);
        component.removeEventListener('mouseleave', hideTooltip);
      }
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);

  // Step 2: Measure Tooltip & Correct Its Position
  useEffect(() => {
    if (mounted && tooltipRef.current && componentRef.current) {
      const tooltip = tooltipRef.current;
      const component = componentRef.current;

      window.requestAnimationFrame(() => {
        const rect = component.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();
        const bodyWidth = document.documentElement.clientWidth; // Better viewport handling

        const positions = {
          top: {
            left: rect.left + window.scrollX + rect.width / 2 - tooltipRect.width / 2,
            top: rect.top + window.scrollY - tooltipRect.height - 5, // Ensuring spacing
          },
          bottom: {
            left: rect.left + window.scrollX + rect.width / 2 - tooltipRect.width / 2,
            top: rect.bottom + window.scrollY + 5,
          },
          left: {
            left: rect.left + window.scrollX - tooltipRect.width - 5,
            top: rect.top + window.scrollY + rect.height / 2 - tooltipRect.height / 2,
          },
          right: {
            left: rect.right + window.scrollX + 5,
            top: rect.top + window.scrollY + rect.height / 2 - tooltipRect.height / 2,
          },
        };

        let { top, left } = positions[placement];

        // Ensure tooltip stays within the viewport
        if (left < 5) {
          left = 5;
        } else if (left + tooltipRect.width > bodyWidth) {
          left = bodyWidth - tooltipRect.width - 5;
        }

        if (top < 5) {
          top = 5;
        }

        setTooltipStyle({ top, left, opacity: 1 }); // Step 3: Apply corrected position
        setVisible(true);
      });
    }
  }, [mounted, placement]);

  return (
    <span
      className={className}
      ref={componentRef}
      style={{
        position: 'relative',
        display: 'inline-flex',
        width: 'fit-content',
      }}
    >
      {infoIcon && <Icon kind="info-circle-fill" size="24px" className="SimpleTooltip-infoIcon" />}
      {!infoIcon && children}
      {mounted &&
        text &&
        createPortal(
          <div
            ref={tooltipRef}
            className={`SimpleTooltip SimpleTooltip--${placement} ${visible ? 'SimpleTooltip--visible' : ''}`}
            style={{
              position: 'absolute',
              top: tooltipStyle.top,
              left: tooltipStyle.left,
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />,
          document.body
        )}
    </span>
  );
};

SimpleTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  text: PropTypes.string,
  infoIcon: PropTypes.bool,
};

export default SimpleTooltip;
