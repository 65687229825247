import React from 'react';
import { Row, Col, SimpleTable } from '../index';
import { GrayBox } from '../table/LoadingTable';

const LoadingState = () => (
  <>
    <Row>
      <Col style={{ marginRight: '12px' }}>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              <Row justifyCenter>
                <GrayBox width="54px" height="14px" />
              </Row>
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell>
                <Row justifyCenter padding="l">
                  <Col shrink>
                    <div className="loader-skeleton-circle" />
                  </Col>
                  <Col shrink>
                    <Row style={{ marginBottom: '4px' }}>
                      <Col shrink>
                        <GrayBox width="20px" height="23px" withoutMargin />
                      </Col>
                      <Col shrink>
                        <GrayBox width="72px" height="23px" withoutMargin />
                      </Col>
                    </Row>
                    <Row>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="12px" height="13px" withoutMargin />
                      </Col>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="24px" height="13px" withoutMargin />
                      </Col>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="24px" height="13px" withoutMargin />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
      <Col style={{ marginRight: '12px' }}>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              <Row justifyCenter>
                <GrayBox width="140px" height="14px" />
              </Row>
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell>
                <Row justifyCenter padding="l">
                  <Col shrink>
                    <div className="loader-skeleton-circle" />
                  </Col>
                  <Col shrink>
                    <Row style={{ marginBottom: '4px' }}>
                      <Col shrink>
                        <GrayBox width="20px" height="23px" withoutMargin />
                      </Col>
                      <Col shrink>
                        <GrayBox width="72px" height="23px" withoutMargin />
                      </Col>
                    </Row>
                    <Row>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="12px" height="13px" withoutMargin />
                      </Col>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="24px" height="13px" withoutMargin />
                      </Col>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="24px" height="13px" withoutMargin />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
      <Col style={{ marginRight: '12px' }}>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              <Row justifyCenter>
                <GrayBox width="111px" height="14px" />
              </Row>
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell>
                <Row justifyCenter padding="l">
                  <Col shrink>
                    <div className="loader-skeleton-circle" />
                  </Col>
                  <Col shrink>
                    <Row style={{ marginBottom: '4px' }}>
                      <Col shrink>
                        <GrayBox width="20px" height="23px" withoutMargin />
                      </Col>
                    </Row>
                    <Row>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="12px" height="13px" withoutMargin />
                      </Col>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="24px" height="13px" withoutMargin />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
      <Col>
        <SimpleTable>
          <SimpleTable.Head>
            <SimpleTable.HeadCell style={{ textAlign: 'center' }}>
              <Row justifyCenter>
                <GrayBox width="169px" height="14px" />
              </Row>
            </SimpleTable.HeadCell>
          </SimpleTable.Head>
          <SimpleTable.Body>
            <SimpleTable.Row>
              <SimpleTable.Cell>
                <Row justifyCenter padding="l">
                  <Col shrink>
                    <div className="loader-skeleton-circle" />
                  </Col>
                  <Col shrink>
                    <Row style={{ marginBottom: '4px' }}>
                      <Col shrink>
                        <GrayBox width="20px" height="23px" withoutMargin />
                      </Col>
                    </Row>
                    <Row>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="12px" height="13px" withoutMargin />
                      </Col>
                      <Col shrink style={{ marginRight: '2px' }}>
                        <GrayBox width="24px" height="13px" withoutMargin />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SimpleTable.Cell>
            </SimpleTable.Row>
          </SimpleTable.Body>
        </SimpleTable>
      </Col>
    </Row>
    <div className="mv-24 delimeter dashed" />
    <Row className="mb-16">
      <Col shrink>
        <GrayBox width="20px" height="19px" withoutMargin />
      </Col>
      <Col shrink>
        <GrayBox width="110px" height="19px" withoutMargin />
      </Col>
      <Col shrink>
        <GrayBox width="150px" height="19px" withoutMargin />
      </Col>
    </Row>
    <SimpleTable>
      <SimpleTable.Head>
        <SimpleTable.HeadCell width="132px">
          <Row center>
            <Col shrink>
              <GrayBox width="20px" height="20px" withoutMargin />
            </Col>
            <Col shrink>
              <GrayBox width="72px" height="20px" withoutMargin />
            </Col>
          </Row>
        </SimpleTable.HeadCell>
        <SimpleTable.HeadCell>
          <Row center>
            <Col shrink>
              <GrayBox width="20px" height="20px" withoutMargin />
            </Col>
            <Col shrink>
              <GrayBox width="54px" height="20px" withoutMargin />
            </Col>
          </Row>
        </SimpleTable.HeadCell>
        <SimpleTable.HeadCell>
          <Row center>
            <Col shrink>
              <GrayBox width="20px" height="20px" withoutMargin />
            </Col>
            <Col shrink>
              <GrayBox width="140px" height="20px" withoutMargin />
            </Col>
          </Row>
        </SimpleTable.HeadCell>
        <SimpleTable.HeadCell>
          <Row center>
            <Col shrink>
              <GrayBox width="20px" height="20px" withoutMargin />
            </Col>
            <Col shrink>
              <GrayBox width="111px" height="20px" withoutMargin />
            </Col>
          </Row>
        </SimpleTable.HeadCell>
        <SimpleTable.HeadCell>
          <Row center>
            <Col shrink>
              <GrayBox width="20px" height="20px" withoutMargin />
            </Col>
            <Col shrink>
              <GrayBox width="169px" height="20px" withoutMargin />
            </Col>
          </Row>
        </SimpleTable.HeadCell>
      </SimpleTable.Head>
      <SimpleTable.Body>
        {[1, 2, 3, 4].map(o => (
          <SimpleTable.Row key={o.name} firstCellBorder>
            <SimpleTable.Cell>
              <GrayBox width="100px" height="13px" withoutMargin />
            </SimpleTable.Cell>
            <SimpleTable.Cell>
              <Row justifyCenter padding="l">
                <Col shrink justifyCenter>
                  <div className="loader-skeleton-circle small" style={{ margin: '0 auto 4px' }} />
                  <GrayBox width="23px" height="13px" withoutMargin />
                </Col>
                <Col shrink>
                  <Row style={{ marginBottom: '4px' }}>
                    <Col shrink>
                      <GrayBox width="20px" height="18px" withoutMargin />
                    </Col>
                    <Col shrink>
                      <GrayBox width="53px" height="18px" withoutMargin />
                    </Col>
                  </Row>
                  <Row>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="12px" height="13px" withoutMargin />
                    </Col>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="24px" height="13px" withoutMargin />
                    </Col>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="24px" height="13px" withoutMargin />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SimpleTable.Cell>
            <SimpleTable.Cell>
              <Row justifyCenter padding="l">
                <Col shrink justifyCenter>
                  <div className="loader-skeleton-circle small" style={{ margin: '0 auto 4px' }} />
                  <GrayBox width="23px" height="13px" withoutMargin />
                </Col>
                <Col shrink>
                  <Row style={{ marginBottom: '4px' }}>
                    <Col shrink>
                      <GrayBox width="20px" height="18px" withoutMargin />
                    </Col>
                    <Col shrink>
                      <GrayBox width="53px" height="18px" withoutMargin />
                    </Col>
                  </Row>
                  <Row>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="12px" height="13px" withoutMargin />
                    </Col>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="24px" height="13px" withoutMargin />
                    </Col>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="24px" height="13px" withoutMargin />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SimpleTable.Cell>
            <SimpleTable.Cell>
              <Row justifyCenter padding="l">
                <Col shrink justifyCenter>
                  <div className="loader-skeleton-circle small" style={{ margin: '0 auto 4px' }} />
                  <GrayBox width="23px" height="13px" withoutMargin />
                </Col>
                <Col shrink>
                  <Row style={{ marginBottom: '4px' }}>
                    <Col shrink>
                      <GrayBox width="20px" height="18px" withoutMargin />
                    </Col>
                  </Row>
                  <Row>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="12px" height="13px" withoutMargin />
                    </Col>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="24px" height="13px" withoutMargin />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SimpleTable.Cell>
            <SimpleTable.Cell>
              <Row justifyCenter padding="l">
                <Col shrink justifyCenter>
                  <div className="loader-skeleton-circle small" style={{ margin: '0 auto 4px' }} />
                  <GrayBox width="23px" height="13px" withoutMargin />
                </Col>
                <Col shrink>
                  <Row style={{ marginBottom: '4px' }}>
                    <Col shrink>
                      <GrayBox width="20px" height="18px" withoutMargin />
                    </Col>
                  </Row>
                  <Row>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="12px" height="13px" withoutMargin />
                    </Col>
                    <Col shrink style={{ marginRight: '2px' }}>
                      <GrayBox width="24px" height="13px" withoutMargin />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SimpleTable.Cell>
          </SimpleTable.Row>
        ))}
      </SimpleTable.Body>
    </SimpleTable>
  </>
);

export default LoadingState;
