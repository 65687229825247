import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Manager, Popper, Reference } from 'react-popper';
import { Row, Col, Icon, cssVariables, Text } from '../index';
import { Loader } from '../Icons';

const DatePickerMenu = ({
  buttonText,
  dataTestId,
  hideOnClick = true,
  children,
  disabled = false,
  loading = false,
  maxHeight,
  minWidth,
  open = false,
  onReset,
  placement = 'bottom-start',
}) => {
  const containerRef = useRef(null);
  const popperElRef = useRef(null);
  const toggleElRef = useRef(null);
  const [show, setShow] = useState(open);

  const handleOutsideClick = useCallback(
    e => {
      if (
        !hideOnClick &&
        popperElRef.current &&
        toggleElRef.current &&
        !popperElRef.current.contains(e.target) &&
        !toggleElRef.current.contains(e.target)
      ) {
        setShow(false);
      } else if (hideOnClick && toggleElRef.current && !toggleElRef.current.contains(e.target)) {
        setShow(false);
      }
    },
    [popperElRef, toggleElRef, setShow, hideOnClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleMenuClick = e => {
    const action = e.target.dataset.action;
    if (action === 'close') {
      setShow(false);
    }
  };

  return (
    <span
      ref={el => {
        containerRef.current = el?.closest('.modal') || document.body;
      }}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className={cs('DatePickerMenu', {
                open: show,
              })}
              ref={el => {
                ref(el);
                toggleElRef.current = el;
              }}
              onClick={() => !disabled && setShow(!show)}
            >
              <div
                className={cs('DatePickerMenu-button', {
                  disabled,
                })}
                data-test-id={dataTestId}
                role="button"
                tabIndex={0}
              >
                <Row center>
                  <Col shrink>
                    <Icon kind="calendar" size="20px" color={cssVariables.iconSubtle} />
                  </Col>
                  <Col grow nowrap>
                    <Text
                      style={{
                        color: buttonText ? cssVariables.interactiveFormIntense : cssVariables.interactiveFormMuted,
                      }}
                    >
                      {buttonText || 'Select Date'}
                    </Text>
                  </Col>
                  {!loading ? (
                    <>
                      {onReset && (
                        <Col shrink style={{ marginRight: '4px' }}>
                          <Icon
                            kind="close-outline-squared"
                            onClick={e => {
                              e.stopPropagation();
                              onReset();
                            }}
                            size="16px"
                            color={cssVariables.iconGhost}
                          />
                        </Col>
                      )}
                      <Col shrink>
                        <Icon
                          className="DatePickerMenu-chevron"
                          kind="chevron-down"
                          size="16px"
                          color={cssVariables.iconSubtle}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col shrink>
                      <Loader size="extraSmall" />
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          )}
        </Reference>
        {show &&
          containerRef.current &&
          ReactDOM.createPortal(
            <Popper placement={placement}>
              {({ ref, style }) => (
                <div
                  ref={el => {
                    ref(el);
                    popperElRef.current = el;
                  }}
                  className={cs('DatePickerMenu-dropdown', {
                    open: show,
                  })}
                  style={{
                    ...style,
                    minWidth: minWidth || 'auto',
                    maxHeight: maxHeight || 'auto',
                    position: containerRef.current === document.body ? 'absolute' : 'fixed',
                  }}
                  onClick={handleMenuClick}
                >
                  {children}
                </div>
              )}
            </Popper>,
            containerRef.current
          )}
      </Manager>
    </span>
  );
};

DatePickerMenu.propTypes = {
  buttonText: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  children: PropTypes.any,
  label: PropTypes.string,
  loading: PropTypes.bool,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.string,
  open: PropTypes.bool,
  onReset: PropTypes.func,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
};

export default DatePickerMenu;
