/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Icon } from '../index';

const SimpleChip = ({
  className,
  disabled,
  iconLeft,
  iconRight,
  kind = 'neutral',
  label,
  onClick,
  selected,
  size = 'md',
}) => {
  const clickHandler = () => {
    if (onClick && !disabled) {
      onClick();
    }
  };
  return (
    <span
      className={cs('SimpleChip', className, {
        [`SimpleChip--${kind}`]: kind,
        [`SimpleChip--${size}`]: size,
        'SimpleChip--selected': selected,
        'SimpleChip--disabled': disabled,
        'SimpleChip--clickable': onClick,
      })}
      onClick={clickHandler}
      tabIndex={onClick ? 0 : null}
    >
      {iconLeft && <Icon className="SimpleChip__icon" kind={iconLeft} inheritColor />}
      {label}
      {iconRight && <Icon className="SimpleChip__icon" kind={iconRight} inheritColor />}
    </span>
  );
};

SimpleChip.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  kind: PropTypes.oneOf(['primary', 'positive', 'warning', 'attention', 'neutral', 'informative']),
  label: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['md', 'sm', 'xs']),
};

export default SimpleChip;
