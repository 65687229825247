/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import xmlFormat from 'xml-formatter';
import { parse } from 'papaparse';
import PropTypes from 'prop-types';
import moment from 'moment';
import { groupBy, sortBy } from 'lodash';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { formatTime, formatDate, formatCurrency, formatNumber, formatPercentage, t } from '../../../../i18n';
import { SyncingLoaderIcon } from '../../../Icons';
import { getErrorsFromData } from '../../../../graphql_provider';
import RemoteModal, { listenOnModalChange, modalDataUrlOpener } from '../../../RemoteModal';
import { MultiEditSelect } from '../../MultiEdit';
import { Row, Col } from '../../../layout';
import { Text } from '../../../Typography';
import { WrapperCell, EditableWrapper } from '../wrappers';
import * as Layout from '../../../layout';
import Badge from '../../../Badge';
import Button from '../../../Button';
import { useModal, Modal as ModalComponent, ModalHeading, ModalHeader, ModalBody, ModalFooter } from '../../../Modal';
import ConditionsBadge from '../../../ConditionsBadge';
import DropdownMenu from '../../../DropdownMenu';
import Ellipsis from '../../../Ellipsis';
import ErrorBoundary from '../../../ErrorBoundary';
import Icon from '../../../Icon';
import Link from '../../../Link';
import ColorLabel from '../../../ColorLabel';
import Switch from '../../../form/Switch';
import SystemIcon from '../../../SystemIcon';
import SystemsDropdown from './SystemsDropdown';
import TimeAgo from '../../../TimeAgo';
import Tooltip from '../../../Tooltip';
import UsedByGraph from '../../../graphs/UsedByGraph';
import TransformationsModal from '../../../Transformations/TransformationsModal';
import { VARIABLE_RULE_STATUS_RUNNING } from '../../../Transformations/Transformation';
import { ActiveProductsBox, cssVariables, SimpleTooltip } from '../../../index';
import MatchRateDetailsModal from '../../../matchRateDetailsModal/MatchRateDetailsModal';

const NUMBER_OF_SHOWED_ROWS = 5;

export const GenericContent = ({ children }) => children;
GenericContent.propTypes = {
  children: PropTypes.any,
};

export const CellContent = ({ children, useEllipsis = true }) => (
  <ErrorBoundary>{useEllipsis ? <Ellipsis>{children}</Ellipsis> : children}</ErrorBoundary>
);
CellContent.propTypes = {
  children: PropTypes.any,
  useEllipsis: PropTypes.bool,
};

export const PlaceholderContent = ({ children, useEllipsis = true }) => (
  <ErrorBoundary>
    {useEllipsis ? (
      <Ellipsis>
        <span dangerouslySetInnerHTML={{ __html: children?.prettyText || children?.text }} />
      </Ellipsis>
    ) : (
      <span dangerouslySetInnerHTML={{ __html: children?.prettyText || children?.text }} />
    )}
  </ErrorBoundary>
);
PlaceholderContent.propTypes = {
  children: PropTypes.any,
  useEllipsis: PropTypes.bool,
};

const AdminErrorContent = ({ children }) => {
  const [opened, setOpened] = useState(false);
  const { errorClass, errorMessage, errorBacktrace, childJobLogs } = children;

  const childJobLogsWithError = childJobLogs.filter(childJobLog => childJobLog.errorClass);

  if (childJobLogsWithError.length === 0 && !errorClass) {
    return null;
  }

  return (
    <React.Fragment>
      <Button icon="magnifier" secondary status={'attention'} onClick={() => setOpened(true)}>
        {(errorClass || childJobLogsWithError[0]?.errorClass || 'show error').slice(0, 15)}
      </Button>
      {opened && (
        <ModalComponent onClose={() => setOpened(false)} heading="Async Job Log error detail" size="full">
          <ModalBody>
            {errorClass && (
              <div>
                <h3>{errorClass}</h3>
                <pre className="pre-line">{errorMessage}</pre>
                <br />
                {errorBacktrace && (
                  <pre>
                    <Text size="sm">{errorBacktrace}</Text>
                  </pre>
                )}
              </div>
            )}
            {childJobLogs
              .filter(childJobLog => childJobLog.errorClass)
              .map(childJobLog => (
                <div key={childJobLog.id}>
                  <h3>
                    {childJobLog.subjectType} ➡ {childJobLog.errorClass}
                  </h3>
                  <pre className="pre-line">{childJobLog.errorMessage}</pre>
                  {childJobLog.errorBacktrace && (
                    <pre>
                      <Text size="sm">{childJobLog.errorBacktrace}</Text>
                    </pre>
                  )}
                </div>
              ))}
          </ModalBody>
        </ModalComponent>
      )}
    </React.Fragment>
  );
};

export const AdtextLineContent = ({ children, useEllipsis = true }) => (
  <ErrorBoundary>
    <Row>
      <Col grow>
        {useEllipsis ? (
          <Ellipsis>
            <span dangerouslySetInnerHTML={{ __html: children?.prettyText || children?.text }} />
          </Ellipsis>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: children?.prettyText || children?.text }} />
        )}
      </Col>
      {typeof children?.overflow === 'boolean' && !children?.disableOverflow && (
        <Col shrink>
          <Switch value={children?.overflow} doNotUseInternalState disabled />
        </Col>
      )}
    </Row>
  </ErrorBoundary>
);
AdtextLineContent.propTypes = {
  children: PropTypes.any,
  useEllipsis: PropTypes.bool,
};
export const AdtextLineWithMainSwitcherContent = ({ children, useEllipsis = true }) => (
  <ErrorBoundary>
    <Col grow>
      {typeof children[children?.mainSwitcher?.key] === 'boolean' && (
        <Row shrink>
          <Switch
            label={children?.mainSwitcher?.label}
            value={children[children?.mainSwitcher?.key]}
            doNotUseInternalState
            disabled
          />
        </Row>
      )}

      {children[children?.mainSwitcher?.key] && (
        <React.Fragment>
          {children?.colorPickers.length > 0 &&
            // eslint-disable-next-line react/prop-types
            children?.colorPickers.map(({ key, label }) => (
              <Row center justifyBetween key={key}>
                {`${label}: ${children?.[key]}`}
                <span style={{ width: '16px', height: '16px', backgroundColor: children?.[key] }} />
              </Row>
            ))}
          {children?.switchers.length > 0 &&
            // eslint-disable-next-line react/prop-types
            children?.switchers.map(({ label, key }) => (
              <Row shrink key={key}>
                <Col>
                  <Switch label={label} value={children?.[key]} doNotUseInternalState disabled />
                </Col>
              </Row>
            ))}
          {children?.texts.length > 0 &&
            // eslint-disable-next-line react/prop-types
            children?.texts.map(({ label, key }) => (
              <Row key={key}>
                <ErrorBoundary>
                  {useEllipsis ? <Ellipsis>{`${label}: ${children[key]}`}</Ellipsis> : `${label}: ${children[key]}`}
                </ErrorBoundary>
              </Row>
            ))}
        </React.Fragment>
      )}
    </Col>
  </ErrorBoundary>
);
AdtextLineWithMainSwitcherContent.propTypes = {
  children: PropTypes.any,
  useEllipsis: PropTypes.bool,
};

export const HtmlCellContent = ({ children }) => <span dangerouslySetInnerHTML={{ __html: children }} />;
HtmlCellContent.propTypes = {
  children: PropTypes.any,
};

export const HtmlRightCellContent = ({ children }) => <span dangerouslySetInnerHTML={{ __html: children }} />;
HtmlRightCellContent.propTypes = {
  children: PropTypes.any,
};

export const ArrayHtmlCellContent = ({ children = [], maxRows = NUMBER_OF_SHOWED_ROWS }) => {
  const [expand, setExpand] = useState(false);
  const expandedRows = expand ? children : children.slice(0, maxRows);

  return (
    <Col noPadding>
      {expandedRows.map((c, i) => (
        <Row shrink key={c || i}>
          <Ellipsis>
            <span dangerouslySetInnerHTML={{ __html: c }} />
          </Ellipsis>
        </Row>
      ))}
      {!expand && children.length > maxRows && (
        <a className="Link" onClick={() => setExpand(!expand)}>
          {window.reacti18n.data_input_preview.show_additional} {children.length - maxRows}
        </a>
      )}
    </Col>
  );
};
ArrayHtmlCellContent.propTypes = {
  children: PropTypes.any,
  maxRows: PropTypes.number,
};

export const RowCellContent = React.memo(
  ({ children: { data, maxRows = NUMBER_OF_SHOWED_ROWS, columnSeparator } }) => {
    const [expand, setExpand] = useState(false);

    let parseData = null;
    const isXML = data.startsWith('<');
    const isJSON = data.startsWith('[');
    try {
      if (isXML) {
        parseData = xmlFormat(data);
      } else if (isJSON) {
        const jsonData = JSON.parse(data);
        parseData = { data: [Object.keys(jsonData[0]), Object.values(jsonData[0])] };
      } else {
        parseData = parse(data, { delimiter: columnSeparator });
      }
    } catch (error) {
      parseData = null;
    }

    if (!parseData) {
      return (
        <React.Fragment>
          {t('view.feed_export_items.expand.no_data_to_show', { default: 'No data to show.' })}
        </React.Fragment>
      );
    }

    if (isXML) {
      const cutOutData = parseData
        .split('\n')
        .splice(0, maxRows)
        .join('\n');
      const showedData = expand ? parseData : cutOutData;
      return (
        <div className="w-100 pa-8">
          <pre className="mt-8">{showedData}</pre>
          {parseData !== cutOutData && (
            <a className="Link" onClick={() => setExpand(prev => !prev)}>
              {t(!expand ? 'show_more' : 'show_less', { scope: 'view.feed_export_items.expand' })}
            </a>
          )}
        </div>
      );
    }

    const cutOutData = [...parseData?.data[0]].splice(0, maxRows);
    const showedData = expand ? parseData?.data[0] : cutOutData;

    return (
      <div className="w-100 ma-4">
        {showedData.map((cell, i) => (
          <Row key={[cell, i].join('-')}>
            <Col shrink>
              <b>{cell}: </b>
            </Col>
            <Col shrink>{parseData?.data[1][i]}</Col>
          </Row>
        ))}

        {parseData.data[0].length !== cutOutData.length && (
          <a className="Link" onClick={() => setExpand(prev => !prev)}>
            {t(!expand ? 'show_more' : 'show_less', { scope: 'view.feed_export_items.expand' })}
          </a>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
);

RowCellContent.propTypes = {
  children: PropTypes.object,
};

export const LinkContent = ({ children: { text, link, icon, onClick, external } }) => (
  <Ellipsis>
    <Link icon={icon} href={link} onClick={onClick} external={external}>
      {text}
    </Link>
  </Ellipsis>
);
LinkContent.propTypes = {
  children: PropTypes.any,
};

export const ConditionBadgeContent = ({ children, ...rest }) => (
  <ConditionsBadge {...rest} itemGroups={rest?.itemGroups || rest?.additionalData} conditions={children} />
);
ConditionBadgeContent.propTypes = {
  children: PropTypes.any,
};

export const ConditionsWithGraphContent = ({
  children: { conditions, products, productsTotal, productsText, withArrow, noConditions, noGraph, additionalData },
}) => (
  <Row center className="pos-relative">
    {withArrow && (
      <div className="pos-absolute" style={{ top: '-14px', left: '8px' }}>
        <Icon kind="arrow-down" color="#CCD0D4" size="9px" />
      </div>
    )}
    <Col shrink center>
      {!noGraph && <UsedByGraph size="md" products={products} productsTotal={productsTotal} />}
    </Col>
    <Col shrink>
      <Text color="gray">{productsText || products}</Text>
    </Col>
    {!noConditions && (
      <Col shrink className="ml-8">
        <ConditionBadgeContent itemGroups={additionalData}>{conditions}</ConditionBadgeContent>
      </Col>
    )}
  </Row>
);

ConditionsWithGraphContent.propTypes = {
  children: PropTypes.any,
};

export const BigBadgeWithTooltipContent = ({ children: { tooltip, link, count } }) =>
  count > 0 && (
    <Tooltip text={tooltip}>
      <Link href={link}>
        <div className="Conditions">
          <div className="Conditions-text Conditions-text--regular">{count > 99 ? '99+' : count}</div>
        </div>
      </Link>
    </Tooltip>
  );
BigBadgeWithTooltipContent.propTypes = {
  children: PropTypes.any,
};

export const LinkModalCellContent = ({ children: { text, link, size = 'medium', notify, heading } }) => (
  <Link href="#" {...modalDataUrlOpener({ url: link, size, heading, notify })}>
    <Ellipsis>{text}</Ellipsis>
  </Link>
);
LinkModalCellContent.propTypes = {
  children: PropTypes.any,
};

export const DropdownMenuContent = ({ children: { links = [], ...rest } }) => {
  const validLinks = links.filter(item => item.href && item.href.length > 0);
  const disabledLinks = links.filter(item => item.disabled && item.disabled.length > 0);

  if (validLinks.length === 0) return null;

  if (validLinks.length === 1) {
    return (
      <Tooltip text={validLinks[0].children}>
        <Button tertiary onlyIcon {...validLinks[0]}>
          {null}
        </Button>
      </Tooltip>
    );
  }

  return (
    <DropdownMenu {...rest}>
      {validLinks.map((item, index) => (
        <Link {...item} key={[item.href, item['data-test-id-duplicate'], index].join('-')} />
      ))}
      {disabledLinks.map((item, index) => (
        <Tooltip text={item.disabled}>
          <Link {...item} disabled key={['disabled', item['data-test-id-duplicate'], index].join('-')} />
        </Tooltip>
      ))}
    </DropdownMenu>
  );
};
DropdownMenuContent.propTypes = {
  children: PropTypes.any,
};

export const IconLinkContent = ({ children: { tooltip, icon, link, ...rest } }) =>
  !link ? null : (
    <div className="text-center">
      <Tooltip text={tooltip}>
        <Button tertiary onlyIcon tag="a" href={link} icon={icon} {...rest} />
      </Tooltip>
    </div>
  );
IconLinkContent.propTypes = {
  children: PropTypes.any,
};

const TableButtonPill = ({ text, isActive, hasError, count, ...rest }) => {
  let status = 'default';

  if (isActive && !hasError) {
    status = 'positive';
  }

  if (hasError) {
    status = 'attention';
  }

  return (
    <Tooltip text={text}>
      <Button {...rest} secondary isTableButton status={status} tag="a" className="mr-4">
        {count && count > 99 ? '99+' : count}
      </Button>
    </Tooltip>
  );
};
TableButtonPill.propTypes = {
  text: PropTypes.string,
  isActive: PropTypes.bool,
  count: PropTypes.number,
  hasError: PropTypes.bool,
};

export const TableButtonPillContent = ({ children: { link, count, showNull = false, ...rest } }) =>
  count > 0 || showNull ? <TableButtonPill count={count} href={link} {...rest} /> : null;
TableButtonPillContent.propTypes = {
  children: PropTypes.any,
};

export const ChildDataSourcesContent = ({ children: { array, link } }) => {
  const group = sortBy(
    groupBy(array, ({ icon }) => icon),
    g => g.length
  ).reverse();

  return (
    <div className="d-flex flex-wrap flex-gap-4">
      {Object.keys(group).map(key => {
        const isActive = group[key].some(({ status }) => status === 'running');

        return (
          <TableButtonPill
            count={group[key].length}
            href={link}
            icon={group[key][0].icon}
            isActive={isActive}
            key={key}
            text={group[key][0].typeName}
          />
        );
      })}
    </div>
  );
};
ChildDataSourcesContent.propTypes = {
  children: PropTypes.object,
};

export const AdditionalSourcesContent = ({ children }) => {
  const group = sortBy(
    groupBy(children, ({ icon }) => icon),
    g => g.length
  ).reverse();

  return (
    <div className="d-flex flex-wrap flex-gap-4">
      {Object.keys(group).map(key => {
        const isActive = group[key].some(({ status }) => status === 'running');

        return (
          <TableButtonPill
            count={group[key].length}
            isActive={isActive}
            href={group[key][0].indexLink}
            icon={group[key][0].icon}
            key={key}
            text={group[key][0].importerNameTranslated}
          />
        );
      })}
    </div>
  );
};
AdditionalSourcesContent.propTypes = {
  children: PropTypes.array,
};

export const IconModalCellContent = ({
  children: { tooltip, icon, link, heading, notify, size = 'medium', ...rest },
}) =>
  !link ? null : (
    <div className="text-center">
      <Tooltip text={tooltip}>
        <Button tertiary onlyIcon icon={icon} {...rest} {...modalDataUrlOpener({ url: link, size, heading, notify })} />
      </Tooltip>
    </div>
  );
IconModalCellContent.propTypes = {
  children: PropTypes.any,
};

export const LabelsContent = ({ children }) =>
  children && children.length > 0 ? (
    <Row flexwrap>
      {children.map(({ id, name, color }) => (
        <Col shrink key={id}>
          <ColorLabel color={color} name={name} />
        </Col>
      ))}
    </Row>
  ) : null;
LabelsContent.propTypes = {
  children: PropTypes.array,
};

export const IconWithTooltipContent = ({ children: { tooltip, icon, colored, color, size, inheritColor = true } }) =>
  !icon ? null : (
    <Tooltip text={tooltip}>
      <Icon
        kind={icon.replace('_', '-')}
        className={icon}
        color={color}
        size={size}
        inheritColor={!color && !!inheritColor}
        colored={colored}
      />
    </Tooltip>
  );

IconWithTooltipContent.propTypes = {
  children: PropTypes.shape({
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    colored: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.any,
    inheritColor: PropTypes.bool,
  }),
};

export const STATES = {
  draft: ({ kind }) => ({
    badgeKind: 'lightOrange',
    icon: 'fill-draft',
    iconColor: cssVariables.statusWarningDefault,
    text: t(`main_states.${kind}.draft.text`),
    textColor: cssVariables.statusWarningBold,
    tooltip: t(`main_states.${kind}.draft.tooltip`),
  }),
  sync_in_progress: ({ kind }) => ({
    badgeKind: 'lightBlue',
    icon: 'syncingLoader',
    iconColor: cssVariables.statusInformativeDefault,
    text: t(`main_states.${kind}.sync_in_progress.text`),
    textColor: cssVariables.statusInformativeBold,
    tooltip: t(`main_states.${kind}.sync_in_progress.tooltip`),
  }),
  waiting_for_child_job_logs: ({ kind }) => ({
    badgeKind: 'lightBlue',
    icon: 'syncingLoader',
    iconColor: cssVariables.statusInformativeDefault,
    text: t(`main_states.${kind}.sync_in_progress.text`),
    textColor: cssVariables.statusInformativeBold,
    tooltip: t(`main_states.${kind}.sync_in_progress.tooltip`),
  }),
  active: ({ lastUpdatedAt, kind }) => {
    let text = t(`main_states.${kind}.active.text`);
    let icon = 'active';
    if (lastUpdatedAt) {
      const time = moment(lastUpdatedAt);
      if (
        time.isBefore(
          moment()
            .subtract(7, 'day')
            .startOf('day')
        )
      ) {
        text = t(`main_states.${kind}.active.long_ago`);
        icon = 'wait';
      } else if (
        time.isBefore(
          moment()
            .subtract(1, 'day')
            .startOf('day')
        )
      ) {
        text = t(`main_states.${kind}.active.recently`);
      } else if (time.isBefore(moment().startOf('day'))) {
        text = t(`main_states.${kind}.active.yesterday`);
      } else {
        text = t(`main_states.${kind}.active.today`);
      }
    }

    return {
      badgeKind: 'lightGreen',
      icon,
      iconColor: cssVariables.statusPositiveDefault,
      text,
      textColor: cssVariables.statusPositiveBold,
      tooltip: lastUpdatedAt
        ? t(`main_states.${kind}.active.tooltip_with_time`, { time: formatTime(lastUpdatedAt) })
        : t(`main_states.${kind}.active.tooltip`),
    };
  },
  paused: ({ lastUpdatedAt, kind }) => ({
    badgeKind: 'lightGray',
    icon: 'paused',
    iconColor: cssVariables.statusNeutralDefault,
    text: t(`main_states.${kind}.paused.text`),
    textColor: cssVariables.statusNeutralBold,
    tooltip: t(`main_states.${kind}.paused.tooltip`, { time: lastUpdatedAt ? formatTime(lastUpdatedAt) : '' }),
  }),
  archived: ({ kind }) => ({
    badgeKind: 'lightGray',
    icon: 'archive',
    iconColor: cssVariables.statusNeutralDefault,
    text: t(`main_states.${kind}.archived.text`),
    textColor: cssVariables.statusNeutralBold,
    tooltip: t(`main_states.${kind}.archived.tooltip`),
  }),
  not_updating: ({ kind }) => ({
    badgeKind: 'lightGray',
    icon: 'bad',
    iconColor: cssVariables.statusNeutralDefault,
    text: t(`main_states.${kind}.not_updating.text`),
    textColor: cssVariables.statusNeutralBold,
    tooltip: t(`main_states.${kind}.not_updating.tooltip`),
  }),
  deleted: ({ kind }) => ({
    badgeKind: 'lightRed',
    icon: 'minus-sign',
    iconColor: cssVariables.statusAttentionDefault,
    text: t(`main_states.${kind}.deleted.text`),
    textColor: cssVariables.statusAttentionBold,
    tooltip: t(`main_states.${kind}.deleted.tooltip`),
  }),
  missing_account_access: ({ kind }) => ({
    badgeKind: 'lightRed',
    icon: 'fill-error',
    iconColor: cssVariables.statusAttentionDefault,
    text: t(`main_states.${kind}.missing_account_access.text`),
    textColor: cssVariables.statusAttentionBold,
    tooltip: t(`main_states.${kind}.missing_account_access.tooltip`),
  }),
  not_in_use: ({ kind }) => ({
    badgeKind: 'lightGray',
    icon: 'bad',
    iconColor: cssVariables.statusNeutralDefault,
    text: t(`main_states.${kind}.not_in_use.text`),
    textColor: cssVariables.statusNeutralBold,
    tooltip: t(`main_states.${kind}.not_in_use.tooltip`),
  }),
  error: ({ kind }) => ({
    badgeKind: 'lightRed',
    icon: 'fill-error',
    iconColor: cssVariables.statusAttentionDefault,
    text: t(`main_states.${kind}.error.text`),
    textColor: cssVariables.statusAttentionBold,
    tooltip: t(`main_states.${kind}.error.tooltip`),
  }),
  first_import_error: ({ kind }) => ({
    badgeKind: 'lightRed',
    icon: 'fill-error',
    iconColor: cssVariables.statusAttentionDefault,
    text: t(`main_states.${kind}.first_import_error.text`),
    textColor: cssVariables.statusAttentionBold,
    tooltip: t(`main_states.${kind}.first_import_error.tooltip`),
  }),
  unknown: ({ kind }) => ({
    badgeKind: 'lightGray',
    icon: 'question',
    iconColor: cssVariables.statusNeutralDefault,
    text: t(`main_states.${kind}.unknown.text`),
    textColor: cssVariables.statusNeutralBold,
    tooltip: t(`main_states.${kind}.unknown.tooltip`),
  }),
};

export const StateContent = ({
  children: { state, error, lastUpdatedAt, kind = 'data_source', customIcons = null },
  hideTooltip = false,
}) => {
  if (state === 'do_not_show_state') {
    return null;
  }

  const states = customIcons ? customIcons[state] : (STATES[state] || STATES.unknown)({ lastUpdatedAt, kind });

  const { badgeKind = 'lightBlue', icon, iconColor, text, textColor, tooltip } = states;
  return (
    <SimpleTooltip text={!hideTooltip && (error || tooltip)}>
      <Badge kind={badgeKind} size="extraLarge" isWithBorder uppercase className="Badge--spacedText">
        {icon === 'syncingLoader' ? (
          <SyncingLoaderIcon size="14px" color={iconColor} />
        ) : (
          <Icon kind={icon} inheritColor />
        )}
        <span style={textColor ? { color: textColor } : {}}>{text}</span>
      </Badge>
    </SimpleTooltip>
  );
};
StateContent.propTypes = {
  hideTooltip: PropTypes.bool,
  children: PropTypes.shape({
    state: PropTypes.oneOf(Object.keys(STATES)),
    error: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
  }),
};

export const GET_ADDITIONAL_SOURCES = gql`
  query TableQuery($organizationId: BigInt!, $dataSourceId: BigInt!, $elementType: String!) {
    collection(
      identifier: "additionalSources"
      page: 0
      limit: 1000
      organizationId: $organizationId
      dataSourceId: $dataSourceId
    ) {
      id
      additionalSources(elementType: $elementType, active: "only_active") {
        id
      }
    }
  }
`;

export const StateContentLive = ({ organizationId, dataSourceId, elementType, children, ...rest }) => {
  const [loadAdditionalSources, { data }] = useLazyQuery(GET_ADDITIONAL_SOURCES, {
    variables: { organizationId, dataSourceId, elementType },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const loadAdditionalSourceCallback = () => {
      loadAdditionalSources();
    };

    window.addEventListener(`additionalDataSourceStateToggled-${elementType}`, loadAdditionalSourceCallback);

    return () => {
      window.removeEventListener(`additionalDataSourceStateToggled-${elementType}`, loadAdditionalSourceCallback);
    };
  }, []);

  useEffect(
    () =>
      listenOnModalChange(eventName => {
        if ((Array.isArray(eventName) ? eventName : [eventName]).indexOf(`additional_sources-${elementType}`) !== -1) {
          loadAdditionalSources();
        }
      }),
    []
  );

  let newState;

  if (data) {
    newState = data.collection.additionalSources.length ? 'active' : 'not_in_use';
  }

  return <StateContent {...rest}>{{ ...children, state: newState || children.state }}</StateContent>;
};

StateContentLive.propTypes = {
  hideTooltip: PropTypes.bool,
  children: PropTypes.shape({
    state: PropTypes.oneOf(Object.keys(STATES)),
  }),
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  elementType: PropTypes.string,
};

export const TextContent = CellContent;

const isNotEmpty = val => !(typeof val === 'undefined' || val === null);
export const NumberCellContent = ({ children }) => (
  <span className="nowrap">{isNotEmpty(children) ? formatNumber(children) : ''}</span>
);
NumberCellContent.propTypes = {
  children: PropTypes.any,
};

export const IntegerCellContent = ({ children }) => (
  <span className="nowrap">{isNotEmpty(children) ? formatNumber(children, { precision: 0 }) : ''}</span>
);
IntegerCellContent.propTypes = {
  children: PropTypes.any,
};

export const MoneyCellContent = ({ children: { money, currency } }) => (
  <span className="nowrap">{isNotEmpty(money) ? formatCurrency(money, { unit: currency || '' }) : ''}</span>
);
MoneyCellContent.propTypes = {
  children: PropTypes.any,
};

export const FloatCellContent = ({ children }) => (
  <span className="nowrap">{isNotEmpty(children) ? formatNumber(children, { precision: 2 }) : ''}</span>
);
FloatCellContent.propTypes = {
  children: PropTypes.any,
};

export const PercentageCellContent = ({ children }) => (
  <span className="nowrap">{isNotEmpty(children) ? formatPercentage(children, { precision: 2 }) : ''}</span>
);
PercentageCellContent.propTypes = {
  children: PropTypes.any,
};

export const TimeAgoCellContent = ({ children }) => <TimeAgo>{children}</TimeAgo>;
TimeAgoCellContent.propTypes = {
  children: PropTypes.any,
};

export const DateTimeCellContent = ({ children }) => <span className="nowrap">{formatDate(children)}</span>;
DateTimeCellContent.propTypes = {
  children: PropTypes.any,
};

export const DayTimeDifferenceCellContent = ({ children: { from, to } }) => {
  if (from && to) {
    return (
      <span className="nowrap">
        {formatTime(from, 'HH:mm:ss')} {to ? `- ${formatTime(to, 'HH:mm:ss')}, ` : ', '} {formatDate(from)}
      </span>
    );
  }

  if (from) {
    return (
      <span className="nowrap">
        {formatTime(from, 'HH:mm:ss')} {formatDate(from)}
      </span>
    );
  }

  return '';
};
DayTimeDifferenceCellContent.propTypes = {
  children: PropTypes.objectOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    })
  ),
};

export const DateAndTimeCellContent = ({ children }) => <span className="nowrap">{formatTime(children)}</span>;
DateAndTimeCellContent.propTypes = {
  children: PropTypes.any,
};

export const SwitchContentWithOptions = ({
  children: { value: val, disabled, doNotShow = false },
  onChangeAndSubmit,
}) => {
  if (doNotShow) return null;

  return (
    <Switch
      disabled={disabled}
      doNotUseInternalState
      value={val}
      onChange={({ target: { value } }) => onChangeAndSubmit(value)}
    />
  );
};

SwitchContentWithOptions.propTypes = {
  children: PropTypes.object,
  onChangeAndSubmit: PropTypes.func.isRequired,
};

export const SwitchContent = ({ children, onChangeAndSubmit }) =>
  (children === false || children === true) && (
    <Switch doNotUseInternalState value={children} onChange={({ target: { value } }) => onChangeAndSubmit(value)} />
  );

SwitchContent.propTypes = {
  children: PropTypes.any,
  onChangeAndSubmit: PropTypes.func.isRequired,
};

SwitchContent.propTypes = {
  children: PropTypes.any,
  onChangeAndSubmit: PropTypes.func.isRequired,
};

export const VisibilitySwitchContent = ({ children, onChangeAndSubmit, id }) =>
  (children === false || children === true) && (
    <Switch
      visibility
      doNotUseInternalState
      name={id}
      value={children}
      onChange={({ target: { value } }) => onChangeAndSubmit(value)}
    />
  );

SwitchContent.propTypes = {
  children: PropTypes.any,
  onChangeAndSubmit: PropTypes.func.isRequired,
};

export const StatusSwitchContent = ({
  disabled,
  children: { enabled, timeRestrictionStatus, timeRestrictionTooltip },
  onChangeAndSubmit,
  statusCampaignSwitchWarning,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleChangeAndSubmit = (value, status = timeRestrictionStatus) => {
    if (!value && statusCampaignSwitchWarning) {
      setShowModal(true);
    } else {
      onChangeAndSubmit({ enabled: value, status });
    }
  };

  const handleModalConfirm = () => {
    setShowModal(false);
    onChangeAndSubmit({ enabled: false, timeRestrictionStatus });
  };

  return (
    <>
      {showModal && (
        <ModalComponent size="small" onClose={() => setShowModal(false)}>
          <ModalHeader>
            <ModalHeading>
              <Row center>
                <Icon kind="warning" size="24px" className="Icon--orange mr-12" inheritColor />
                {t('react.time_restrictions.modal_heading')}
              </Row>
            </ModalHeading>
          </ModalHeader>
          <ModalBody>{t('react.time_restrictions.modal_body')}</ModalBody>
          <ModalFooter>
            <Button primary onClick={() => handleModalConfirm()} data-test-id={'status-switch-modal-confirm'}>
              {t('react.multi_edit.confirm')}
            </Button>
            <Button secondary onClick={() => setShowModal(false)} data-test-id={'status-switch-modal-cancel'}>
              {t('react.time_restrictions.modal_cancel')}
            </Button>
          </ModalFooter>
        </ModalComponent>
      )}
      <Tooltip text={timeRestrictionTooltip}>
        <Switch
          doNotUseInternalState
          pausePlay
          disabled={disabled}
          name="status-switch"
          id="status-switch"
          timeRestriction={timeRestrictionStatus}
          value={enabled}
          onChange={({ target: { value } }) => handleChangeAndSubmit(value, timeRestrictionStatus)}
        />
      </Tooltip>
    </>
  );
};

StatusSwitchContent.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.any,
  onChangeAndSubmit: PropTypes.func.isRequired,
  statusCampaignSwitchWarning: PropTypes.bool,
};

export const FeedExportsStatusContent = ({
  children: { active, activateLink, generating, defaultValue, feedExportState, wasEverGenerated },
}) => {
  const [state, setState] = useState('copy_to_clipboard');

  if (feedExportState === 'error' && !wasEverGenerated) {
    return null;
  }

  if (active === 'stopped') {
    return (
      <Button
        kind="secondary"
        icon="wiz-sync"
        {...modalDataUrlOpener({
          url: activateLink,
          size: 'medium',
          tid: 'feed-export-activate',
        })}
      >
        {t('feed_exports.activate')}
      </Button>
    );
  }

  return generating && feedExportState !== 'error' ? (
    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <SyncingLoaderIcon color="#000" />
      <span className="ml-4">{t('views.feed_export.generating')}</span>
    </span>
  ) : (
    <span className="Input" style={{ display: 'inherit' }}>
      <input name="feed-exports[url]" value={defaultValue} readOnly style={{ width: '250px', direction: 'rtl' }} />
      <Tooltip placement="top" text={t(state, { scope: 'views.organizations.feed_exports.table_item' })}>
        <Button
          data-test-id="copy-to-clipboard"
          tertiary
          className="ml-4"
          onlyIcon
          icon="duplicate"
          onClick={() => {
            // eslint-disable-next-line no-undef
            navigator.clipboard.writeText(defaultValue).then(() => {
              new window.NotificationCenter().show_success(
                t('views.organizations.feed_exports.table_item.notification_success', { url: defaultValue })
              );
              setState('copied_to_clipboard');
              setTimeout(() => {
                setState('copy_to_clipboard');
              }, 3000);
            });
          }}
        />
      </Tooltip>
    </span>
  );
};

FeedExportsStatusContent.propTypes = {
  children: PropTypes.any,
};

export const DataSourceLastLogContent = ({ children: { showLink, skippedProductsCount } }) =>
  showLink && skippedProductsCount && skippedProductsCount > 0 ? (
    <Tooltip text={t('views.data_sources.index.skipped_products_tooltip', { default: 'Show skipped products' })}>
      <Button secondary status={'default'} {...modalDataUrlOpener({ url: showLink, size: 'medium' })}>
        {skippedProductsCount}
      </Button>
    </Tooltip>
  ) : (
    <Badge kind="lightBlue" count={skippedProductsCount} />
  );
DataSourceLastLogContent.propTypes = {
  children: PropTypes.any,
};

export const AdSystemContent = ({ children }) => (
  <Layout.Row>
    {children.map(system => (
      <Layout.Col shrink key={system}>
        <SystemIcon size={'14px'} system={system} />
      </Layout.Col>
    ))}
  </Layout.Row>
);
AdSystemContent.propTypes = {
  children: PropTypes.any,
};

export const SystemStateContent = ({ children, editQuery, forceOpen }) => {
  const { systems = [], generated, synced } = children;
  const [loading, setLoading] = useState(false);

  const [update] = editQuery
    ? useMutation(editQuery, {
        onCompleted: responseData => {
          setLoading(false);
          if (typeof window.NotificationCenter === 'function') {
            const err = getErrorsFromData(responseData);
            if (err.length > 0) {
              new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
            } else {
              new window.NotificationCenter().show_success('Successfully saved');
            }
          }
        },
      })
    : [() => {}, {}];

  return (
    <Row>
      <SystemsDropdown
        systems={systems}
        generated={generated}
        synced={synced}
        loading={loading}
        forceOpen={forceOpen}
        setLoading={setLoading}
        editQuery={editQuery}
        update={update}
      />
    </Row>
  );
};
SystemStateContent.propTypes = {
  children: PropTypes.any,
  editQuery: PropTypes.object,
  forceOpen: PropTypes.bool,
};

export const SimpleListModalContent = ({ children }) => {
  const { Modal, showModal } = useModal(false);

  if (children?.doNotUseModal) {
    return (
      <div style={{ width: '150px' }}>
        <Ellipsis>{children?.text}</Ellipsis>
      </div>
    );
  }

  return (
    <div>
      <Link href="#" onClick={showModal}>
        {children?.text}
      </Link>

      <Modal>
        <ModalHeader>
          <ModalHeading>{children?.modalHeader}</ModalHeading>
        </ModalHeader>
        <ModalBody>{children?.modalContent}</ModalBody>
      </Modal>
    </div>
  );
};
SimpleListModalContent.propTypes = {
  children: PropTypes.object,
};

export const MainIdentifierContent = ({
  editButton,
  children: {
    id,
    tooltip,
    icon,
    size,
    color,
    colored,
    name,
    link,
    linkIcon,
    onClick,
    links,
    additionalContent,
    ...rest
  },
}) => (
  <Row center>
    <MultiEditSelect id={id} name={name} />
    {additionalContent}
    <Col shrink>
      <IconWithTooltipContent>{{ tooltip, icon, color, colored, size }}</IconWithTooltipContent>
    </Col>
    <Col onClick={onClick} grow data-test-id="main-identifier">
      {link ? (
        <LinkContent>{{ bold: true, text: name, link, icon: linkIcon }}</LinkContent>
      ) : (
        <Ellipsis>{name}</Ellipsis>
      )}
    </Col>
    {editButton}
    <Col shrink>
      <DropdownMenuContent>{{ links, ...rest }}</DropdownMenuContent>
    </Col>
  </Row>
);
MainIdentifierContent.propTypes = {
  editButton: PropTypes.any,
  children: PropTypes.object,
};

export const MainIdentifierSystemContent = ({
  editButton,
  children: { id, system, size, name, link, linkIcon, onClick, links, additionalContent, ...rest },
}) => (
  <Row center>
    <MultiEditSelect id={id} name={name} />
    {additionalContent}
    <Col shrink>
      <SystemIcon size={size} system={system} />
    </Col>
    <Col onClick={onClick} grow data-test-id="main-identifier">
      {link ? <LinkContent>{{ bold: true, text: name, link, icon: linkIcon }}</LinkContent> : name}
    </Col>
    {editButton}
    <Col shrink>
      <DropdownMenuContent>{{ links, ...rest }}</DropdownMenuContent>
    </Col>
  </Row>
);
MainIdentifierSystemContent.propTypes = {
  editButton: PropTypes.any,
  children: PropTypes.object,
};

const MainIdentifierWithSwitcherSitelinkContent = ({
  editButton,
  children: {
    id,
    name,
    link,
    links,
    status,
    disableStatus,
    tooltip,
    onClick,
    icon,
    color,
    size,
    colored,
    linkIcon,
    linkOnClick,
    timeRestrictionTooltip,
    timeRestrictionStatus,
    disableEditButton,
    additionalContent,
    approveChangeStatusModalHeading,
    approveChangeStatusModalLink,
    content,
    ...rest
  },
  multiEditView,
  canEdit,
  onChangeAndSubmit,
  statusCampaignSwitchWarning,
}) => {
  const [showApproveModal, setShowApproveModal] = useState({ show: false, value: null });

  return (
    <Row center padding="l">
      {!multiEditView && id && <MultiEditSelect id={id} name={name} />}
      <Col shrink>
        {status && (
          <StatusSwitchContent
            canEdit={canEdit}
            onChangeAndSubmit={
              approveChangeStatusModalLink ? value => setShowApproveModal({ show: true, value }) : onChangeAndSubmit
            }
            disabled={disableStatus}
            statusCampaignSwitchWarning={statusCampaignSwitchWarning}
          >
            {{ enabled: status === 'running', timeRestrictionStatus, timeRestrictionTooltip }}
          </StatusSwitchContent>
        )}
      </Col>
      {!multiEditView && additionalContent}
      {!multiEditView && <Col grow>{content}</Col>}
      {!multiEditView && icon && (
        <Col shrink style={{ lineHeight: 0 }}>
          <IconWithTooltipContent>{{ tooltip, icon, colored, size, color }}</IconWithTooltipContent>
        </Col>
      )}
      {!multiEditView && !disableEditButton && editButton}
      {!multiEditView && links && (
        <Col shrink>
          <DropdownMenuContent>{{ links, ...rest }}</DropdownMenuContent>
        </Col>
      )}
      {!multiEditView && showApproveModal.show && (
        <RemoteModal
          heading={approveChangeStatusModalHeading}
          url={approveChangeStatusModalLink}
          onClose={() => setShowApproveModal({ show: false })}
          onPrimaryClick={() => {
            onChangeAndSubmit(showApproveModal.value);
            setShowApproveModal({ show: false });
          }}
        />
      )}
    </Row>
  );
};

MainIdentifierWithSwitcherSitelinkContent.propTypes = {
  canEdit: PropTypes.bool,
  editButton: PropTypes.any,
  children: PropTypes.object,
  multiEditView: PropTypes.bool,
  onChangeAndSubmit: PropTypes.func.isRequired,
  statusCampaignSwitchWarning: PropTypes.bool,
};

export const MainIdentifierWithSwitcherContent = ({
  editButton,
  children: {
    id,
    name,
    link,
    links,
    status,
    disableStatus,
    tooltip,
    onClick,
    icon,
    color,
    size,
    colored,
    linkIcon,
    linkOnClick,
    timeRestrictionTooltip,
    timeRestrictionStatus,
    disableEditButton,
    additionalContent,
    approveChangeStatusModalHeading,
    approveChangeStatusModalLink,
    ...rest
  },
  multiEditView,
  canEdit,
  onChangeAndSubmit,
  statusCampaignSwitchWarning,
}) => {
  const [showApproveModal, setShowApproveModal] = useState({ show: false, value: null });

  return (
    <Row center>
      {!multiEditView && id && <MultiEditSelect id={id} name={name} />}
      {!multiEditView && additionalContent}
      <Col shrink className="mr-16">
        {status && (
          <StatusSwitchContent
            canEdit={canEdit}
            onChangeAndSubmit={
              approveChangeStatusModalLink ? value => setShowApproveModal({ show: true, value }) : onChangeAndSubmit
            }
            disabled={disableStatus}
            statusCampaignSwitchWarning={statusCampaignSwitchWarning}
          >
            {{ enabled: status === 'running', timeRestrictionStatus, timeRestrictionTooltip }}
          </StatusSwitchContent>
        )}
      </Col>
      {!multiEditView && icon && (
        <Col shrink style={{ lineHeight: 0 }}>
          <IconWithTooltipContent>{{ tooltip, icon, colored, size, color }}</IconWithTooltipContent>
        </Col>
      )}
      {!multiEditView && (
        <Col grow>
          <LinkContent>{{ bold: true, text: name, link, icon: linkIcon, onClick }}</LinkContent>
        </Col>
      )}
      {!multiEditView && !disableEditButton && editButton}
      {!multiEditView && links && (
        <Col shrink>
          <DropdownMenuContent>{{ links, ...rest }}</DropdownMenuContent>
        </Col>
      )}
      {!multiEditView && showApproveModal.show && (
        <RemoteModal
          heading={approveChangeStatusModalHeading}
          url={approveChangeStatusModalLink}
          onClose={() => setShowApproveModal({ show: false })}
          onPrimaryClick={() => {
            onChangeAndSubmit(showApproveModal.value);
            setShowApproveModal({ show: false });
          }}
        />
      )}
    </Row>
  );
};
MainIdentifierWithSwitcherContent.propTypes = {
  canEdit: PropTypes.bool,
  editButton: PropTypes.any,
  children: PropTypes.object,
  multiEditView: PropTypes.bool,
  onChangeAndSubmit: PropTypes.func.isRequired,
  statusCampaignSwitchWarning: PropTypes.bool,
};

const P_MAX_IMAGE_TYPES = [
  'Square marketing image',
  'Marketing image',
  'Business logo',
  'Landscape logo',
  'Logo',
  'Portrait marketing image',
  'Ad image',
];
const P_MAX_VIDEO_TYPES = ['Youtube video', 'Video'];
const P_MAX_CALL_TO_ACTION = 'Call to action selection';

export const PmaxAssetContent = ({ children: { type, imageUrl, text, videoUrl, videoTitle, callToAction } }) => {
  if (P_MAX_IMAGE_TYPES.includes(type) && imageUrl) {
    return <img src={imageUrl} alt="" className="mv-4" style={{ maxWidth: '91px', maxHeight: '48px' }} />;
  }

  if (P_MAX_VIDEO_TYPES.includes(type) && videoUrl) {
    return (
      <a href={videoUrl} target="_blank" className="Link" rel="noopener noreferrer">
        {videoTitle}
      </a>
    );
  }

  if (P_MAX_CALL_TO_ACTION === type) {
    return callToAction;
  }

  return text;
};
PmaxAssetContent.propTypes = {
  children: PropTypes.object,
};

const P_MAX_STATUS_ICONS = {
  APPROVED: {
    kind: 'active',
    color: '#3ED95E',
  },
  PAUSED: {
    kind: 'fill-paused',
    color: '#f59e0b',
  },
  REMOVED: {
    kind: 'fill-error',
    color: '#ff264a',
  },
};

export const PmaxStatusContent = ({ children: status }) => {
  const icon = P_MAX_STATUS_ICONS[status] || { color: '#859bab', kind: 'question' };
  return (
    <>
      <Icon {...icon} size="14px" />
      <Text className="ml-8 text-nowrap" style={{ color: icon.color }} size="xs" bold spaced uppercase>
        {status}
      </Text>
    </>
  );
};
PmaxStatusContent.propTypes = {
  children: PropTypes.string,
};

export const BadgeContent = ({ children: { kind, text, size } }) => (
  <Badge size={size} kind={kind}>
    {text}
  </Badge>
);
BadgeContent.propTypes = {
  children: PropTypes.shape({
    size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big']),
    kind: PropTypes.oneOf(['blue', 'lightBlue', 'red', 'gray', 'beta', 'lightGray', 'green', 'orange', 'lightOrange']),
    text: PropTypes.string,
  }),
};

export const VariableRulesContent = ({ name, organizationId, dataSourceId, showFieldKind, rowData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const transformationSubjectsActive = rowData.transformationSubjects?.filter(
    item => item.status === VARIABLE_RULE_STATUS_RUNNING
  );

  let tooltip = !rowData?.transformationSubjects?.length
    ? t('react.transformations.create_new_rule')
    : `${transformationSubjectsActive.length} ${t(transformationSubjectsActive.length === 1 ? 'rule' : 'rules')} ${t(
        'react.transformations.in_use'
      )}`;

  if (!!rowData?.transformationSubjects?.length && !transformationSubjectsActive.length) {
    tooltip = t('react.transformations.all_rules_disabled');
  }

  return (
    <>
      <Tooltip text={tooltip} key={tooltip}>
        <Button isTableButton secondary status={'informative'} icon="rules" onClick={() => setIsOpen(true)}>
          {transformationSubjectsActive?.length}
        </Button>
      </Tooltip>
      {isOpen && (
        <TransformationsModal
          variable={{ name, id: rowData.id, showFieldKind }}
          onClose={() => setIsOpen(false)}
          organizationId={organizationId}
          dataSourceId={dataSourceId}
        />
      )}
    </>
  );
};

VariableRulesContent.propTypes = {
  name: PropTypes.string,
  rowData: PropTypes.shape({ id: PropTypes.string }),
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  showFieldKind: PropTypes.string,
};

export const MatchRate = props => {
  const FINISHED = 'finished';
  const IN_PROGRESS = 'in_progress';
  const ERROR = 'failed';
  const WAITING_FOR_SYNC = 'waiting_to_start';
  const [openDetails, setOpenDetails] = useState(false);
  const additionalSourceId = props.rowData?.id;
  const { syncState, dataSourceAllItemsCount, highestMatchRate } = props.rowData;

  return (
    <div className="match-rate">
      {syncState === ERROR && (
        <Tooltip text={t('match_rate.status.error', { default: 'Synchronization Error: Match Rate Unavailable' })}>
          <Icon
            kind="calculating_error"
            className="match-rate__error-icon"
            size="24px"
            color={cssVariables.interactiveAttentionDefault}
          />
        </Tooltip>
      )}
      {syncState === WAITING_FOR_SYNC && (
        <Tooltip
          text={t('match_rate.status.waiting_to_start', {
            default: 'Synchronization will start soon. This process may take a few seconds to a few minutes.',
          })}
        >
          <Icon
            data-test-id="waiting-for-sync-icon"
            kind="countdown-type"
            className="match-rate__progress-icon"
            size="24px"
            color={cssVariables.iconDefault}
          />
        </Tooltip>
      )}
      {syncState === IN_PROGRESS && (
        <Tooltip
          text={t('match_rate.status.in_progress', {
            default:
              'Match rate is not available yet. Please wait for the next sync to complete, or update your data source.',
          })}
        >
          <Icon
            kind="calculating_syncing"
            className="match-rate__progress-icon"
            size="24px"
            color={cssVariables.iconDefault}
          />
        </Tooltip>
      )}
      {syncState === FINISHED && (
        <div className="match-rate-data">
          <ActiveProductsBox
            allItemsCount={dataSourceAllItemsCount}
            count={highestMatchRate}
            tooltip={t('match_rate.title', { default: 'Match rate' })}
            pieColor="#855BE2"
            hasRoundBorder
          />

          <Button tertiary onlyIcon icon="magnifier" size="small" onClick={() => setOpenDetails(true)} />
        </div>
      )}

      {openDetails && (
        <MatchRateDetailsModal
          closeModal={() => setOpenDetails(false)}
          additionalSourceId={additionalSourceId}
          totalItems={dataSourceAllItemsCount}
        />
      )}
    </div>
  );
};

export const AdminErrorCell = WrapperCell(AdminErrorContent);
export const AdSystemCell = WrapperCell(AdSystemContent);
export const AdtextLineWithMainSwitcherCell = WrapperCell(AdtextLineWithMainSwitcherContent);
export const AdtextLineCell = WrapperCell(AdtextLineContent);
export const ArrayHtmlCell = WrapperCell(ArrayHtmlCellContent);
export const BadgeCell = WrapperCell(BadgeContent);
export const BigBadgeWithTooltipCell = WrapperCell(BigBadgeWithTooltipContent);
export const TableButtonPillCell = WrapperCell(TableButtonPillContent);
export const Cell = WrapperCell(CellContent);
export const ConditionCell = WrapperCell(ConditionBadgeContent);
export const ConditionsWithGraphCell = WrapperCell(ConditionsWithGraphContent);
export const DataSourceLastLogCell = WrapperCell(DataSourceLastLogContent);
export const DateTimeCell = WrapperCell(DateTimeCellContent);
export const DateAndTimeCell = WrapperCell(DateAndTimeCellContent);
export const DropdownMenuCell = WrapperCell(DropdownMenuContent);
export const FeedExportsStatusCell = WrapperCell(FeedExportsStatusContent);
export const FloatCell = WrapperCell(FloatCellContent);
export const LabelsCell = WrapperCell(LabelsContent);
export const GenericCell = WrapperCell(GenericContent);
export const HtmlCell = WrapperCell(HtmlCellContent);
export const HtmlRightCell = WrapperCell(HtmlRightCellContent);
export const IconLinkCell = WrapperCell(IconLinkContent);
export const IconModalCell = WrapperCell(IconModalCellContent);
export const IconWithTooltipCell = WrapperCell(IconWithTooltipContent);
export const AdditionalSourcesCell = WrapperCell(AdditionalSourcesContent);
export const ChildDataSourcesCell = WrapperCell(ChildDataSourcesContent);
export const IntegerCell = WrapperCell(IntegerCellContent);
export const LinkCell = WrapperCell(LinkContent);
export const LinkModalCell = WrapperCell(LinkModalCellContent);
export const MainIdentifierCell = WrapperCell(MainIdentifierContent);
export const MainIdentifierSystemCell = WrapperCell(MainIdentifierSystemContent);
export const MainIdentifierWithSwitcherCell = WrapperCell(EditableWrapper(MainIdentifierWithSwitcherContent));
export const MainIdentifierCustomContentCell = WrapperCell(EditableWrapper(MainIdentifierWithSwitcherSitelinkContent));
export const MoneyCell = WrapperCell(MoneyCellContent);
export const NumberCell = WrapperCell(NumberCellContent);
export const PercentageCell = WrapperCell(PercentageCellContent);
export const PlaceholderCell = WrapperCell(PlaceholderContent);
export const PmaxAssetCell = WrapperCell(PmaxAssetContent);
export const PmaxStatusCell = WrapperCell(PmaxStatusContent);
export const StatusSwitchCell = WrapperCell(EditableWrapper(StatusSwitchContent));
export const StateCell = WrapperCell(StateContent);
export const SwitchCell = WrapperCell(EditableWrapper(SwitchContent));
export const SwitchCellWithOptions = WrapperCell(EditableWrapper(SwitchContentWithOptions));
export const SystemStateCell = WrapperCell(SystemStateContent);
export const SimpleListModalCell = WrapperCell(SimpleListModalContent);
export const TextCell = WrapperCell(TextContent);
export const TimeAgoCell = WrapperCell(TimeAgoCellContent);
export const VariableRulesCell = WrapperCell(VariableRulesContent);
export const VisibilitySwitchCell = WrapperCell(EditableWrapper(VisibilitySwitchContent));
export const PreviewRowCell = WrapperCell(RowCellContent);
export const DayTimeDifferenceCell = WrapperCell(DayTimeDifferenceCellContent);
export const MatchRateCell = WrapperCell(MatchRate);
