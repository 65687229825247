/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import { SegmentedButton, Switch, convertBoolean } from '../components/form';
import { Row, Col } from '../components/layout';
import updatePreview from './updatePreview';
import PlaceholderInput from '../placeholders/Input';
import CharacterCounter from './CharacterCounter';
import Button from '../components/Button';

class AdtextHeadline extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    _error_fields: PropTypes.arrayOf(PropTypes.string),
    _errors: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    overflow: PropTypes.bool,
    optional: PropTypes.bool,
    pinned: PropTypes.string,
    text: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    text: this.props.text || '',
    pinned: this.props.pinned || 'not_pinned',
    overflow: convertBoolean(this.props.overflow, true),
    optional: convertBoolean(this.props.optional, false),
  };

  handleText = ({ target: { value } }) => {
    if (this.state.text !== value) {
      this.setState({ text: value });
      this.props.updateFieldData(this.props.id, { text: value });
      updatePreview();
    }
  };
  handlePinned = ({ target: { value } }) => {
    if (this.state.pinned !== value) {
      this.setState({ pinned: value });
      this.props.updateFieldData(this.props.id, { pinned: value });
      updatePreview();
    }
  };
  handleOverflow = ({ target: { value } }) => {
    if (this.state.overflow !== value) {
      this.setState({ overflow: value });
      this.props.updateFieldData(this.props.id, { overflow: value });
      updatePreview();
    }
  };
  handleOptional = ({ target: { value } }) => {
    if (this.state.optional !== value) {
      this.setState({ optional: value });
      this.props.updateFieldData(this.props.id, { optional: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, fieldData, id, getSubFiledOptions, onRemove, options } = this.props;
    const { text, pinned, overflow, optional } = this.state;
    const unDestroyetFields = fieldData?.filter(field => field._destroy !== true);

    return (
      <React.Fragment>
        <Row center className="mb-0" data-test-id="nested-field-adtextHeadline">
          <Col grow>
            <PlaceholderInput
              error={!!this.props._errors}
              defaultValue={text}
              onChange={this.handleText}
              {...getSubFiledOptions('text')}
            />
          </Col>
          <Col shrink>
            <SegmentedButton
              value={pinned}
              doNotUseInternalState
              onChange={this.handlePinned}
              collection={[
                { value: 'not_pinned', label: '–' },
                { value: 'first_position', label: '1' },
                { value: 'second_position', label: '2' },
                { value: 'third_position', label: '3' },
              ]}
              {...getSubFiledOptions('pinned')}
            />
          </Col>
          {options.allow_optional_adtext_lines && (
            <Col width="72px" className="text-center">
              <Switch value={optional} onChange={this.handleOptional} {...getSubFiledOptions('optional')} />
            </Col>
          )}
          <Col width="45px" className="text-center">
            <Switch value={overflow} onChange={this.handleOverflow} {...getSubFiledOptions('overflow')} />
          </Col>
          <Col width="32px">
            {id && onRemove && unDestroyetFields?.length > 1 && (
              <Button
                tertiary
                onlyIcon
                disabled={disabled}
                onClick={onRemove}
                tabIndex="-1"
                icon="trash"
                data-test-id="nested-field-remove"
              />
            )}
          </Col>
        </Row>
        {this.props._errors && (
          <Row className="mb-4 mt-0" shrink>
            <Col>
              <div className="ValidationError">{this.props._errors}</div>
            </Col>
          </Row>
        )}
        <Row className="mb-8 mt-4">
          <Col>
            <CharacterCounter
              {...getSubFiledOptions('_text_counter')}
              chars={options.chars}
              show_variables_count={options.show_variables_count}
              max_text_count={options.max_text_count}
              without_variables={options.without_variables}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default AdtextHeadline;
