import React from 'react';

const SimpleTable = ({ children, ...props }) => (
  <table {...props} className="simple-table">
    {children}
  </table>
);

SimpleTable.Head = ({ children, ...props }) => (
  <thead {...props}>
    <tr>{children}</tr>
  </thead>
);

SimpleTable.HeadCell = ({ children, ...props }) => <th {...props}>{children}</th>;

SimpleTable.Body = ({ children, ...props }) => <tbody {...props}>{children}</tbody>;

SimpleTable.Row = ({ children, firstCellBorder, ...props }) => (
  <tr {...props} className={firstCellBorder ? 'first-cell-border' : ''}>
    {children}
  </tr>
);

SimpleTable.Cell = ({ children, ...props }) => <td {...props}>{children}</td>;

export default SimpleTable;
