import React from 'react';
import { Row, Col, Heading, Text, cssVariables, Badge, SimpleTooltip, Icon } from '../index';
import Chart from '../feed_exports/Chart';
import { calculatePercentage, formatNumber } from './utils';
import { t } from '../../i18n';

const LimitSmall = ({ data, overallAsString, currency = null, motherOrganizationLimit }) => {
  if (motherOrganizationLimit === null) {
    return (
      <Row center>
        <Col shrink style={{ marginRight: '12px' }}>
          <SimpleTooltip text={t('react.usage_history.unlimited_limit_tooltip', { default: 'Unlimited usage' })}>
            <Icon className="usage-history-unlimited-icon" kind="general_infinity" size="16px" />
          </SimpleTooltip>
        </Col>
        <Col shrink>
          <Heading tag="h4" size="xl" spacing={0}>
            {formatNumber(data?.count, currency)}
          </Heading>
        </Col>
      </Row>
    );
  }
  if (motherOrganizationLimit === 0) {
    return (
      <Badge kind="gray">
        {t('react.usage_history.available_in_higher_plans', { default: 'Available in higher plans' })}
      </Badge>
    );
  }

  const overall = overallAsString ? 'of overall' : `of ${formatNumber(data?.allItemsCount, currency)}`;

  return (
    <Row center>
      <Col shrink style={{ minWidth: '28px', marginRight: '10px' }}>
        <Row justifyCenter style={{ marginBottom: '4px' }}>
          <Chart
            innerRadius={6}
            outerRadius={8}
            size={16}
            collection={data?.collection}
            hideLabel
            percentual
            hasRoundBorder
          />
        </Row>
        <Text size="sm" style={{ color: cssVariables.textMuted, textAlign: 'center' }}>
          {calculatePercentage(data?.count, data?.allItemsCount)}
        </Text>
      </Col>
      <Col shrink>
        <Heading tag="h4" size="xl" spacing={4}>
          {formatNumber(data?.count, currency)}
        </Heading>
        <Text size="sm" style={{ color: cssVariables.textSubtle }}>
          {overall}
        </Text>
      </Col>
    </Row>
  );
};

export default LimitSmall;
