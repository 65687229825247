import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  setYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';

import { createStaticRanges } from 'react-date-range';

export const DATE_RANGE = {
  Range_1YearAgo: 'RANGE_1_YEAR_AGO',
  RangeCustom: 'RANGE_CUSTOM',
  RangeCustomDays: 'RANGE_CUSTOM_DAYS',
  RangeLast_7Days: 'RANGE_LAST_7_DAYS',
  RangeLast_30Days: 'RANGE_LAST_30_DAYS',
  RangeLast_90Days: 'RANGE_LAST_90_DAYS',
  RangeLastMonth: 'RANGE_LAST_MONTH',
  RangeLastYear: 'RANGE_LAST_YEAR',
  RangeThisMonth: 'RANGE_THIS_MONTH',
  RangeThisYear: 'RANGE_THIS_YEAR',
  RangeDefaultPeriod: 'RANGE_DEFAULT_PERIOD',
};

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
  startOfThisYear: startOfYear(new Date()),
};

export const dateRangesOptions = [
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: defineds.endOfYesterday,
      kind: DATE_RANGE.RangeLast_7Days,
    }),
    kind: DATE_RANGE.RangeLast_7Days,
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: defineds.endOfYesterday,
      kind: DATE_RANGE.RangeLast_30Days,
    }),
    kind: DATE_RANGE.RangeLast_30Days,
  },
  {
    label: 'Last 90 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -90)),
      endDate: defineds.endOfYesterday,
      kind: DATE_RANGE.RangeLast_90Days,
    }),
    kind: DATE_RANGE.RangeLast_90Days,
  },
  {
    label: 'Last 365 days',
    range: () => ({
      startDate: startOfDay(setYear(new Date(), defineds.startOfToday.getFullYear() - 1)),
      endDate: defineds.endOfYesterday,
      kind: DATE_RANGE.Range_1YearAgo,
    }),
    kind: DATE_RANGE.Range_1YearAgo,
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate:
        defineds.endOfYesterday.getMonth() === defineds.startOfMonth.getMonth()
          ? new Date(defineds.endOfYesterday.getTime() + 1000) // Add 1 second
          : endOfMonth(defineds.startOfMonth),
      kind: DATE_RANGE.RangeThisMonth,
    }),
    kind: DATE_RANGE.RangeThisMonth,
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      kind: DATE_RANGE.RangeLastMonth,
    }),
    kind: DATE_RANGE.RangeLastMonth,
  },
  {
    label: 'Last year',
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
      kind: DATE_RANGE.RangeLastYear,
    }),
    kind: DATE_RANGE.RangeLastYear,
  },
  {
    label: 'This year',
    range: () => ({
      startDate: defineds.startOfThisYear,
      endDate: defineds.endOfYesterday,
      kind: DATE_RANGE.RangeThisYear,
    }),
    kind: DATE_RANGE.RangeThisYear,
  },
];

export const staticDateRanges = defaultPeriod => {
  const defaultRange = defaultPeriod
    ? [
        {
          label: 'This period',
          range: () => ({
            startDate: new Date(defaultPeriod.startDate),
            endDate: new Date(defaultPeriod.endDate),
            kind: DATE_RANGE.RangeDefaultPeriod,
          }),
          isSelected: selectedRange =>
            selectedRange.startDate.getTime() === new Date(defaultPeriod.startDate).getTime() &&
            selectedRange.endDate.getTime() === new Date(defaultPeriod.endDate).getTime(),
        },
      ]
    : [];

  return createStaticRanges([
    ...defaultRange,
    ...dateRangesOptions.map(range => ({
      label: range.label,
      range: range.range,
      isSelected: selectedRange => selectedRange.kind === range.kind,
    })),
  ]);
};
