/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import Searchbox from '../organizations_dropdown/Searchbox';
import { t, formatPercentage } from '../i18n';
import Switch from '../components/form/Switch';
import {
  Col,
  CreateElementButton,
  Row,
  Heading,
  Text,
  cssVariables,
  Badge,
  Button,
  Loader,
  SimpleCard,
  Icon,
} from '../components';
import { SortableElement, SortableContainer } from '../components/Sortable';
import EmptyState from '../components/EmptyState';
import FbAdtextTile from './FbAdtextTile';
import arrayMove from '../utils/arrayMove';

const QUERY_FB_ADTEXTS = gql`
  query GetFBAdtexts(
    $organizationId: BigInt
    $campaignSettingId: BigInt
    $campaignSettingIdString: String!
    $fulltext: String
    $active: String
    $useDynamicFormat: Boolean
  ) {
    fbAdtexts: collection(
      identifier: "fbAdtexts"
      order: "position"
      orderDirection: "asc"
      page: 0
      limit: 1000
      organizationId: $organizationId
      campaignSettingId: $campaignSettingId
    ) {
      fbAdtexts(fulltext: $fulltext, active: $active, useDynamicFormat: $useDynamicFormat) {
        id
        name
        status
        headline
        description
        format
        creativeSource
        humanizedFormat
        position
        preview
        position
        fbProductSetInfo
        humanizedCreativeSource
        editLink
        previewImageUrl
      }
    }
    campaignSettings: collection(organizationId: $organizationId, identifier: "campaignSettings", page: 0, limit: -1) {
      campaignSettings(ids: [$campaignSettingIdString]) {
        id
        funnelInfo
      }
    }
  }
`;

const UPDATE_FB_ADTEXTS_POSITION_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $position: BigInt!) {
    updateFbAdtext(id: $id, position: $position) {
      fbAdtext {
        id
        position
      }
    }
  }
`;

const SortableAdItem = SortableElement(props => (
  <FbAdtextTile
    key={props.item?.id}
    item={props?.item}
    refetch={props?.refetch}
    setVersion={props?.setVersion}
    showItemsMenu={props?.showItemsMenu}
    sortable
  />
));

const CatalogAdsList = SortableContainer(({ collection = [], ...props }) => (
  <div>
    {collection.map((el, index) => (
      <SortableAdItem {...props} key={el?.id} index={index} item={el} testId={`ad-item-${index + 1}`} />
    ))}
  </div>
));

const FbAdList = ({
  organizationId,
  campaignSettingId,
  createBtnProps,
  version = 0,
  setVersion,
  hasAdsToImport,
  showMenu = true,
  showItemsMenu = true,
}) => {
  const [search, setSearch] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const campaignSettingIdString = String(campaignSettingId);
  const { data, loading, refetch } = useQuery(QUERY_FB_ADTEXTS, {
    variables: {
      organizationId,
      campaignSettingId,
      campaignSettingIdString,
      fulltext: search,
      active: activeOnly ? 'only_active' : '',
      use_dynamic_format: false,
    },
  });
  const [updateFBAdtextsPosition] = useMutation(UPDATE_FB_ADTEXTS_POSITION_MUTATION);

  useEffect(() => {
    refetch();
  }, [version]);

  const adTexts = data?.fbAdtexts?.fbAdtexts || [];
  const [catalogAds, setCatalogAds] = useState(
    adTexts.filter(ad => ad.creativeSource === 'catalog').sort((a, b) => a.position - b.position)
  );

  useEffect(() => {
    setCatalogAds(adTexts.filter(ad => ad.creativeSource === 'catalog').sort((a, b) => a.position - b.position));
  }, [data]);

  const manualAds = adTexts.filter(ad => ad.creativeSource === 'manual_upload');

  const createAdUrl = `/organizations/${organizationId}/campaign_settings/${campaignSettingId}/fb_a_d_s/new`;
  const emptyStateMessage = hasAdsToImport
    ? t('fb_advantage_plus.ads_overview.no_ads_found_text_with_import', {
        default: 'Start with creating your first ad. Or import existing ads below.',
      })
    : t('fb_advantage_plus.ads_overview.no_ads_found_text', { default: 'Start with creating your first ad.' });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    let newCatalogAds = arrayMove([...catalogAds], oldIndex, newIndex);
    const oldPositions = catalogAds.map(ad => ad.position);
    newCatalogAds = newCatalogAds.map((ad, index) => ({ ...ad, position: oldPositions[index] }));
    setCatalogAds(newCatalogAds);
    try {
      // Iterate through all ads and update positions one by one
      await Promise.all(
        newCatalogAds.map(ad =>
          updateFBAdtextsPosition({
            variables: {
              id: ad.id,
              position: ad.position,
            },
          })
        )
      );
      refetch();
    } catch (error) {
      new window.NotificationCenter().show_error(error);
    }
  };

  const showEmptyState = !adTexts.length && !loading && !activeOnly && !search;
  const emptySearch = !activeOnly && !search;

  const funnelInfo = data?.campaignSettings?.campaignSettings[0]?.funnelInfo || {};

  const leftProductsAspectRatio = formatPercentage(
    funnelInfo?.products_left > 0 ? (funnelInfo?.products_left / funnelInfo?.total_products) * 100 : 0
  );
  const usedProductsAspectRatio = formatPercentage(
    funnelInfo?.products_in_use > 0 ? (funnelInfo?.products_in_use / funnelInfo?.total_products) * 100 : 0
  );

  const wasCatalogAdsRecentlyUpdated = funnelInfo?.funnel_not_actual;

  return (
    <div style={{ position: 'relative' }}>
      <div className="mb-24">
        {showMenu && !showEmptyState && (
          <Row className="mv-16" padding="xl">
            <Col shrink>
              <CreateElementButton {...createBtnProps} />
            </Col>
            <Col width="186px">
              <Searchbox
                searchValue={search}
                handleSearch={({ target }) => {
                  setSearch(target.value);
                }}
                debounce={400}
                searchBoxText="Search..."
                testId="search-ad"
              />
            </Col>
            <Col shrink className="justify-content-center">
              <Switch
                label={t('active_only', { scope: 'react.create_element_modal' })}
                onChange={e => {
                  setActiveOnly(e.target.value);
                }}
                value={activeOnly}
                doNotUseInternalState
                name="data_source_active_only"
              />
            </Col>
          </Row>
        )}

        {adTexts.length > 0 && (
          <SimpleCard>
            {((emptySearch && adTexts.length > 0) || catalogAds.length > 0) && (
              <div className="mb-32">
                <Row className="mb-16" center>
                  <Col shrink>
                    <Heading tag="h3" size="xl" spacing={0}>
                      {t('react.fb_adtexts.catalog_ads_structure', { default: 'Catalog ads structure' })}
                    </Heading>
                  </Col>
                  <Col shrink>
                    <Badge kind="lightBlue" size="extraLarge" data-test-id="catalog-ads-badge">
                      {`${catalogAds.length} ${t('react.fb_adtexts.items', {
                        default: 'items',
                      })}`}
                    </Badge>
                  </Col>
                </Row>
                <div className="FbAdList-ads-container">
                  <div className="FbAdList-decorative-line" />
                  <Row center justifyBetween className="mb-8">
                    <Col shrink style={{ paddingLeft: '16px' }}>
                      <Text size="sm" style={{ color: cssVariables.textSubtle }}>
                        {t('react.fb_adtexts.ad_info', { default: 'Ad info' })}
                      </Text>
                    </Col>
                    <Col shrink style={{ paddingRight: '120px' }}>
                      <Text size="sm" style={{ color: cssVariables.textSubtle }} data-test-id="products-in-use">
                        <span style={{ fontWeight: 'bold' }}>{funnelInfo?.products_in_use}</span>{' '}
                        {t('react.fb_adtexts.products', { default: 'products' })}{' '}
                        <span style={{ fontWeight: 'bold' }}>({usedProductsAspectRatio})</span>
                      </Text>
                    </Col>
                  </Row>
                  <CatalogAdsList
                    collection={catalogAds}
                    refetch={refetch}
                    setVersion={setVersion}
                    showItemsMenu={showItemsMenu}
                    onSortEnd={onSortEnd}
                    useDragHandle
                    lockAxis="y"
                  />
                  <Row center justifyEnd className="mb-8">
                    <Col shrink style={{ paddingRight: '120px' }}>
                      <Text size="sm" style={{ color: cssVariables.textSubtle }} data-test-id="products-left">
                        <span style={{ fontWeight: 'bold' }}>{funnelInfo?.products_left}</span>{' '}
                        {t('react.fb_adtexts.products_left', { default: 'products left' })}{' '}
                        <span style={{ fontWeight: 'bold' }}>({leftProductsAspectRatio})</span>
                      </Text>
                    </Col>
                  </Row>
                  <div className="FbAdList-item">
                    <Button
                      tag="a"
                      href={`${createAdUrl}?creative-source=catalog`}
                      icon="plus"
                      secondary
                      data-test-id="create-new-ad"
                    >
                      {t('react.fb_adtexts.create_new_catalog_ad', { default: 'Create new catalog ad' })}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {((emptySearch && adTexts.length > 0) || manualAds.length > 0) && (
              <>
                <Row className="mb-16" center>
                  <Col shrink>
                    <Heading tag="h3" size="xl" spacing={0}>
                      {t('react.fb_adtexts.static_ads', { default: 'Static ads' })}
                    </Heading>
                  </Col>
                  <Col shrink>
                    <Badge kind="lightBlue" size="extraLarge" data-test-id="static-ads-badge">
                      {`${manualAds.length} ${t('react.fb_adtexts.items', {
                        default: 'items',
                      })}`}
                    </Badge>
                  </Col>
                </Row>
                <div className="FbAdList-ads-container">
                  <div className="FbAdList-decorative-line" />
                  {manualAds?.map(item => (
                    <FbAdtextTile
                      key={item.id}
                      item={item}
                      refetch={refetch}
                      setVersion={setVersion}
                      showItemsMenu={showItemsMenu}
                    />
                  ))}
                  <div className="FbAdList-item mt-16">
                    <Button tag="a" href={createAdUrl} icon="plus" secondary data-test-id="create-new-ad">
                      {t('react.fb_adtexts.create_new_static_ad', { default: 'Create new static ad' })}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </SimpleCard>
        )}

        {loading && (
          <Row center justifyCenter className="Tile Tile-content" style={{ padding: '48px' }}>
            <Loader size="big" />
          </Row>
        )}

        {adTexts?.length === 0 && !loading && (activeOnly || search) && (
          <div className="mv-16">
            <EmptyState
              icon="empty_search_magnifier"
              emptySearch={t('fb_adtext.no_ads_found', {
                default: 'No ads found',
              })}
              hasBorder
            >
              <div className="mt-16">
                <Text style={{ color: cssVariables.textMuted }}>
                  {t('fb_adtext.no_ads_found_description', {
                    default: 'No ads matching your search query. Try to use different keyword.',
                  })}
                </Text>
              </div>
            </EmptyState>
          </div>
        )}

        {showEmptyState && (
          <div className="mv-16">
            <EmptyState icon="a_d_text_icon" hasBorder>
              <div className="mt-24">
                <Heading tag="h2" spacing={16}>
                  {t('fb_advantage_plus.ads_overview.no_ads_found_title', { default: 'No ads found' })}
                </Heading>
                <Text tag="div" className="mv-16" style={{ color: cssVariables.textMuted }}>
                  {emptyStateMessage}
                </Text>
                <Button tag="a" href={createAdUrl} icon="plus" kind="primary" data-test-id="create-new-ad">
                  {t('fb_advantage_plus.ads_overview.create_ad_cta', { default: 'Create new ad' })}
                </Button>
              </div>
            </EmptyState>
          </div>
        )}
      </div>
      {wasCatalogAdsRecentlyUpdated && catalogAds.length > 0 && (
        <div
          className="mb-24"
          style={{
            position: 'sticky',
            bottom: '0px',
            border: `1px solid ${cssVariables.interfaceOutlineDefault}`,
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          <Row center style={{ padding: '13px 12px', backgroundColor: cssVariables.statusWarningFaint }}>
            <Col shrink style={{ marginRight: '12px' }}>
              <Icon kind="warning" size="20px" color={cssVariables.statusWarningDefault} />
            </Col>
            <Col shrink style={{ marginRight: '12px' }}>
              <Text bold style={{ color: cssVariables.statusWarningIntense }}>
                {t('react.fb_adtexts.catalog_ads_changes_detected', { default: 'Catalog Ads Changes Detected' })}
              </Text>
            </Col>
            <Col shrink>
              <Text size="xs" style={{ color: cssVariables.statusWarningIntense }}>
                {t('react.fb_adtexts.you_have_some_unsaved_changes_in_the_catalog_ads', {
                  default:
                    'You have some unsaved changes in the catalog ads. Sync now or they will be automatically synced during the next campaign synchronization.',
                })}
              </Text>
            </Col>
          </Row>
          <div
            style={{
              padding: '12px',
              backgroundColor: cssVariables.interfaceSurfaceLowest,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Button
              primary
              icon="wiz-sync"
              tag="a"
              href={`/organizations/${organizationId}/fb_advantage_plus_campaigns/${campaignSettingId}/system_sync`}
              data-test-id="synchronize-campaign-now"
            >
              {t('react.fb_adtexts.synchronize_campaign_now', { default: 'Synchronize campaign now' })}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

FbAdList.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  createBtnProps: PropTypes.object,
  emptyState: PropTypes.object,
  showMenu: PropTypes.bool,
  version: PropTypes.number,
  setVersion: PropTypes.func,
  hasAdsToImport: PropTypes.bool,
};

export default FbAdList;
