import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../i18n';
import { Button, Tile, Select, DndDragHandle, Row, Col } from '../components';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';

class Replace extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    replace_type: PropTypes.string,
    dragHandleProps: PropTypes.any,
    to: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
    what: PropTypes.string,
    itemsToRender: PropTypes.number,
  };

  static defaultProps = {
    options: {},
    replace_type: 'text',
  };

  state = {
    replace_type: this.props.replace_type || 'text',
    what: this.props.what || '',
    to: this.props.to || '',
  };

  getReplacesTypes = () => this.props.options.replace_types || [];

  handleReplaceType = ({ target: { value } }) => {
    if (value !== this.state.replace_type) {
      this.setState({ replace_type: value });
      this.props.updateFieldData(this.props.id, { replace_type: value });
      updatePreview();
    }
  };
  handleWhat = ({ target: { value } }) => {
    if (value !== this.state.what) {
      this.setState({ what: value });
      this.props.updateFieldData(this.props.id, { what: value });
      updatePreview();
    }
  };
  handleTo = ({ target: { value } }) => {
    if (value !== this.state.to) {
      this.setState({ to: value });
      this.props.updateFieldData(this.props.id, { to: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove, dragHandleProps } = this.props;
    const { replace_type, what, to } = this.state;

    return (
      <Tile contentStyle={{ padding: '8px 16px' }} className="mb-8" style={{ maxWidth: '800px' }}>
        <Row center data-test-id="nested-field-replace">
          <Col width="25px">
            <DndDragHandle {...dragHandleProps} />
          </Col>
          <Col width="20%" data-test-id="replace-type">
            <Select
              value={replace_type}
              doNotUseInternalState
              onChange={this.handleReplaceType}
              className="mb-0"
              {...getSubFiledOptions('replace_type')}
            >
              {this.getReplacesTypes().map(option => (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              ))}
            </Select>
          </Col>
          <Col grow data-test-id="replace-input" style={{ maxWidth: '30%' }}>
            <PlaceholderInput
              defaultValue={what || ''}
              onChange={this.handleWhat}
              placeholder={t(`views.replaces.fields.${replace_type}`)}
              {...getSubFiledOptions('what')}
            />
          </Col>
          <Col grow data-test-id="replace-to">
            {replace_type !== 'regexp_extract' && (
              <PlaceholderInput
                defaultValue={to || ''}
                onChange={this.handleTo}
                placeholder={t('views.replaces.fields.to')}
                {...getSubFiledOptions('to')}
              />
            )}
          </Col>
          <Col shrink>
            {id && onRemove && this.props.itemsToRender > 1 && (
              <Button
                tertiary
                onlyIcon
                disabled={disabled}
                onClick={onRemove}
                tabIndex="-1"
                icon="trash"
                data-test-id="nested-field-remove"
              />
            )}
          </Col>
        </Row>
      </Tile>
    );
  }
}

export default Replace;
