import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Modal, ModalFooter, ModalBody } from '../components/Modal';
import {
  DropdownMenu,
  Link,
  Row,
  Col,
  Switch,
  Text,
  Button,
  cssVariables,
  Icon,
  SimpleInput,
  UsedByGraph,
  DragHandle,
} from '../components';
import { t, formatPercentage } from '../i18n';

const UPDATE_FB_ADTEXT_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $name: String) {
    updateFbAdtext(id: $id, name: $name) {
      fbAdtext {
        id
        name
      }
    }
  }
`;

const UPDATE_FB_ADTEXT_STATUS_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $status: String) {
    updateFbAdtext(id: $id, status: $status) {
      fbAdtext {
        id
        status
      }
    }
  }
`;

const DELETE_FB_ADTEXT_MUTATION = gql`
  mutation DeleteFbAdtext($id: BigInt!) {
    deleteFbAdtext(id: $id) {
      fbAdtext {
        id
      }
    }
  }
`;

const FbAdtextTile = ({ item, refetch, setVersion, sortable }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tempName, setTempName] = useState(item.name);
  const [updateFBAdtextMutation] = useMutation(UPDATE_FB_ADTEXT_MUTATION);
  const [deleteFBAdtextMutation] = useMutation(DELETE_FB_ADTEXT_MUTATION);
  const [updateFBAdtextStatus] = useMutation(UPDATE_FB_ADTEXT_STATUS_MUTATION);

  const openEditModal = () => {
    setTempName(item.name);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleNameChange = value => {
    setTempName(value);
  };

  const handleSave = async () => {
    try {
      await updateFBAdtextMutation({
        variables: {
          id: item.id,
          name: tempName,
        },
      });
      refetch();
      closeEditModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating item:', error);
    }
  };

  const handleSwitchChange = async value => {
    try {
      await updateFBAdtextStatus({
        variables: {
          id: item.id,
          status: value ? 'running' : 'paused',
        },
      });
      refetch();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating item status:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteFBAdtextMutation({
        variables: {
          id: item.id,
        },
      });
      refetch();
      setVersion(v => v + 1);
      closeDeleteModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting item:', error);
    }
  };

  if (!item) return null;

  let productsAspectRatio = 0;
  if (item?.fbProductSetInfo?.count_at_product_set && item?.fbProductSetInfo?.products_count) {
    productsAspectRatio = formatPercentage(
      item?.fbProductSetInfo?.products_count > 0
        ? (item?.fbProductSetInfo?.count_at_product_set / item?.fbProductSetInfo?.products_count) * 100
        : 0
    );
  }

  const isVideoPreview =
    !!item?.preview?.adtext_lines?.feeds?.video_asset ||
    !!item?.preview?.adtext_lines?.carousel?.[0]?.video_asset ||
    false;

  return (
    <div className="FbAdList-item" data-test-id="ad-card">
      <Row
        center
        justifyBetween
        style={{
          minHeight: '82px',
          marginTop: 0,
          marginBottom: '8px',
          padding: '12px',
          borderRadius: '8px',
          border: `1px solid ${cssVariables.interfaceOutlineBold}`,
          background: cssVariables.interfaceSurfaceLowest,
        }}
      >
        <Row center>
          {sortable && (
            <Col className="FbAdList-drag-handle" shrink style={{ marginRight: '17px', marginLeft: '5px' }}>
              <DragHandle />
            </Col>
          )}
          <Col shrink style={{ marginRight: '12px' }}>
            <Switch
              pausePlay
              doNotUseInternalState
              value={item.status === 'running'}
              onChange={({ target: { value } }) => handleSwitchChange(value)}
            />
          </Col>
          {item?.previewImageUrl && (
            <Col shrink style={{ marginRight: '12px' }}>
              <div style={{ position: 'relative' }}>
                <img
                  src={item?.previewImageUrl}
                  style={{ width: '40px', height: '56px', objectFit: 'cover' }}
                  alt={item?.name}
                />
                {isVideoPreview && (
                  <div
                    style={{
                      position: 'absolute',
                      width: '20px',
                      height: '20px',
                      top: 'calc(50% - 10px)',
                      left: 'calc(50% - 10px)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'rgba(0, 0, 0, 0.3)',
                      borderRadius: '50%',
                      border: '1px solid #ffffff',
                      zIndex: 2,
                      transition: '0.3s',
                    }}
                  >
                    <Icon kind="play" size="12px" color={cssVariables.white} />
                  </div>
                )}
              </div>
            </Col>
          )}
          <Col style={{ marginRight: '12px' }}>
            <Link href={item?.editLink}>{item?.name}</Link>
            <Text
              tag="div"
              style={{ color: cssVariables.textSubtle, marginTop: '4px' }}
              size="sm"
              data-test-id="ad-type"
            >
              {item?.humanizedFormat} / {item?.humanizedCreativeSource}
            </Text>
          </Col>
        </Row>

        <Col shrink>
          <Row center>
            {item.creativeSource === 'manual_upload' && (
              <Col shrink style={{ marginRight: '12px', paddingRight: '32px' }}>
                <Text tag="div" style={{ color: cssVariables.textSubtle }} size="sm" data-test-id="ad-info">
                  {t('fb_advantage_plus.ads_overview_page.static_ad', { default: 'Static ad' })}
                </Text>
              </Col>
            )}
            {item.creativeSource === 'catalog' && (
              <Row center style={{ marginRight: '12px', paddingRight: '32px' }}>
                <Col style={{ marginRight: '44px', paddingTop: '2px', width: '100px' }} data-test-id="used-product-set">
                  <Link icon="product-set" href={item?.fbProductSetInfo?.product_set_url} size="sm">
                    {item?.fbProductSetInfo?.product_set_name}
                  </Link>
                </Col>
                <Row center style={{ position: 'relative', width: '160px' }}>
                  <Col shrink style={{ marginRight: 0 }}>
                    <UsedByGraph
                      products={item?.fbProductSetInfo?.count_at_product_set}
                      productsTotal={item?.fbProductSetInfo?.products_count}
                    />
                  </Col>
                  <Col shrink data-test-id="products-count" style={{ position: 'relative' }}>
                    <Text
                      tag="div"
                      style={{ color: cssVariables.textSubtle, marginTop: '2px', width: '120px', wordBreak: 'normal' }}
                      size="sm"
                    >
                      <span style={{ fontWeight: 'bold' }}>{item?.fbProductSetInfo?.count_at_product_set}</span>{' '}
                      {t('fb_advantage_plus.ads_overview_page.products', { default: 'products' })}{' '}
                      <span style={{ fontWeight: 'bold' }}>({productsAspectRatio})</span>
                    </Text>
                    <Icon
                      size="16px"
                      kind="arrow-down"
                      color={cssVariables.iconGhost}
                      style={{ position: 'absolute', bottom: '-23px', left: 'calc(50% - 8px)' }}
                    />
                  </Col>
                </Row>
              </Row>
            )}
            <Col shrink style={{ marginRight: '12px' }}>
              <Button
                kind="tertiary"
                tag="a"
                size="large"
                onlyIcon
                icon="edit"
                href={item.editLink}
                data-test-id="ad-edit"
              />
            </Col>
            <Col shrink>
              <DropdownMenu secondary hideOnClick={false} placement="auto-start" data-test-id="ad-dropdown">
                <div style={{ padding: '8px', marginBottom: '4px' }}>
                  <Switch
                    label={item.status === 'running' ? 'Pause ad' : 'Run ad'}
                    pausePlay
                    doNotUseInternalState
                    value={item.status === 'running'}
                    onChange={e => {
                      handleSwitchChange(e.target.value);
                    }}
                  />
                </div>
                <div style={{ marginBottom: '4px' }}>
                  <Link icon="type" onClick={openEditModal}>
                    Rename
                  </Link>
                </div>
                <div>
                  <Link danger icon="trash" onClick={openDeleteModal}>
                    Delete
                  </Link>
                </div>
              </DropdownMenu>
            </Col>
          </Row>
        </Col>
      </Row>
      {showEditModal && (
        <Modal
          onClose={closeEditModal}
          size="small"
          heading={
            <Row center>
              <Icon kind="type" size="24px" className="mr-12" color={cssVariables.iconSubtle} />
              Rename ad
            </Row>
          }
        >
          <ModalBody>
            <SimpleInput
              name="name"
              value={tempName}
              onChange={handleNameChange}
              label="Edit name"
              id={item.id}
              showClearButton={false}
              testId="ad-name"
            />
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button primary onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col>
                <Button secondary onClick={closeEditModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          onClose={closeDeleteModal}
          size="small"
          heading={
            <Row center>
              <Icon kind="trash" size="24px" className="mr-12" color={cssVariables.statusAttentionDefault} />
              {`Delete ${item.name}`}
            </Row>
          }
        >
          <ModalBody>
            <p>
              Are you sure you want to delete {item.name}? This action cannot be undone. Please confirm if you want
              proceed with deletion.
            </p>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button primary status="attention" icon="trash" onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
              <Col>
                <Button secondary onClick={closeDeleteModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

FbAdtextTile.propTypes = {
  item: PropTypes.object,
  refetch: PropTypes.func,
  setVersion: PropTypes.func,
};

export default FbAdtextTile;
