import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import cs from 'classnames';
import { Button, Icon, Pagination, StateContent, InfoBox } from '../index';
import { t } from '../../i18n';
import Searchbox from '../../organizations_dropdown/Searchbox';
import EmptyImage from '../../../../assets/images/empty_search_inside.svg';

export const GET_PRODUCT_SETS = gql`
  query dataSources(
    $organizationId: BigInt!
    $name: String
    $active: Boolean
    $offset: Int!
    $limit: Int!
    $skippedDataSourceId: BigInt!
  ) {
    dataSources(
      organizationId: $organizationId
      name: $name
      active: $active
      offset: $offset
      limit: $limit
      skippedDataSourceId: $skippedDataSourceId
    ) {
      nodes {
        icon
        name
        dataSourceState
        id
      }
      pageInfo {
        limit
        total
        hasNextPage
      }
    }
  }
`;

export const DUPLICATE_PRODUCT_SETS = gql`
  mutation($organizationId: BigInt!, $targetDataSourceId: BigInt!, $itemGroupId: BigInt!) {
    duplicateItemGroupToDataSource(
      organizationId: $organizationId
      targetDataSourceId: $targetDataSourceId
      itemGroupId: $itemGroupId
    ) {
      newItemGroupLink
      targetDataSourceLink
      errors
    }
  }
`;

export const VALIDATE_PRODUCT_SET = gql`
  query($organizationId: BigInt!, $targetDataSourceId: BigInt!, $itemGroupId: BigInt!) {
    itemGroupConditionsVariablesValidation(
      organizationId: $organizationId
      targetDataSourceId: $targetDataSourceId
      itemGroupId: $itemGroupId
    )
  }
`;

const ProductSetsDuplicationDataSource = ({
  organization_id,
  item_group_id,
  item_group_name,
  current_data_source_id,
}) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [activeOnly, setActiveOnly] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const PAGE_LIMIT = 8;

  const { data } = useQuery(GET_PRODUCT_SETS, {
    variables: {
      organizationId: organization_id,
      name: search,
      active: activeOnly,
      offset: currentPage * PAGE_LIMIT,
      limit: PAGE_LIMIT,
      skippedDataSourceId: current_data_source_id,
    },
  });

  const { loading: validationLoading, refetch: fetchValidation } = useQuery(VALIDATE_PRODUCT_SET, {
    variables: {
      organizationId: organization_id,
      targetDataSourceId: activeItem,
      itemGroupId: item_group_id,
    },
    skip: true,
  });

  const selectedDataSource = data?.dataSources?.nodes.find(item => item.id === activeItem);
  const newDataSourceLink = `/organizations/${organization_id}/data_sources/${selectedDataSource?.id}/table`;

  const [duplicateProductSets] = useMutation(DUPLICATE_PRODUCT_SETS, {
    onCompleted: res => {
      document.querySelector(`#duplicate-datasource-close-${item_group_id}`)?.click();
      new window.NotificationCenter().show_success(
        `<a href=${res?.duplicateItemGroupToDataSource?.newItemGroupLink || '#'}>${item_group_name || 'Product set'}</a>
        ${t('duplicate_product_sets.success', { default: ' was successfully duplicated to ' })}
        <a href=${res?.duplicateItemGroupToDataSource?.targetDataSourceLink ||
          newDataSourceLink}>${selectedDataSource?.name || 'data source'}</a>`
      );
    },
    onError: () => {
      document.querySelector(`#duplicate-datasource-close-${item_group_id}`)?.click();
      new window.NotificationCenter().show_error(
        `${item_group_name || 'Product set'}
        ${t('duplicate_product_sets.error', { default: ' could not be duplicated to ' })}
        <a href=${newDataSourceLink}>${selectedDataSource?.name || 'data source'}</a>`
      );
    },
  });

  const handleDuplicate = async () => {
    await duplicateProductSets({
      variables: {
        organizationId: organization_id,
        targetDataSourceId: activeItem,
        itemGroupId: item_group_id,
      },
    });
  };

  const handleValidation = async id => {
    await setActiveItem(id);
    const res = await fetchValidation();

    setIsValid(res.data.itemGroupConditionsVariablesValidation);
  };

  const handlePagination = async page => {
    setActiveItem(null);
    setCurrentPage(page);
  };

  const handleSearch = value => {
    setActiveItem(null);
    setCurrentPage(0);
    setSearch(value);
  };

  // const handleActiveSwitch = value => {
  //   setActiveItem(null);
  //   setCurrentPage(0);
  //   setActiveOnly(value);
  // };

  const clearOnClose = () => {
    setSearch('');
    setCurrentPage(null);
    setActiveOnly(false);
    setActiveItem(null);
  };

  document.querySelector(`#products-set-data-source-${item_group_id}`)?.addEventListener('click', e => {
    if (e.target !== e.currentTarget) return;
    clearOnClose();
  });

  if (!data && !search) {
    return false;
  }

  return (
    <div className="duplication-modal">
      <div className="duplication-modal__header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Icon kind="product-set" size="24px" />
          <span>{t('duplicate_product_sets.header.title', { default: 'Duplicate product sets' })}</span>
        </div>

        <a href="#" className="modal-close Modal-close fc-close" data-dismiss="modal" onClick={clearOnClose}>
          {' '}
        </a>
      </div>
      <div className="duplication-modal__body">
        <div className="duplication-modal__form">
          <div className="duplication-modal__form-header">
            <div className="d-flex align-items-center">
              <Searchbox
                searchBoxText={t('duplicate_product_sets.header.search', { default: 'Search' })}
                searchValue={search}
                handleSearch={({ target: { value } }) => handleSearch(value)}
                hideReset
              />
              {/* <div className="ml-6"> */}
              {/*  <Switch */}
              {/*    value={activeOnly} */}
              {/*    onChange={({ target: { value } }) => handleActiveSwitch(value)} */}
              {/*    label={t('duplicate_product_sets.header.active_only', { default: 'Active only' })} */}
              {/*  /> */}
              {/* </div> */}
            </div>
            <div className="duplication-modal__form-hint">
              {t('duplicate_product_sets.form.search_hint', {
                default: 'Select the data sources where your product sets will be copied.',
              })}
            </div>
          </div>
          {data?.dataSources?.pageInfo?.total !== 0 && (
            <div className="duplication-modal__form-body">
              <div className="duplication-modal__items-list">
                {data?.dataSources?.nodes.map(item => (
                  <div
                    className={cs('duplication-modal__items-list_item', { active: activeItem === item.id })}
                    onClick={() => handleValidation(item.id)}
                    key={item.id}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="duplication-modal__items-list_radio" />
                        <Icon kind={item.icon} size="16px" />
                        <div className="duplication-modal__items-list_name">{item.name}</div>
                      </div>
                      <StateContent>{{ state: item.dataSourceState }}</StateContent>
                    </div>
                    {!isValid && activeItem === item.id && (
                      <InfoBox type="warning" withIcon className="mt-16">
                        {t('duplicate_product_sets.validation.warning', {
                          default:
                            'You can proceed with the duplication, but some variables are missing from the data source. Address them later if needed.',
                        })}
                      </InfoBox>
                    )}
                    {validationLoading && activeItem === item.id && (
                      <div className="duplication-modal__items-list_loading d-flex align-items-center">
                        <div className="duplication-modal__items-list_loading-circle" />
                        <div className="duplication-modal__items-list_loading-bar" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {data?.dataSources?.pageInfo?.total === 0 && (
            <div className="duplication-modal__body-empty">
              <img src={EmptyImage} alt="Empty" />
              <div className="duplication-modal__body-empty_label">
                {t('duplicate_product_sets.body.empty', { default: 'No data source found' })}
              </div>
              <span>
                {t('duplicate_product_sets.body.empty', {
                  default: "You don't have any data sources to which you can copy this product sets.",
                })}
              </span>
            </div>
          )}
        </div>

        {data?.dataSources?.pageInfo?.total > 0 && (
          <Pagination
            className="mt-24"
            current={currentPage}
            disableAnchor
            changePage={page => () => handlePagination(page)}
            maxPages={Math.ceil(data?.dataSources?.pageInfo?.total / PAGE_LIMIT)}
            hideDisabledControls
          />
        )}
      </div>
      <div className="duplication-modal__footer">
        <Button
          primary
          disabled={data?.dataSources?.pageInfo?.total === 0 || activeItem === null}
          onClick={() => handleDuplicate()}
        >
          {t('duplicate_product_sets.footer.duplicate', { default: 'Duplicate' })}
        </Button>
        <Button
          secondary
          data-dismiss="modal"
          id={`duplicate-datasource-close-${item_group_id}`}
          onClick={clearOnClose}
        >
          {t('duplicate_product_sets.footer.cancel', { default: 'Cancel' })}
        </Button>
      </div>
    </div>
  );
};

export default ProductSetsDuplicationDataSource;
