import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from '../Modal';
import { ActiveProductsBox, cssVariables } from '../index';
import Icon from '../Icon';
import InfoBox from '../InfoBox';
import { t, tHtml } from '../../i18n';
import SimpleTable from '../SimpleTable/SimpleTable';

const MATCH_RATE_ADDITIONAL_SOURCE_TYPE = gql`
  query MatchRateAdditionalSourceType($additionalSourceId: ID!) {
    additionalSourceType(id: $additionalSourceId) {
      id
      dataSourceAllItemsCount
      matchRatesPerDataField {
        dateTime
        columns
        rows {
          name
          data
        }
      }
    }
  }
`;

const MatchRateDetailsModal = ({ closeModal, additionalSourceId }) => {
  const [syncDate, setSyncDate] = useState('');
  const [headers, setHeaders] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const { data } = useQuery(MATCH_RATE_ADDITIONAL_SOURCE_TYPE, {
    variables: {
      additionalSourceId,
    },
  });

  const humanizeDate = dateString => {
    const date = moment(dateString);
    const now = moment();
    const diffDays = now.diff(date, 'days');

    if (date.isSame(now, 'day')) {
      return `Today at ${date.format('HH:mm')}`;
    }

    if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return `Yesterday at ${date.format('HH:mm')}`;
    }

    if (diffDays <= 3) {
      return `${diffDays} days ago at ${date.format('HH:mm')}`;
    }

    if (date.isSame(now, 'year')) {
      return `${date.format('DD.MM')} at ${date.format('HH:mm')}`;
    }

    return `${date.format('DD.MM.YY')} at ${date.format('HH:mm')}`;
  };

  const setTableWidth = itemsCount => {
    if (itemsCount > 5) {
      return 'full';
    }

    if (itemsCount > 2) {
      return 'medium';
    }

    return 'small';
  };

  const parseLargeNumber = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  useEffect(() => {
    if (!data) {
      return;
    }

    const date = data?.additionalSourceType?.matchRatesPerDataField?.dateTime;
    setTotalItems(data?.additionalSourceType?.dataSourceAllItemsCount);

    const tableData = {
      rows: data?.additionalSourceType?.matchRatesPerDataField?.rows || [],
      columns: data?.additionalSourceType?.matchRatesPerDataField?.columns || [],
    };

    if (date) {
      setSyncDate(humanizeDate(date));
    }

    if (tableData.columns.length) {
      const rows = [];
      tableData?.rows?.forEach(item => rows.push([item.name, ...item.data]));
      const updatedColumns = ['Metrics', ...tableData.columns];

      setHeaders(updatedColumns);
      setRowData(rows);
    }
  }, [data]);

  return (
    <Modal size={setTableWidth(headers?.length)} onClose={closeModal}>
      <ModalHeader>
        <div className="match-rate-details__header">
          <Icon kind="calculator" size="24px" color={cssVariables.textDefault} />
          <span>Match rate</span>
        </div>
      </ModalHeader>
      <ModalBody className="match-rate-details__header">
        {headers.length ? (
          <>
            <InfoBox type="info" withIcon className="mb-24">
              {tHtml('match_rate.modal.info', {
                syncDate,
                default:
                  'Your match rates have been updated. Last sync: <b>%{syncDate}</b>. To access the most recent data, you can refresh or trigger a new sync. Please note that syncing a large data source may take some time.',
              })}
            </InfoBox>
            <SimpleTable>
              <SimpleTable.Head>
                {headers && headers.map(header => <SimpleTable.HeadCell width="120px">{header}</SimpleTable.HeadCell>)}
              </SimpleTable.Head>
              <SimpleTable.Body className="match-rate-details__body">
                {rowData &&
                  rowData.map(row => (
                    <SimpleTable.Row firstCellBorder>
                      {row.map((item, index) => (
                        <SimpleTable.Cell>
                          {index === 0 ? (
                            <div>{item}</div>
                          ) : (
                            <div className="match-rate-details__body-cell">
                              <div className="match-rate-details__body-left">
                                <ActiveProductsBox
                                  allItemsCount={totalItems}
                                  count={item}
                                  tooltip={t('match_rate.title', { default: 'Match rate' })}
                                  pieColor="#855BE2"
                                  hasRoundBorder
                                />
                                <span>{Math.round((100 * item) / totalItems)}%</span>
                              </div>
                              <div className="match-rate-details__body-right" data-test-id="match-rate-details">
                                <span>{parseLargeNumber(item)}</span> of <span>{parseLargeNumber(totalItems)}</span>
                              </div>
                            </div>
                          )}
                        </SimpleTable.Cell>
                      ))}
                    </SimpleTable.Row>
                  ))}
              </SimpleTable.Body>
            </SimpleTable>
          </>
        ) : (
          <InfoBox type="warning" withIcon>
            {t('additional_source.data_match_rate.infobox_warning', {
              default:
                'No matching data found in the selected source. To improve results, please review your settings and ensure the data source is up to date.',
            })}
          </InfoBox>
        )}
      </ModalBody>
    </Modal>
  );
};

MatchRateDetailsModal.propTypes = {
  closeModal: PropTypes.func,
  additionalSourceId: PropTypes.string,
};

export default MatchRateDetailsModal;
