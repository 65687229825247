import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '../index';
import { t } from '../../i18n';
import MatchRateDetailsModal from './MatchRateDetailsModal';
// eslint-disable-next-line import/extensions
import getCSRFToken from '../../utils/getCSRFToken';
import GrapqlProvider from '../../graphql_provider';

const MatchRateDetailModalButton = ({ additional_source_id, total_items }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const csrfToken = getCSRFToken();

  return (
    <GrapqlProvider csrfToken={csrfToken}>
      <Button
        data-test-id="mach-rate-show-details"
        text={t('additional_source.data_match_rate.show_more_button', { default: 'Show details' })}
        kind="secondary"
        size="small"
        onClick={() => setOpenDetails(true)}
      />

      {openDetails && (
        <MatchRateDetailsModal
          closeModal={() => setOpenDetails(false)}
          additionalSourceId={additional_source_id}
          totalItems={parseInt(total_items, 10)}
        />
      )}
    </GrapqlProvider>
  );
};

MatchRateDetailModalButton.propTypes = {
  additional_source_id: PropTypes.string,
  total_items: PropTypes.number,
};

export default MatchRateDetailModalButton;
