import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import {
  Modal,
  ModalHeader,
  ModalHeading,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Heading,
  Text,
  EmptyState,
  cssVariables,
  Button,
  InfoBox,
  Icon,
  Pagination,
  Tabs,
} from '../index';
import { MediaTile } from './index';
import useSearch from '../../hooks/useSearch';
import Searchbox from '../../organizations_dropdown/Searchbox';
import { PAGE_LIMIT, mediaTabs, MEDIA_TYPES } from './meta';
import { t, tHtml } from '../../i18n';

const DELETE_IMAGE = gql`
  mutation DeleteImage($id: BigInt!) {
    deleteImage(id: $id) {
      image {
        id
        deleted
      }
    }
  }
`;

const MediaGallery = ({
  refetch,
  onSelect,
  media,
  selectedMedia,
  selectedSource,
  uploadError,
  setUploadError,
  organizationId,
  campaignSettingId,
}) => {
  const [deleteImage] = useMutation(DELETE_IMAGE, { onCompleted: () => refetch() });
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [mediaIdToDelete, setMediaIdToDelete] = useState(null);
  const [videoToPlay, setVideoToPlay] = useState(null);
  const [activeTab, setActiveTab] = useState('all');

  const modalVideo = useRef(null);

  const { search, setSearch, result } = useSearch({
    collection: media,
    ignoreKeys: ['id', 'height', 'url', 'width', '__typename'],
  });

  const filteredResultByType = result.filter(m => m.type === activeTab || activeTab === 'all');
  const filteredResultBySource = filteredResultByType.filter(m => m.source === selectedSource || selectedSource === '');

  const paginatedMedia = useMemo(
    () => filteredResultBySource.slice(0 + PAGE_LIMIT * currentPage, PAGE_LIMIT * (currentPage + 1)),
    [currentPage, filteredResultBySource]
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [result, selectedSource, activeTab]);

  const onSelectHandler = (_media, disabled) => {
    if (disabled) return;
    if (_media.id === selectedMedia?.id) {
      onSelect({});
      return;
    }
    onSelect({
      xlinkHref: _media.url,
      width: _media.width,
      height: _media.height,
      filename: _media.name,
      id: _media.id,
      media_id: _media.id,
      videoSource: _media?.videoSource,
      type: _media.type,
      source: _media.source,
    });
  };

  const deleteMediaHandler = async () => {
    const id = mediaIdToDelete.id;
    if (selectedMedia?.id === id) {
      onSelect({});
    }
    if (mediaIdToDelete.type === MEDIA_TYPES.IMAGE) {
      deleteImage({
        variables: {
          id,
          deleted: true,
        },
      });
    }
    if (mediaIdToDelete.type === MEDIA_TYPES.VIDEO) {
      try {
        await fetch(
          `${window.location.origin}/organizations/${organizationId}/fb_advantage_plus_campaigns/${campaignSettingId}/videos/${id}`,
          {
            method: 'DELETE',
          }
        );
      } finally {
        refetch();
      }
    }
    setShowDeleteModal(false);
  };

  const videoIconClickHandler = (e, _media) => {
    e.stopPropagation();
    setShowVideoModal(true);
    setVideoToPlay(_media);
    setTimeout(() => {
      modalVideo.current?.play();
    }, 500);
  };

  return (
    <>
      <Row grow>
        <div className="w-100">
          <Row className="mb-16" padding="xl" shrink>
            <Col width="392px">
              <Searchbox
                testId="media-search"
                searchBoxText={t('react.image_generator.modal.search')}
                searchValue={search}
                handleSearch={({ target: { value } }) => setSearch(value)}
              />
            </Col>
          </Row>
          {uploadError && (
            <Row className="mb-16">
              <Col grow>
                <InfoBox type="error" withIcon onClose={() => setUploadError(false)}>
                  <span>
                    <b style={{ marginRight: '8px' }}>{t('media.media_upload_error', { default: 'Upload error' })}</b>
                    {tHtml(`media.media_upload_error_${uploadError}`)}
                  </span>
                </InfoBox>
              </Col>
            </Row>
          )}
          <Row>
            <Col grow>
              <InfoBox type="info" withIcon>
                <span>
                  <Heading size="lg" spacing={8} tag="h3" style={{ margin: '8px 0' }}>
                    {t('media.media_requirements', { default: 'Image and video requirements' })}
                  </Heading>
                  <ul style={{ paddingLeft: '20px' }}>
                    <li>
                      {t('media.requirements.image600x600', {
                        default:
                          'Images can be up to 30MB in size, with a minimum resolution of 600x600 pixels. Accepted formats include jpg, png, and webp.',
                      })}
                    </li>
                    <li style={{ marginTop: '3px' }}>
                      {tHtml('media.requirements.video_from_fb', {
                        default:
                          'Videos can be up to 4GB in size, with a minimum resolution of 120x120 pixels. We recommend using MP4, MOV and GIF format but other formats are also possible. <a target="blank" href="https://www.facebook.com/business/help/1640701476174343?id=603833089963720">All supported formats</a>',
                      })}
                    </li>
                  </ul>
                </span>
              </InfoBox>
            </Col>
          </Row>

          <div className="mt-16">
            <Tabs
              tabs={mediaTabs}
              variant="underline"
              direction="bottom"
              activeTab={activeTab}
              onTabSelect={setActiveTab}
              testId="media-type"
            />
          </div>

          <Row padding="l" flexwrap className="mv-16 Grid--fifthWithoutMargin" style={{ width: 924, maxWidth: '100%' }}>
            {media.length === 0 && (
              <EmptyState icon="image_editor_icon">
                <Heading className="mt-24" tag="h3">
                  {t('media.no_media', {
                    default: 'No Media Yet',
                  })}
                </Heading>
                <Text className="MediaUpload-emptyDescription">
                  {t('media.no_media_description', {
                    default:
                      "Your media library is currently empty. Start by uploading your first media. Once you do, they'll appear here.",
                  })}
                </Text>
              </EmptyState>
            )}

            {filteredResultBySource.length === 0 && media.length > 0 && (
              <EmptyState icon="image_editor_no_images">
                <Heading className="mt-24" tag="h3">
                  {t('media.no_results_found', {
                    default: 'No Results Found',
                  })}
                </Heading>
                <Text className="MediaUpload-emptyDescription">
                  {t('media.no_results_found_description', {
                    default:
                      'We couldn’t find any media matching your search. Try adjusting your search terms. You can also upload new media to find exactly what you need.',
                  })}
                </Text>
              </EmptyState>
            )}

            {paginatedMedia.map(_media => {
              let disabled = false;
              if (_media.type === MEDIA_TYPES.IMAGE) {
                disabled = _media?.width < 600 || _media?.height < 600;
              }
              return (
                <Col width="172px" key={_media.id}>
                  <MediaTile
                    label={_media.name}
                    url={_media.url}
                    resolution={`${_media.width}x${_media.height}`}
                    source={_media.source}
                    disabled={disabled}
                    selected={
                      selectedMedia?.id === _media.id || selectedMedia?.image_id?.toString() === _media.id.toString()
                    }
                    type={_media.type}
                    duration={_media.length}
                    onSelect={() => onSelectHandler(_media, disabled)}
                    onDelete={e => {
                      e.stopPropagation();
                      setMediaIdToDelete(_media);
                      setShowDeleteModal(true);
                    }}
                    onPlayIconClick={e => videoIconClickHandler(e, _media)}
                  />
                </Col>
              );
            })}
          </Row>

          <Row justifyEnd>
            <Col shrink>
              <Pagination
                className="mt-16"
                current={currentPage}
                disableAnchor
                changePage={page => () => setCurrentPage(page)}
                maxPages={Math.ceil(filteredResultBySource.length / PAGE_LIMIT)}
              />
            </Col>
          </Row>
        </div>
      </Row>
      {showDeleteModal && (
        <Modal size="small" onClose={() => setShowDeleteModal(false)}>
          <ModalHeader>
            <ModalHeading>
              <Row center>
                <Icon kind="trash" size="24px" className="mr-12" color={cssVariables.interactiveAttentionDefault} />
                {t('media.delete_media_modal_heading', { default: 'Delete media' })}
              </Row>
            </ModalHeading>
          </ModalHeader>
          <ModalBody>
            {t('media.delete_media_modal_body', {
              default:
                'Are you sure you want to delete this media? This action cannot be undone. Please confirm if you want to proceed with the deletion.',
            })}
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col style={{ marginRight: '12px' }}>
                <Button primary status="attention" icon="trash" onClick={deleteMediaHandler}>
                  {t('media.delete_media_modal_confirm', { default: 'Delete' })}
                </Button>
              </Col>
              <Col>
                <Button secondary onClick={() => setShowDeleteModal(false)}>
                  {t('media.delete_media_modal_cancel', { default: 'Cancel' })}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}
      {showVideoModal && (
        <Modal borderless centered size="auto" onClose={() => setShowVideoModal(false)}>
          {videoToPlay?.videoSource?.includes('.gif') ? (
            <img
              src={videoToPlay.videoSource}
              alt={videoToPlay.name}
              style={{ display: 'block', maxWidth: '80vw', maxHeight: '80vh' }}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video ref={modalVideo} controls style={{ display: 'block', maxWidth: '80vw', maxHeight: '80vh' }}>
              <source src={videoToPlay.videoSource} />
            </video>
          )}
        </Modal>
      )}
    </>
  );
};

MediaGallery.propTypes = {
  refetch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  media: PropTypes.array,
  selectedMedia: PropTypes.object,
  selectedSource: PropTypes.string,
  uploadError: PropTypes.string,
  organizationId: PropTypes.number,
  campaignSettingId: PropTypes.number,
};

export default MediaGallery;
