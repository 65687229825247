import React from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { Icon, Tooltip, SimpleTooltip } from './index';
import { t } from '../i18n';

export const BetaBadge = ({ text, className, size = 'large' }) => (
  <Badge size={size} kind="blue" className={className}>
    <Icon kind="beta" inheritColor />
    {text}
  </Badge>
);
BetaBadge.propTypes = {
  className: RPT.string,
  text: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

export const MoneyBadge = ({ className, size = 'large' }) => (
  <Badge size={size} kind="green" className={className}>
    <Tooltip text={t('views.money_badge.tooltip')}>
      <Icon kind="dollar" inheritColor />
    </Tooltip>
  </Badge>
);
MoneyBadge.propTypes = {
  className: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

export const NewBadge = ({ text, className, size = 'large' }) => (
  <Badge size={size} kind="pinky" className={className}>
    <Icon kind="flame" inheritColor />
    {text}
  </Badge>
);
NewBadge.propTypes = {
  className: RPT.string,
  text: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

export const TipBadge = ({ className, size = 'large' }) => (
  <Badge size={size} kind="grape" uppercase className={className}>
    <Icon kind="bullseye" inheritColor />
    {t('views.tip_badge', { default: 'Tip' })}
  </Badge>
);
TipBadge.propTypes = {
  className: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

const freeBadgeTooltips = {
  PerformanceMaxCampaign: t('views.free_badge.performance_max_tooltip', {
    default: 'Free until 2026. Launch unlimited PMAX campaigns with no impact on your subscription fee.',
  }),
  FBAdvantagePlusCampaign: t('views.free_badge.fb_advantage_plus_tooltip', {
    default: 'Free until 2026. Launch unlimited Advantage+ campaigns with no impact on your subscription fee.',
  }),
};

export const FreeBadge = ({ className, campaignType, size }) => {
  const tooltipText = freeBadgeTooltips[campaignType];
  return (
    <Badge size={size} kind="green" icon="thumbs-up" className={className} uppercase tooltip={tooltipText}>
      {t('views.free_badge.text', { default: 'Free' })}
    </Badge>
  );
};

FreeBadge.propTypes = {
  className: RPT.string,
  campaignType: RPT.string.isRequired,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

const Badge = ({
  count,
  className,
  isWithBorder,
  kind,
  label,
  icon,
  iconAfter,
  size = 'medium',
  spaced,
  tooltip,
  uppercase,
  children,
  ...rest
}) => (
  <SimpleTooltip text={tooltip} placement="top">
    <span
      className={cs('Badge', className, {
        'Badge--uppercase': uppercase,
        'Badge--withBorder': isWithBorder,
        [`Badge--${kind}`]: kind,
        [`Badge--${size}`]: size,
        'Badge--empty': !children && typeof count === 'undefined' && !label,
      })}
      {...rest}
    >
      {icon && <Icon inheritColor kind={icon} />}
      {count && count > 99 ? '99+' : count}
      {children}
      {iconAfter && <Icon inheritColor kind={iconAfter} />}
    </span>
  </SimpleTooltip>
);

Badge.propTypes = {
  className: RPT.string,
  count: RPT.oneOfType([RPT.number, RPT.string]),
  children: RPT.any,
  icon: RPT.string,
  iconAfter: RPT.bool,
  isWithBorder: RPT.bool,
  kind: RPT.oneOf([
    'blue',
    'lightBlue',
    'red',
    'lightRed',
    'gray',
    'lightGray',
    'green',
    'lightGreen',
    'orange',
    'lightOrange',
    'pinky',
  ]),
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
  tooltip: RPT.string,
  uppercase: RPT.bool,
};

export default Badge;
