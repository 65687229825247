import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cs from 'classnames';
import { uniq, omitBy, isNil, isBoolean, isString } from 'lodash';
import { OrderContext } from './index';
import { setPageToUrl } from '../Pagination';
import mapping, { mappingAdditional } from './mapping';
import { getGraphqlQuery } from './getGraphqlQuery';
import { QueryStickyTable } from './StickyTable';
import ColumnPresetDropdown from './columns/Dropdown';
import * as Layout from '../layout';
import { LocalStateUsedContext, CreateElementButton } from '../index';
import { SearchWrapper, SearchContextProvider, validFilterKeys } from './search';
import { getQueryDefaultSearchState } from '../../utils/urlManipulation';
import { getLocalStorageDefaultSearchState, setLocalStorageDefaultSearchState } from './localStorage';
import fixSearchText from '../../utils/fixSearchText';
import { SegmentedButton } from '../form/index';
import Storage from '../../utils/storage';
import { t } from '../../i18n';
import concatPresence from '../../utils/concatPresence';

const VARIABLE_TRANSFORMATION_COLUMN_NAME = 'rules';

const ensureValidSearchOptions = (search, options = {}) => {
  if (search?.elementType) {
    if (!options.elementTypes.find(v => v.value === search.elementType)) {
      search.elementType = 'all'; // eslint-disable-line no-param-reassign
    }
  }

  if (search?.dataSourceId) {
    if (!options.dataSources.find(v => v === search?.dataSourceId)) {
      search.dataSourceId = 'all'; // eslint-disable-line no-param-reassign
    }
  }
  return search;
};

export const sortColumns = (columns, columnMapping) => {
  const requiredColumns = columns.filter(col => columnMapping[col].required);
  const leftColumns = columns.filter(col => !columnMapping[col].required && columnMapping[col].fixed === 'left');
  const middleColumns = columns.filter(col => !columnMapping[col].required && !columnMapping[col].fixed);
  const rightColumns = columns.filter(col => !columnMapping[col].required && columnMapping[col].fixed === 'right');
  return [...requiredColumns, ...leftColumns, ...middleColumns, ...rightColumns];
};

export const getFilters = (searchState, callBack) => {
  const prefilters = typeof callBack === 'function' ? callBack({ ...searchState }) : { ...searchState };
  // filter undefines, nulls and bools(otherwise fixSearchText will return error)
  const filters = omitBy(prefilters, x => isNil(x) || isBoolean(x) || (isString(x) && x.trim().length === 0));

  if (filters && Object.keys(filters).length > 0) {
    const result = `(${Object.keys(filters)
      .map(key => `${key}: ${fixSearchText(filters[key] || '')}`)
      .join(', ')})`;

    if (result !== '()') return result;
  }

  return '';
};

const getLayoutTypeSettings = collectionKey =>
  [
    collectionKey === 'adtextGroups' && [
      {
        value: 'branches',
        icon: 'hierarchy',
        doNotShowColumns: ['branch'],
        onStateSet: { order: 'name', orderDirection: 'asc' },
      },
      {
        value: 'adtextsOnly',
        icon: 'list',
        doNotShowColumns: [],
        onStateSet: { order: 'branch_name', orderDirection: 'asc' },
      },
    ],
    [null],
  ].find(i => i);

const defaultColumns = collectionKey =>
  JSON.stringify(
    Object.keys(mapping?.[collectionKey])
      .map(key => key)
      .sort()
  );

const getLocalStorageKeys = ({ collectionKey, suffix }) => {
  const defaultColumnsKey = concatPresence([`default-columns-${collectionKey}`, suffix], '--');
  const selectedColumnsKey = concatPresence([`selected-columns-${collectionKey}`, suffix], '--');
  return { defaultColumnsKey, selectedColumnsKey };
};

class GraphqlTable extends PureComponent {
  static propTypes = {
    accountAccessId: PropTypes.number,
    additionalHtml: PropTypes.string,
    campaignSettingId: PropTypes.number,
    additionalSourceId: PropTypes.number,
    children: PropTypes.any,
    collectionKey: PropTypes.string,
    columns: PropTypes.array,
    createButton: PropTypes.object,
    dataSourceId: PropTypes.number,
    feedExportId: PropTypes.number,
    disableColumnsChange: PropTypes.bool,
    disableFilters: PropTypes.bool,
    doNotShowColumns: PropTypes.array,
    emptyState: PropTypes.object,
    enabledFilters: PropTypes.array,
    forceSearchFilters: PropTypes.object,
    grid: PropTypes.bool,
    limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool,
    order: PropTypes.string,
    orderDirection: PropTypes.string,
    organizationId: PropTypes.number,
    parentDataSourceId: PropTypes.number,
    additionalDataSourceId: PropTypes.number,
    perOptions: PropTypes.array,
    searchOptions: PropTypes.object,
    overrideFilters: PropTypes.object,
    showLoaderInFilter: PropTypes.bool,
    hidePagination: PropTypes.bool,
    compactSearch: PropTypes.bool,
    suffix: PropTypes.string,
    statusCampaignSwitchWarning: PropTypes.bool,
  };

  static defaultProps = {
    doNotShowColumns: [],
    grid: false,
    perOptions: [10, 20, 50, 100],
    forceSearchFilters: {},
    searchOptions: {},
    overrideFilters: {},
    statusCampaignSwitchWarning: false,
  };

  static contextType = LocalStateUsedContext;

  getEscapedCollectionKey = () =>
    this.props?.suffix ? `${this.props.collectionKey}-${this.props.suffix}` : this.props.collectionKey;

  initialSearchState = () =>
    ensureValidSearchOptions(
      getQueryDefaultSearchState(validFilterKeys) ||
        getLocalStorageDefaultSearchState(this.getEscapedCollectionKey(), this.context) ||
        {},
      this.props.searchOptions
    );

  initialLayoutType = () => {
    const layoutType =
      Storage.get(`TableLayoutType:${this.getEscapedCollectionKey()}`) ||
      getLayoutTypeSettings(this.props.collectionKey)[0]?.value;
    return this.props.collectionKey === 'adtextGroups' &&
      layoutType === 'branches' &&
      this.initialSearchState().fulltext
      ? 'adtextsOnly'
      : layoutType;
  };

  setDefaultLimit = (limit, perOptions) => {
    if (perOptions?.includes(limit)) {
      return limit;
    }

    if (perOptions?.length) {
      return Math.min(...perOptions);
    }

    return 20;
  };

  state = {
    page: 0,
    limit: this.setDefaultLimit(this.props.limit, this.props.perOptions),
    order: this.props.order || null,
    orderDirection: this.props.orderDirection || 'asc',
    columns: uniq(this.props.columns || []).filter(column => mapping[this.props.collectionKey][column]),
    activeSearchState: this.initialSearchState(),
    layoutType: this.initialLayoutType(),
    additionalTriggeredColumns: [],
    loading: false,
  };

  componentWillMount() {
    const { defaultColumnsKey, selectedColumnsKey } = getLocalStorageKeys(this.props);

    const cachedDefaultColumns = Storage.get(defaultColumnsKey);
    const currentDefaultColumns = defaultColumns(this.props.collectionKey);
    const cachedSelectedColumns = Storage.get(selectedColumnsKey);

    if (cachedDefaultColumns === currentDefaultColumns && cachedSelectedColumns) {
      this.setState({ columns: JSON.parse(cachedSelectedColumns) });
    }
    if (cachedDefaultColumns !== currentDefaultColumns && cachedSelectedColumns) {
      Storage.remove(selectedColumnsKey);
      Storage.remove(defaultColumnsKey);
      new window.NotificationCenter().show_warning(t('react.columns_cleared'));
    }
  }

  updateColumns = columns => {
    const { defaultColumnsKey, selectedColumnsKey } = getLocalStorageKeys(this.props);
    this.setState({ columns });

    Storage.set(defaultColumnsKey, defaultColumns(this.props.collectionKey));
    Storage.set(selectedColumnsKey, JSON.stringify(columns));
  };

  changeActiveSearch = activeSearchState => {
    // Change layout to adtext only if user used fulltext
    const layoutType =
      this.props.collectionKey === 'adtextGroups' && this.state.layoutType === 'branches' && activeSearchState?.fulltext
        ? 'adtextsOnly'
        : this.state.layoutType;

    this.setState({ activeSearchState, layoutType, page: 0 });
    setLocalStorageDefaultSearchState(this.getEscapedCollectionKey(), activeSearchState);
    setPageToUrl(0);
  };

  changeOrder = order => {
    this.setState({ order, orderDirection: 'asc', page: 0 });
    setPageToUrl(0);
  };

  changePage = page => () => {
    this.setState({ page });
  };

  changeLimit = ({ target: { value } }) => {
    this.setState({ limit: parseInt(value, 10), page: 0 });
    setPageToUrl(0);
  };

  changeOrderDirection = orderDirection => {
    this.setState({ orderDirection, page: 0 });
    setPageToUrl(0);
  };

  changeLoading = value => {
    this.setState({ loading: value });
  };

  changeLayout = ({ target: { value: layoutType } }) => {
    const overrideSetting =
      ((getLayoutTypeSettings(this.props.collectionKey) || []).find(s => s?.value === layoutType) || {})?.onStateSet ||
      {};

    if (layoutType !== this.state.layoutType) {
      const as = this.state.activeSearchState || {};
      // whjen switching to branches remove fulltext filter!
      if (this.props.collectionKey === 'adtextGroups' && layoutType === 'branches' && as?.fulltext) {
        const newSearchState = { ...as };
        delete newSearchState.fulltext;
        overrideSetting.activeSearchState = newSearchState;
      }

      this.setState({ ...overrideSetting, layoutType, page: 0 });
      Storage.set(`TableLayoutType:${this.getEscapedCollectionKey()}`, layoutType);
      setPageToUrl(0);
    }
  };

  changeAdditionalTriggeredColumns = additionalColumns => {
    this.setState({ additionalTriggeredColumns: additionalColumns });
  };

  getQuery = (columns, columnsMapping) => {
    const { collectionKey, forceSearchFilters, overrideFilters } = this.props;
    const collectionMapping = mapping[collectionKey];
    const collectionMappingAdditional = mappingAdditional[collectionKey]?.query || '';
    const { activeSearchState } = this.state;
    const searchState = { ...forceSearchFilters, ...activeSearchState };

    if (columnsMapping.length < columns.length) {
      columns.map(col =>
        collectionMapping[col]
          ? true
          : // eslint-disable-next-line no-console
            console.warn('TABLE CANNOT SHOW: ', col, ' from ', Object.keys(collectionMapping))
      );
    }

    const filters = getFilters({ ...searchState, ...overrideFilters });

    return gql`
      query TableQuery($order: String, $orderDirection: String, $page: Int!, $limit: Int!, $organizationId: BigInt!, $campaignSettingId: BigInt, $dataSourceId: BigInt, $feedExportId: BigInt, $additionalSourceId: BigInt, $parentDataSourceId: BigInt, $accountAccessId: BigInt, $additionalDataSourceId: BigInt) {
        collection(identifier: "${collectionKey}", order: $order, orderDirection: $orderDirection, page: $page, limit: $limit, organizationId: $organizationId, campaignSettingId: $campaignSettingId, accountAccessId: $accountAccessId, dataSourceId: $dataSourceId, parentDataSourceId: $parentDataSourceId, feedExportId: $feedExportId, additionalSourceId: $additionalSourceId, additionalDataSourceId: $additionalDataSourceId) {
          id
          ${collectionMappingAdditional}
          ${collectionKey} ${filters} {
            ${getGraphqlQuery(collectionMapping, columns)}
          }
          pagination ${filters} {
            id
            totalPages
            limit
            page
            totalCount
            totalUnfilteredCount
          }
        }
      }
    `;
  };

  render() {
    const {
      accountAccessId,
      additionalHtml,
      campaignSettingId,
      children,
      collectionKey,
      dataSourceId,
      parentDataSourceId,
      additionalDataSourceId,
      feedExportId,
      additionalSourceId,
      enabledFilters,
      doNotShowColumns: givenDoNotShowColumns,
      organizationId,
      perOptions,
      createButton,
      emptyState,
      searchOptions,
      disableFilters,
      disableColumnsChange,
      showLoaderInFilter,
      compactSearch,
      suffix,
      statusCampaignSwitchWarning,
      ...rest
    } = this.props;
    const {
      loading,
      activeSearchState,
      columns: initialColumns,
      limit,
      page,
      order,
      orderDirection,
      layoutType,
      additionalTriggeredColumns,
    } = this.state;

    const columns = uniq([...initialColumns, ...additionalTriggeredColumns]);
    const isRulesDisabled = !initialColumns.some(column => column === VARIABLE_TRANSFORMATION_COLUMN_NAME);

    const layoutTypeSettings = getLayoutTypeSettings(collectionKey);
    const doNotShowColumns = givenDoNotShowColumns.concat(
      (layoutTypeSettings || []).find(s => s?.value === layoutType)?.doNotShowColumns
    );

    let collectionMapping = mapping[collectionKey];

    if (isRulesDisabled) {
      delete collectionMapping[VARIABLE_TRANSFORMATION_COLUMN_NAME];
    }

    if (rest?.disableCategories?.length > 0 && Object.keys(collectionMapping)?.length > 0) {
      collectionMapping = omitBy(collectionMapping, x => rest.disableCategories.includes(x?.category));
    }

    const columnsMapping = sortColumns(
      columns
        .map(col => (doNotShowColumns.indexOf(col) === -1 ? collectionMapping[col] : undefined))
        .filter(c => !!c)
        .map(c => c.id),
      collectionMapping
    ).map(c => collectionMapping[c]);

    const requiredColumns = columns.filter(col => collectionMapping[col] && collectionMapping[col].required);

    const orderContextValue = {
      currentOrder: order,
      currentOrderDirection: orderDirection,
      changeOrder: this.changeOrder,
      changeOrderDirection: this.changeOrderDirection,
    };

    const createItem = (
      <React.Fragment>
        {children}
        <CreateElementButton organizationId={organizationId} {...createButton} />
      </React.Fragment>
    );

    return (
      <div className="pos-relative">
        <OrderContext.Provider value={orderContextValue}>
          <SearchContextProvider
            activeSearchState={activeSearchState}
            onSearchChange={this.changeActiveSearch}
            searchOptions={searchOptions}
            collectionKey={this.getEscapedCollectionKey()}
            changeAdditionalTriggeredColumns={this.changeAdditionalTriggeredColumns}
          >
            <QueryStickyTable
              {...rest}
              columns={columns}
              changeLimit={this.changeLimit}
              changePage={this.changePage}
              collectionKey={collectionKey}
              showChildrenWhileLoading
              mapping={columnsMapping}
              perOptions={perOptions}
              query={this.getQuery(requiredColumns, columnsMapping)}
              fullQuery={this.getQuery(columns, columnsMapping)}
              cachePreviousData={false}
              variables={{
                organizationId,
                campaignSettingId,
                dataSourceId,
                parentDataSourceId,
                additionalDataSourceId,
                feedExportId,
                additionalSourceId,
                page,
                limit,
                order,
                orderDirection,
                accountAccessId,
              }}
              createItem={createItem}
              emptyState={emptyState}
              layoutType={layoutType}
              activeSearchState={activeSearchState}
              getGraphqlQuery={`${getGraphqlQuery(collectionMapping, columns)}`}
              onLoadingStateChange={this.changeLoading}
              statusCampaignSwitchWarning={statusCampaignSwitchWarning}
            >
              {(createButton || children || !disableColumnsChange || !disableFilters) && (
                <Layout.Row top justifyBetween className="flex-wrap flex-gap-16">
                  <Layout.Col shrink className="mw-100">
                    <Layout.Row className="flex-wrap" style={{ gap: '16px 0' }}>
                      {(createButton || children) && (
                        <Layout.Col shrink className="mr-24">
                          {createItem}
                        </Layout.Col>
                      )}
                      <SearchWrapper
                        enabledFilters={disableFilters ? [] : enabledFilters}
                        changeAdditionalTriggeredColumns={this.changeAdditionalTriggeredColumns}
                        compactSearch={compactSearch}
                        loading={showLoaderInFilter ? !!loading : null}
                      />
                    </Layout.Row>
                  </Layout.Col>
                  {(additionalHtml || !disableColumnsChange) && (
                    <Layout.Row shrink>
                      {additionalHtml && (
                        <Layout.Col shrink className="mr-12" dangerouslySetInnerHTML={{ __html: additionalHtml }} />
                      )}
                      {layoutType && (
                        <SegmentedButton
                          doNotUseInternalState
                          name={layoutType}
                          id="layoutType"
                          onlyIcons
                          value={layoutType}
                          onChange={this.changeLayout}
                          collection={layoutTypeSettings}
                          size="medium"
                        />
                      )}
                      {!disableColumnsChange && (
                        <Layout.Col shrink className={cs({ 'ml-12': layoutType })}>
                          <ColumnPresetDropdown
                            collectionMapping={collectionMapping}
                            columns={columns}
                            suffix={suffix}
                            updateColumns={this.updateColumns}
                            collectionKey={collectionKey}
                            doNotShowColumns={doNotShowColumns}
                          />
                        </Layout.Col>
                      )}
                    </Layout.Row>
                  )}
                </Layout.Row>
              )}
            </QueryStickyTable>
          </SearchContextProvider>
        </OrderContext.Provider>
      </div>
    );
  }
}
export default GraphqlTable;
