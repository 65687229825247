import { v4 } from 'uuid';

const mapDataToInputs = (data = []) => {
  if (data.length === 0) {
    return [{ uuid: v4(), value: '' }];
  }
  return data.map(({ id, text, errors }) => ({
    id,
    uuid: v4(),
    value: text,
    error: errors?.join(', '),
  }));
};

export default mapDataToInputs;
