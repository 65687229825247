import React, { useEffect, useState } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { t } from '../i18n';
import { Col, Icon, Loader, Row } from './index';

const Button = ({
  active,
  className,
  disabled,
  error,
  href,
  children,
  icon,
  rightIcon,
  kind,
  isTableButton,
  loading = false,
  onClick,
  onlyIcon,
  primary,
  secondary,
  setRef,
  size = 'medium',
  style = {},
  tag,
  tertiary,
  text,
  type = 'button',
  status = 'default',
  upload,
  testId,
  ...rest
}) => {
  const BEFORE = 'before';
  const LOADING = 'loading';
  const DONE = 'check';
  const ERROR = 'error';

  const [loadingState, setLoadingState] = useState(BEFORE);
  const [buttonStatus, setButtonStatus] = useState(status);

  useEffect(() => {
    if (loading) setLoadingState(LOADING);
    if (loadingState === LOADING && !loading) {
      setLoadingState(error ? ERROR : DONE);
      setButtonStatus(error ? 'attention' : 'positive');
      setTimeout(() => {
        setLoadingState(BEFORE);
        setButtonStatus(status);
      }, 2500);
    }
  }, [loading]);

  const handleClick = e => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick(e);
      return false;
    }
    return true;
  };

  const Component = tag || 'button';

  return (
    <Component
      data-test-id={testId}
      onClick={handleClick}
      ref={setRef}
      disabled={disabled}
      className={cs('button', className, {
        'button--primary': primary || kind === 'primary',
        'button--secondary': secondary || kind === 'secondary',
        'button--tertiary': tertiary || kind === 'tertiary',
        'button--loading': loading,
        'button--disabled': disabled,
        'button--table': isTableButton,
        'button--only-icon': onlyIcon || className?.includes('Button--hideText'),
        [`button--${size}`]: size,
        [`button--${buttonStatus}`]: buttonStatus,
        active,
      })}
      href={href}
      style={{ ...style }}
      {...(Component === 'button' ? { type } : {})}
      {...rest}
    >
      {loadingState === ERROR || loadingState === DONE ? (
        <>
          <Col shrink>{loadingState === ERROR ? 'Failed' : 'Success'}</Col>
          <Icon className={'ml-8'} kind={loadingState === ERROR ? 'close' : 'check'} inheritColor />
        </>
      ) : (
        <>
          {!upload && (
            <>
              {icon && <Icon className={cs({ 'mr-8': !onlyIcon })} kind={icon} inheritColor />}
              {!onlyIcon && (
                <Col shrink>
                  {children}
                  {text}
                </Col>
              )}
              {rightIcon && !onlyIcon && <Icon className={'ml-8'} kind={rightIcon} inheritColor />}
            </>
          )}

          {(loading || upload) && (
            <Row center>
              {upload && (
                <>
                  {loading ? <span>Uploading...</span> : <span>{children}</span>}
                  {!loading && <Icon className={'ml-8'} kind={'upload'} inheritColor />}
                </>
              )}
              {primary && loading && (
                <Col shrink className="ml-8">
                  <div className="simple-loader" />
                </Col>
              )}
              {!primary && loading && (
                <Col shrink className="ml-8">
                  <Loader size="small" />
                </Col>
              )}
            </Row>
          )}
        </>
      )}
    </Component>
  );
};

Button.propTypes = {
  active: RPT.bool,
  className: RPT.string,
  disabled: RPT.bool,
  error: RPT.object || RPT.string,
  href: RPT.oneOfType([RPT.bool, RPT.string]),
  children: RPT.any,
  icon: RPT.string,
  rightIcon: RPT.string,
  loading: RPT.bool,
  onClick: RPT.func,
  onlyIcon: RPT.bool,
  setRef: RPT.func,
  size: RPT.oneOf(['small', 'medium', 'large']),
  style: RPT.object,
  tag: RPT.string,
  text: RPT.any,
  type: RPT.oneOf(['button', 'submit', 'reset']),
  status: RPT.oneOf(['default', 'positive', 'warning', 'attention', 'informative']),
  primary: RPT.bool,
  secondary: RPT.bool,
  tertiary: RPT.bool,
  upload: RPT.bool,
  isTableButton: RPT.bool,
};

export default Button;

export const MazeButton = ({ href, text }) => (
  <Button
    tag="a"
    href={href}
    icon="reply"
    secondary
    size="small"
    target="_blank"
    text={text || t('react.maze_button.leave_feedback')}
  />
);

MazeButton.propTypes = {
  href: RPT.string.required,
  text: RPT.string,
};
