import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button, Row, Col, Text, Icon, cssVariables, Badge } from '../index';
import { t } from '../../i18n';
import hoppyIcon from '../../../../assets/images/hoppy_small.png';
import { formatDuration, truncateFilename } from './utils';
import { MEDIA_TYPES } from './meta';

const getSourceIcon = source => {
  switch (source) {
    case 'Facebook':
      return <Icon kind="facebook-square" size="12px" color={cssVariables.interactiveNeutralDefault} />;
    case 'Dotidot':
      return <img src={hoppyIcon} alt="Dotidot" style={{ width: '10px', height: '10px' }} />;
    default:
      return null;
  }
};

const MediaTile = ({
  disabled = false,
  label,
  onDelete,
  onPlayIconClick,
  onSelect,
  selected,
  resolution,
  url,
  source,
  type,
  duration,
}) => (
  <Row
    direction="column"
    data-test-id="media-card"
    className={cs('CardMedia', { 'CardMedia--disabled': disabled, 'CardMedia--selected': selected })}
    onClick={onSelect}
  >
    {selected && (
      <div className="CardMedia-selectedIcon">
        <Icon kind="check" size="14px" color={cssVariables.white} />
      </div>
    )}
    {onDelete && source === 'Dotidot' && (
      <div className="CardMedia-deleteButton">
        <Button
          secondary
          status={'attention'}
          icon="trash"
          onlyIcon
          onClick={onDelete}
          // primary
          size="small"
          data-test-id="delete-image-button"
        />
      </div>
    )}
    <Row center className="CardMedia-imagePreviewContainer">
      {url && (
        <Col className="CardMedia-imagePreview" style={{ margin: 0 }}>
          <img src={url} alt={label} />
        </Col>
      )}
      {type === MEDIA_TYPES.VIDEO && (
        <>
          {!!duration && (
            <div className="CardMedia-videoDuration">
              <Icon kind="play" size="14px" color={cssVariables.white} />
              <Text medium size="sm" style={{ color: cssVariables.white, marginLeft: '4px' }}>
                {formatDuration(duration)}
              </Text>
            </div>
          )}
          <div className="CardMedia-videoPlayIcon" onClick={onPlayIconClick}>
            <Icon kind="play" size="24px" color={cssVariables.white} />
          </div>
        </>
      )}
    </Row>
    <div className="CardMedia-metabox">
      <Text bold className="CardMedia-label">
        {truncateFilename(label, 65)}
      </Text>
      {type === MEDIA_TYPES.IMAGE && <Text className="CardMedia-text">{resolution}</Text>}
      <Row center className="CardMedia-source mt-4">
        <Text className="CardMedia-text">{t('react.media.source', { default: 'Source:' })}</Text>
        <Badge kind="lightGray" isWithBorder style={{ marginLeft: '4px' }}>
          {getSourceIcon(source)}
          {source}
        </Badge>
      </Row>
    </div>
  </Row>
);

MediaTile.propTypes = {
  disabled: PropTypes.bool,
  imgUrl: PropTypes.string,
  label: PropTypes.string,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  resolution: PropTypes.string,
};

export default MediaTile;
