import { cssVariables } from '../index';

export const calculatePercentage = (count, all) => {
  if (all === 0) {
    return '0%'; // Avoid division by zero
  }
  const percentage = (count / all) * 100;
  return `${Math.round(percentage)}%`;
};

export const formatNumber = (value, currency = null) => {
  const parsedValue = parseInt(value, 10);
  if (isNaN(parsedValue)) {
    throw new Error('Value must be a number or a numeric string');
  }

  // Define currency options
  const currencyFormats = {
    USD: { locale: 'en-US', options: { style: 'currency', currency: 'USD', minimumFractionDigits: 0 } },
    EUR: { locale: 'en-US', options: { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 } },
    CZK: { locale: 'cs-CZ', options: { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 } },
  };

  // Handle currency formatting
  if (currency && currencyFormats[currency]) {
    return new Intl.NumberFormat(currencyFormats[currency].locale, currencyFormats[currency].options).format(
      parsedValue
    );
  }

  // Handle simple number formatting (e.g., 100 000)
  return parsedValue.toLocaleString('cs-CZ', { useGrouping: true, minimumFractionDigits: 0 });
};

const mapDataToCollection = ({ total, limit }) => {
  const adjustedLimit = limit === 0 ? 1 : limit;
  const result = {
    collection: [
      {
        count: total >= adjustedLimit ? 0 : adjustedLimit - total,
        color: cssVariables.interfaceBackgroundMuted,
        kind: 'summ',
      },
      {
        count: total,
        color: total >= limit ? cssVariables.statusAttentionDefault : cssVariables.interfaceCommonAccent,
        kind: 'summ',
      },
    ],
    count: total,
    allItemsCount: limit,
  };
  return result;
};

export const mapDataForUsageHistory = data => {
  if (!data) {
    return null;
  }

  const subOrgAdSpend = data?.organizationsBreakdown?.reduce((acc, org) => acc + org.adSpend.total, 0);
  const subOrgProductsInFeedExports = data?.organizationsBreakdown?.reduce(
    (acc, org) => acc + org.productsInFeedExports.total,
    0
  );
  const subOrgDomainsInScraper = data?.organizationsBreakdown?.reduce(
    (acc, org) => acc + org.domainsInScraper.total,
    0
  );
  const subOrgImageEditorForFeedExports = data?.organizationsBreakdown?.reduce(
    (acc, org) => acc + org.imageEditorForFeedExports.total,
    0
  );

  const mappedData = {
    currency: data.currency,
    adSpend: mapDataToCollection(data.adSpend),
    productsInFeedExports: mapDataToCollection(data.productsInFeedExports),
    domainsInScraper: mapDataToCollection(data.domainsInScraper),
    imageEditorForFeedExports: mapDataToCollection(data.imageEditorForFeedExports),
    organizationsBreakdown: data.organizationsBreakdown.map(o => ({
      id: o.organization.id,
      name: o.organization.name,
      adSpend: mapDataToCollection({ total: o.adSpend?.total, limit: subOrgAdSpend }),
      productsInFeedExports: mapDataToCollection({
        total: o.productsInFeedExports?.total,
        limit: subOrgProductsInFeedExports,
      }),
      domainsInScraper: mapDataToCollection({ total: o.domainsInScraper?.total, limit: subOrgDomainsInScraper }),
      imageEditorForFeedExports: mapDataToCollection({
        total: o.imageEditorForFeedExports?.total,
        limit: subOrgImageEditorForFeedExports,
      }),
    })),
  };

  return mappedData;
};
