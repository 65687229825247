import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button, cssVariables, Heading, Text } from '../index';

const SimpleEmptyState = ({ className, heading, icon, description, buttons, withBorder }) => {
  const { primary, secondary, tertiary } = buttons;
  const getImage = icon_name => {
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`../../../../assets/images/illustrations/${icon_name}.svg`);
    } catch (err) {
      return null;
    }
  };

  const hasButtons = primary || secondary || tertiary;

  return (
    <div
      className={cs('SimpleEmptyState--container', className, {
        'SimpleEmptyState--container__withBorder': withBorder,
      })}
    >
      {icon && <img className="SimpleEmptyState--icon" src={getImage(icon)} alt="Empty state icon" />}
      {heading && (
        <Heading style={{ marginTop: '24px' }} spacing={0}>
          {heading}
        </Heading>
      )}
      {description && (
        <Text className="mt-16" style={{ color: cssVariables.textMuted }}>
          {description}
        </Text>
      )}
      {hasButtons && (
        <div className="SimpleEmptyState--buttons mt-16">
          {primary && (
            <Button
              text={primary.text}
              icon={primary.icon}
              rightIcon={primary.rightIcon}
              onClick={primary.onClick}
              href={primary.href}
              tag={primary.href ? 'a' : 'button'}
              style={{ margin: 0 }}
              primary
            />
          )}
          {secondary && (
            <Button
              text={secondary.text}
              icon={secondary.icon}
              rightIcon={secondary.rightIcon}
              onClick={secondary.onClick}
              href={secondary.href}
              tag={secondary.href ? 'a' : 'button'}
              style={{ margin: 0 }}
              secondary
            />
          )}
          {tertiary && (
            <Button
              text={tertiary.text}
              icon={tertiary.icon}
              rightIcon={tertiary.rightIcon}
              onClick={tertiary.onClick}
              href={tertiary.href}
              tag={tertiary.href ? 'a' : 'button'}
              style={{ margin: 0 }}
              tertiary
            />
          )}
        </div>
      )}
    </div>
  );
};

SimpleEmptyState.propTypes = {
  heading: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  withBorder: PropTypes.bool,
  buttons: PropTypes.shape({
    primary: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      rightIcon: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
    }),
    secondary: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      rightIcon: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
    }),
    tertiary: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      rightIcon: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
    }),
  }),
};

export default SimpleEmptyState;
