import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateInput from '../form/DateInput';
import { t, formatDate } from '../../i18n';

const FbAplusCampaignScheduleForm = ({ startDate, endDate, inputsDisabled }) => {
  const [campaignStartDate, setCampaignStartDate] = useState(startDate);
  const [campaignEndDate, setCampaignEndDate] = useState(endDate);
  return (
    <>
      <div className="mv-16">
        <DateInput
          label={t('react.fb_aplus_campaign_schedule_form.start_date', { default: 'Start Date' })}
          testId="schedule-date-from"
          value={campaignStartDate}
          onChange={({ target: { value } }) => setCampaignStartDate(value)}
          maxDate={campaignEndDate && moment(campaignEndDate).toDate()}
          doNotUseInternalState
          labelOnTop
          disabled={inputsDisabled}
        />
      </div>
      <input
        type="hidden"
        name="campaign_setting[fb_setting_attributes][schedule_start_time]"
        value={campaignStartDate ? formatDate(campaignStartDate, 'YYYY-MM-DD') : ''}
      />
      <div>
        <DateInput
          label={t('react.fb_aplus_campaign_schedule_form.end_date', { default: 'End date' })}
          testId="schedule-date-to"
          value={campaignEndDate}
          onChange={({ target: { value } }) => setCampaignEndDate(value)}
          minDate={campaignStartDate && moment(campaignStartDate).toDate()}
          doNotUseInternalState
          labelOnTop
          optional
        />
      </div>
      <input
        type="hidden"
        name="campaign_setting[fb_setting_attributes][schedule_end_time]"
        value={campaignEndDate ? formatDate(campaignEndDate, 'YYYY-MM-DD') : ''}
      />
    </>
  );
};

FbAplusCampaignScheduleForm.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default FbAplusCampaignScheduleForm;
