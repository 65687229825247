import React from 'react';
import { Row, Col, Heading, Text, cssVariables, Badge, Button, Icon, SimpleTooltip } from '../index';
import Chart from '../feed_exports/Chart';
import { formatNumber } from './utils';
import { t } from '../../i18n';

const LimitBig = ({ data, currency = null }) => {
  if (data?.allItemsCount === null) {
    return (
      <Row style={{ minHeight: '42px', margin: 0 }} padding="s" center flexwrap justifyCenter>
        <Row justifyCenter>
          <Col shrink>
            <Heading tag="h3" style={{ textAlign: 'center' }} spacing={0}>
              {formatNumber(data?.count, currency)}
            </Heading>
          </Col>
        </Row>
        <Row center justifyCenter padding="s" style={{ margin: 0 }}>
          <Col shrink>
            <Text size="sm" style={{ color: cssVariables.textSubtle }}>
              of
            </Text>
          </Col>
          <Col shrink>
            <SimpleTooltip text={t('react.usage_history.unlimited_limit_tooltip', { default: 'Unlimited usage' })}>
              <Icon className="usage-history-unlimited-icon" kind="general_infinity" size="14px" />
            </SimpleTooltip>
          </Col>
        </Row>
      </Row>
    );
  }
  if (data?.allItemsCount === 0) {
    return (
      <Row style={{ minHeight: '42px' }} padding="s" center justifyCenter>
        <Col shrink>
          <Badge kind="gray">
            {t('react.usage_history.available_in_higher_plans', { default: 'Available in higher plans' })}
          </Badge>
        </Col>
        <Col shrink>
          <Button
            href="https://meetings-eu1.hubspot.com/daniel-cervinka?uuid=a5d608d5-ca44-455d-9953-f004bd85275b"
            tag="a"
            tertiary
          >
            {t('react.usage_history.contact_us', { default: 'Contact us' })}
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Row center justifyCenter>
      <Col shrink style={{ marginRight: '12px' }}>
        <Chart
          collection={data.collection}
          allItemsCount={data.allItemsCount}
          count={data.count}
          labelColor={cssVariables.textMuted}
          percentual
          hasRoundBorder
        />
      </Col>
      <Col shrink>
        <Heading spacing={0}>{formatNumber(data?.count, currency)}</Heading>
        <Text size="sm" style={{ color: cssVariables.textMuted }}>
          {`of ${formatNumber(data?.allItemsCount, currency)}`}
        </Text>
      </Col>
    </Row>
  );
};

export default LimitBig;
