import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Label, Tooltip as Retooltip } from 'recharts';
import { Text } from '../index';
import { formatPercentage } from '../../i18n';

const CustomizedTooltip = ({ active, payload, data }) => {
  if (!active) return null;
  const item = data.find(x => x.kind === payload[0].payload.kind);
  return (
    <div
      className="CustomizedTooltip tooltip-inner pa-8"
      style={{
        backgroundColor: 'rgba(0, 22, 42, 0.85)',
      }}
    >
      <Text className="mr-4 nowrap" color="white">
        {item?.tooltip}:
      </Text>
      <Text size="sm" color="white">
        {item.count}x
      </Text>
    </div>
  );
};

const getPercents = ({ count, allItemsCount }) => {
  const percents = (100 * count) / allItemsCount;
  return formatPercentage(isNaN(percents) ? 0 : percents);
};

const Chart = ({
  percentual,
  collection,
  size = 42,
  labelColor,
  hideLabel,
  hasRoundBorder,
  innerRadius = 16,
  outerRadius = 21,
  ...rest
}) => {
  let labelStyles = {};
  if (getPercents(rest).length > 3) {
    labelStyles = { fontSize: '10px' };
  }
  if (getPercents(rest).length > 5) {
    labelStyles = { fontSize: '8px' };
  }
  if (getPercents(rest).length > 6) {
    labelStyles = { fontSize: '6px' };
  }
  return (
    <PieChart width={size} height={size}>
      <Pie
        isAnimationActive={false}
        data={collection}
        dataKey="count"
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={90}
        cornerRadius={hasRoundBorder ? 40 : 0}
        paddingAngle={hasRoundBorder ? -15 : 0}
        endAngle={-270}
        stroke="none"
        fill="#82ca9d"
      >
        {percentual && !hideLabel && (
          <Label
            value={getPercents(rest)}
            position="center"
            className="Text Text--semibold Text--gray"
            fill={labelColor}
            style={{ ...labelStyles }}
          />
        )}
        {collection.map(entry => (
          <Cell key={`cell-${entry.kind}`} fill={entry.color} strokeWidth="10" stroke="rgba(0,0,0,0.01)" />
        ))}
      </Pie>
      {!percentual && (
        <Retooltip
          wrapperStyle={{ zIndex: 1000 }}
          content={props => <CustomizedTooltip data={collection} {...props} />}
        />
      )}
    </PieChart>
  );
};

Chart.propTypes = {
  collection: PropTypes.array,
  percentual: PropTypes.bool,
  hideLabel: PropTypes.bool,
  size: PropTypes.number,
  labelColor: PropTypes.string,
  hasRoundBorder: PropTypes.bool,
};
CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  data: PropTypes.array,
};

export default Chart;
