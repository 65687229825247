import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Tile, SystemIcon, Row, Col, Icon, SmallHeading, Badge, Tooltip } from './index';
import { t } from '../i18n';
import Ellipsis from './Ellipsis';

const ImageResolver = ({ className, src, icon, iconColor }) => {
  if (icon) {
    return (
      <div className={cs('Tile-icon', className)}>
        <Icon kind={icon} inheritColor={!iconColor} color={iconColor} />
      </div>
    );
  }
  if (src.startsWith('<svg')) {
    // eslint-disable-next-line react/no-danger
    return <div className={cs('Tile-image', className)} dangerouslySetInnerHTML={{ __html: src }} />;
  }
  return (
    <div className={cs('Tile-image', className)}>
      <img src={src} alt="Tile icon" />
    </div>
  );
};

ImageResolver.propTypes = {
  src: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
};

const CountryBadge = ({ country, tooltip, tooltipPosition }) => (
  <Col width="26px" style={{ margin: '2px' }}>
    <Tooltip text={tooltip} placement={tooltipPosition}>
      <Badge kind="lightGray" size="medium" style={{ width: '26px' }}>
        {country === 'ALL' ? t('react.create_element_modal.all_countries_badge') : country}
      </Badge>
    </Tooltip>
  </Col>
);

CountryBadge.propTypes = {
  country: PropTypes.any,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

const MAX_TOOLTIP_ITEMS = 24;

const getHiddenCountriesTooltip = (hideCountries, countries) => {
  if (hideCountries?.length > MAX_TOOLTIP_ITEMS / 2) {
    return hideCountries
      .map(
        (country, index) =>
          `${country}: ${countries[country]}${
            (index + 1) % 2 !== 0 && index + 1 !== hideCountries.length ? ', ' : '<br />'
          } `
      )
      .join('');
  }

  return hideCountries?.map(country => `${country}: ${countries[country]} <br />`).join('');
};

getHiddenCountriesTooltip.propTypes = {
  countries: PropTypes.object,
  hideCountries: PropTypes.array,
};

const CountriesBadges = ({ countries }) => {
  const allCountries = Object.keys(countries);

  if (allCountries.length > 4) {
    const showCountries = allCountries.slice(0, 3);
    const hideCountries = allCountries.slice(3);

    return (
      <React.Fragment>
        {showCountries.map(country => (
          <CountryBadge key={`${country}-badge`} country={country} tooltip={countries[country]} />
        ))}
        <CountryBadge
          country={<Icon kind="more" inheritColor />}
          tooltipPosition={hideCountries?.length > MAX_TOOLTIP_ITEMS ? 'bottom' : 'top'}
          tooltip={getHiddenCountriesTooltip(hideCountries, countries)}
        />
      </React.Fragment>
    );
  }

  return allCountries.map(country => (
    <CountryBadge key={`${country}-badge`} country={country} tooltip={countries[country]} />
  ));
};

CountriesBadges.propTypes = {
  countries: PropTypes.object,
};

const IconTileHeading = ({ heading, spacing }) => (
  <Row>
    <Col shrink className="mw-100">
      <SmallHeading spacing={spacing}>
        <Ellipsis>{heading}</Ellipsis>
      </SmallHeading>
    </Col>
  </Row>
);

IconTileHeading.propTypes = {
  spacing: PropTypes.number,
  heading: PropTypes.string,
};

const IconTileDescription = ({ description }) => (
  <Row>
    <div className="Tile-description">{description}</div>
  </Row>
);

IconTileDescription.propTypes = {
  description: PropTypes.string,
};

const IconTileSystems = ({ system }) => (
  <Row className="Tile-icon Tile-icon--system mt-8">
    {system.map(s => (
      <Col shrink key={`${s}-system`}>
        <SystemIcon size="15px" system={s} />
      </Col>
    ))}
  </Row>
);

IconTileSystems.propTypes = {
  system: PropTypes.array,
};

const IconTile = ({
  active,
  additionalContent,
  addResource,
  alphaBadge,
  badge,
  betaBadge,
  className,
  countries,
  ctaLink,
  data_test_id,
  description,
  disabled,
  heading,
  hoverable = true,
  icon,
  iconColor,
  isVertical,
  moneyBadge,
  newBadge,
  onClick,
  recommended,
  src,
  system,
  tipBadge,
  tooltip,
  withCheck,
  subTile,
  subTileAltBackground,
  subTiles,
  ...rest
}) => (
  <Tile
    active={active}
    addResource={addResource}
    alphaBadge={alphaBadge}
    badge={badge}
    betaBadge={betaBadge}
    className={isVertical && 'Tile--radio'}
    ctaLink={ctaLink}
    disabled={disabled}
    hasDarkBorder
    horizontal={!isVertical}
    hoverable={hoverable}
    moneyBadge={moneyBadge}
    newBadge={newBadge}
    onClick={onClick}
    tipBadge={tipBadge}
    tooltip={tooltip}
    withCheck={withCheck}
    subTile={subTile}
    subTileAltBackground={subTileAltBackground}
    subTiles={subTiles}
    {...rest}
  >
    {!isVertical && (
      <Row center padding="l">
        {((icon && icon.length > 0) || (src && src.length > 0)) && (
          <Col shrink>
            <ImageResolver className="Tile-icon" src={src} icon={icon} iconColor={iconColor} />
          </Col>
        )}

        <Col grow noPadding>
          <IconTileHeading heading={heading} spacing={description ? 4 : 0} />

          {description?.length > 0 && <IconTileDescription description={description} />}

          {system?.length > 0 && <IconTileSystems system={system} />}
        </Col>

        {countries && (
          <Col width="60px" wrap style={{ margin: '0 -2px' }}>
            <Row flexwrap justifyCenter noPadding>
              <CountriesBadges countries={countries} />
            </Row>
          </Col>
        )}

        {additionalContent && <Col shrink>{additionalContent}</Col>}
      </Row>
    )}

    {isVertical && (
      <Col center noPadding>
        {((icon && icon.length > 0) || (src && src.length > 0)) && (
          <Row>
            <ImageResolver className="Tile-image--radioTile" src={src} icon={icon} iconColor={iconColor} />
          </Row>
        )}

        <Row>
          <IconTileHeading heading={heading} spacing={description ? 4 : 0} />
        </Row>

        {description?.length > 0 && <IconTileDescription description={description} />}
      </Col>
    )}
  </Tile>
);

IconTile.propTypes = {
  active: PropTypes.bool,
  additionalContent: PropTypes.any,
  addResource: PropTypes.object,
  alphaBadge: PropTypes.string,
  badge: PropTypes.string,
  betaBadge: PropTypes.string,
  className: PropTypes.string,
  countries: PropTypes.object,
  ctaLink: PropTypes.string,
  data_test_id: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  hoverable: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  isVertical: PropTypes.bool,
  moneyBadge: PropTypes.bool,
  newBadge: PropTypes.string,
  onClick: PropTypes.func,
  recommended: PropTypes.bool,
  src: PropTypes.string,
  system: PropTypes.array,
  tipBadge: PropTypes.bool,
  tooltip: PropTypes.string,
  withCheck: PropTypes.bool,
  subTiles: PropTypes.object,
};

export default IconTile;
