import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { differenceInDays, format, isEqual, startOfDay } from 'date-fns';
import DatePickerMenu from './DatePickerMenu';
import { Row, Col, Text, Badge, Button, Icon, cssVariables } from '../index';
import { staticDateRanges } from './meta';
import { t } from '../../i18n';

const RangeDatePicker = ({ label, minDate, maxDate, defaultPeriod, period, onChange, onReset }) => {
  const [dynamicButtonText, setDynamicButtonText] = useState(
    defaultPeriod ? t('date_range_picker.this_period', { defaultValue: 'This period' }) : ''
  );

  const [range, setRange] = useState(period);

  useEffect(() => {
    updateButtonText(range[0]);
  }, [range]);

  const staticRanges = staticDateRanges(defaultPeriod);

  const formatDateRange = (startDate, endDate) => {
    const formattedStartDate = format(new Date(startDate), 'dd.MM.yyyy');
    const formattedEndDate = format(new Date(endDate), 'dd.MM.yyyy');
    return (
      <Row center>
        <Text>{formattedStartDate}</Text>
        <Icon kind="arrow-right" size="16px" color={cssVariables.iconSubtle} style={{ margin: '0 8px' }} />
        <Text style={{ color: cssVariables.interactiveFormMuted }}>{formattedEndDate}</Text>
      </Row>
    );
  };

  const updateButtonText = selectedRange => {
    const matchingStaticRange = staticRanges.find(_range => {
      const { startDate, endDate } = _range.range();
      return (
        isEqual(startOfDay(new Date(startDate)), startOfDay(new Date(selectedRange.startDate))) &&
        isEqual(startOfDay(new Date(endDate)), startOfDay(new Date(selectedRange.endDate)))
      );
    });

    if (!selectedRange) {
      setDynamicButtonText('');
    }

    if (matchingStaticRange) {
      setDynamicButtonText(matchingStaticRange.label);
    } else {
      setDynamicButtonText(formatDateRange(selectedRange.startDate, selectedRange.endDate));
    }
  };

  const handleChange = item => {
    const selection = item.selection;
    updateButtonText(selection); // Update the button text dynamically
    setRange([selection]);
  };

  const resetHandler = () => {
    onReset();
    setRange([defaultPeriod]);
  };

  const daysCount =
    range[0].endDate && range[0].startDate
      ? differenceInDays(new Date(range[0].endDate), new Date(range[0].startDate)) + 1
      : 0;

  return (
    <DatePickerMenu buttonText={dynamicButtonText} label={label} hideOnClick={false} onReset={resetHandler}>
      <div className="DateRange">
        <DateRangePicker
          direction="horizontal"
          inputRanges={[]}
          minDate={minDate}
          maxDate={maxDate}
          months={2}
          moveRangeOnFirstSelection={false}
          ranges={range}
          showDateDisplay={false}
          staticRanges={staticRanges}
          weekdayDisplayFormat="EEEEEE"
          weekStartsOn={1}
          showPreview
          onChange={handleChange}
        />
        <div className="DateRange-footer">
          <Row center justifyBetween>
            <Col shrink>
              <Row center>
                <Text>Range:</Text>
                <Badge className="ml-6" kind="lightGray" size="large">
                  {daysCount}{' '}
                  {daysCount === 1
                    ? t('date_range_picker.day', { default: 'Day' })
                    : t('date_range_picker.days', { default: 'Days' })}
                </Badge>
              </Row>
            </Col>
            <Col shrink>
              <Row center>
                <Button
                  secondary
                  onClick={() => {
                    setRange(period);
                  }}
                >
                  <span data-action="close">{t('date_range_picker.cancel', { default: 'Cancel' })}</span>
                </Button>
                <Button
                  primary
                  onClick={() => {
                    onChange(range);
                  }}
                >
                  <span data-action="close">{t('date_range_picker.apply', { default: 'Apply' })}</span>
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </DatePickerMenu>
  );
};

export default RangeDatePicker;
