import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Col, Row, IconWithBadge, Tag, Text, PageHeading } from './index';
import Link from './Link';

const Wrapper = ({ heading, children }) => (
  <Col noPadding>
    <Row center>
      <Text size="sm" color="gray">
        {heading}
      </Text>
    </Row>

    {children}
  </Col>
);

export const IconsWrapper = ({ color: boxColor, limit = 3, collection, onClick, ...rest }) => {
  const croppedArray = collection.slice(0, limit);
  const hiddenElementsCount = collection.length - croppedArray.length;
  const elementsAreHidden = hiddenElementsCount > 0;

  const handleClick = () => onClick(collection);

  return (
    <Wrapper {...rest}>
      <Row center height="32px">
        {croppedArray.map(({ color, ...other }) => (
          <IconWithBadge
            {...other}
            key={[other.kind, other.count].join('-')}
            className="mr-8"
            color={color || boxColor}
          />
        ))}
        {elementsAreHidden && <Tag onClick={handleClick}>+{hiddenElementsCount}</Tag>}
      </Row>
    </Wrapper>
  );
};

export const TextWrapper = ({
  description,
  showFullDescription,
  collection,
  onClick,
  descriptionSmall,
  hasDynamicHeight,
  badge,
  link,
  descriptionIsNumber,
  ...rest
}) => {
  const handleClick = () => onClick(collection);
  const parseLargeNumber = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  let descriptionContent = description;
  if (showFullDescription || !description) {
    descriptionContent = descriptionSmall && (
      <Row center>
        <PageHeading spacing={0}>{descriptionIsNumber ? parseLargeNumber(description) : description}</PageHeading>
        {descriptionSmall && (
          <Text size="sm" color="lightGray" className="pl-4 pt-4">
            {descriptionIsNumber ? parseLargeNumber(descriptionSmall) : descriptionSmall}
          </Text>
        )}
      </Row>
    );
  }

  return (
    <Wrapper {...rest}>
      <Row center height={hasDynamicHeight ? 'auto' : '32px'} style={{ ...(badge ? { paddingTop: '6px' } : {}) }}>
        {!!badge && (
          <Badge size="medium" kind="gray">
            {badge}
          </Badge>
        )}
        {descriptionContent}
        {link?.text && link?.href && (
          <Text size="sm" color="gray" className="ml-8">
            <Link href={link.href} target={link.target}>
              {link.text}
            </Link>
          </Text>
        )}
        {typeof onClick === 'function' && (
          <Button
            className="ml-4"
            disabled={collection?.length === 0}
            secondary
            size="small"
            onlyIcon
            icon="magnifier"
            onClick={handleClick}
          />
        )}
      </Row>
    </Wrapper>
  );
};

IconsWrapper.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      count: PropTypes.number.isRequired,
      kind: PropTypes.string.isRequired,
    })
  ),
  color: PropTypes.string,
  limit: PropTypes.number,
  onClick: PropTypes.func,
};

TextWrapper.propTypes = {
  collection: PropTypes.array,
  description: PropTypes.string,
  hasDynamicHeight: PropTypes.bool,
  onClick: PropTypes.func,
  showTotal: PropTypes.bool,
  descriptionSmall: PropTypes.string,
  badge: PropTypes.string,
  link: PropTypes.object,
  descriptionIsNumber: PropTypes.bool,
};
Wrapper.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.any,
};
