import { DirectUpload } from '@rails/activestorage';
import { v4 } from 'uuid';
import SparkMD5 from 'spark-md5';
import { aspectRatios, defaultAspectRatioOptions, defaultCropSettings, MEDIA_TYPES } from './meta';

export const uploadFile = (file, successCallback, errorCallback) => {
  const url = '/rails/active_storage/direct_uploads';
  const upload = new DirectUpload(file, url);

  upload.create((error, blob) => {
    if (error) {
      errorCallback(error);
    } else {
      successCallback(blob.signed_id);
    }
  });
};

export function getDefaultCrop(imageData, aspect) {
  if (!aspect) {
    return null;
  }
  let cropWidth = 0;
  let cropHeight = 0;

  if (aspect < 1) {
    // "tall" crop
    cropWidth = Math.min(imageData.height * aspect, imageData.width);
    cropHeight = cropWidth / aspect;
  } else {
    // "wide" or square crop
    cropHeight = Math.min(imageData.width / aspect, imageData.height);
    cropWidth = cropHeight * aspect;
  }

  return {
    ...defaultCropSettings,
    aspect,
    x: Math.floor((imageData.width - cropWidth) / 2),
    y: Math.floor((imageData.height - cropHeight) / 2),
    width: cropWidth,
    height: cropHeight,
  };
}

export function getPreviewCrop(preview, crop) {
  if (!preview) {
    return crop;
  }

  const scaleX = preview.naturalWidth / preview.width;
  const scaleY = preview.naturalHeight / preview.height;

  return {
    ...crop,
    x: crop.x / scaleX,
    y: crop.y / scaleY,
    width: crop.width / scaleX,
    height: crop.height / scaleY,
  };
}

export function getNaturalCrop(preview, crop) {
  if (!preview) {
    return crop;
  }

  const scaleX = preview.naturalWidth / preview.width;
  const scaleY = preview.naturalHeight / preview.height;

  return {
    ...crop,
    x: Math.round(crop.x * scaleX),
    y: Math.round(crop.y * scaleY),
    width: Math.round(crop.width * scaleX),
    height: Math.round(crop.height * scaleY),
  };
}

export const getAspectRatioOptions = (media, type) => {
  const options = [defaultAspectRatioOptions];
  if (type === 'single') {
    const aspectRatio = aspectRatios.find(a => a.forMediaPlaces.includes(media?.place));
    const defaultCrop = getDefaultCrop(media, aspectRatio?.aspect);
    options.push({
      value: aspectRatio.aspect,
      label: aspectRatio.label,
      disabled: aspectRatio.minWidth > defaultCrop.width || aspectRatio.minHeight > defaultCrop.height,
    });
  } else {
    aspectRatios.forEach(aspectRatio => {
      const defaultCrop = getDefaultCrop(media, aspectRatio?.aspect);
      options.push({
        value: aspectRatio.aspect,
        label: aspectRatio.label,
        disabled: aspectRatio.minWidth > defaultCrop.width || aspectRatio.minHeight > defaultCrop.height,
      });
    });
  }
  return options;
};

export const getPreviewSize = (media, adType, sizeType) => {
  if (adType === 'single') {
    return aspectRatios.find(a => a.forMediaPlaces.includes(media?.place))?.previewSizes[sizeType];
  } else {
    return media.crop
      ? aspectRatios.find(a => a.aspect.toString() === media.crop?.aspect.toString())?.previewSizes[sizeType]
      : null;
  }
};

export const buildMediaFromProps = propsMedia => {
  if (propsMedia) {
    const mediaArray = [];
    propsMedia.forEach(line => {
      mediaArray.push({
        place: line.place,
        line_id: line.image_asset?.adtext_line_id || line.video_asset?.adtext_line_id,
        media_id: line.image_asset?.image_id || line.video_asset?.video_id,
        asset_id: line.image_asset?.id || line.video_asset?.id,
        type: line.video_asset ? MEDIA_TYPES.VIDEO : MEDIA_TYPES.IMAGE,
        source: line.image?.url,
        videoSource: line.image?.video_url,
        width: line.image?.width,
        height: line.image?.height,
        crop: line.image_asset?.aspect
          ? {
              x: line.image_asset?.crop_x,
              y: line.image_asset?.crop_y,
              height: line.image_asset?.crop_height,
              width: line.image_asset?.crop_width,
              aspect: line.image_asset?.aspect,
            }
          : null,
        id: v4(),
      });
    });
    return mediaArray;
  } else {
    return [];
  }
};

export const buildCarouselMediaFromProps = propsMedia => {
  if (propsMedia) {
    const mediaArray = [];
    propsMedia.forEach(line => {
      mediaArray.push({
        line_id: line.image_asset?.adtext_line_id || line.video_asset?.adtext_line_id,
        media_id: line.image_asset?.image_id || line.video_asset?.video_id,
        asset_id: line.image_asset?.id || line.video_asset?.id,
        type: line.video_asset ? MEDIA_TYPES.VIDEO : MEDIA_TYPES.IMAGE,
        source: line.image?.url,
        videoSource: line.image?.video_url,
        name: line.image?.filename,
        width: line.image?.width,
        height: line.image?.height,
        crop: line.image_asset?.aspect
          ? {
              x: line.image_asset?.crop_x,
              y: line.image_asset?.crop_y,
              height: line.image_asset?.crop_height,
              width: line.image_asset?.crop_width,
              aspect: line.image_asset?.aspect,
            }
          : null,
        id: v4(),
        headline: line.name,
        description: line.text,
        websiteUrl: line.url,
        errors: line.errors,
        open: !!Object.keys(line.errors).length,
        position: line.position,
      });
    });
    return mediaArray.sort((a, b) => a.position - b.position);
  } else {
    return [];
  }
};

export function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedMinutes = hours > 0 ? minutes.toString().padStart(2, '0') : minutes;
  const formattedSeconds = secs.toString().padStart(2, '0');

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${minutes}:${formattedSeconds}`;
  }
}

export function calculateMD5Base64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      const arrayBuffer = event.target.result;

      // Get raw binary MD5 hash
      const binaryHash = SparkMD5.ArrayBuffer.hash(arrayBuffer, true);

      // Convert to Base64
      const base64Hash = btoa(binaryHash);

      resolve(base64Hash);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export const validateVideoFile = async file => {
  const MAX_SIZE = 4 * 1024 * 1024 * 1024; // 4GB
  const VALID_TYPES = ['video/mp4', 'video/quicktime', 'image/gif'];
  const MIN_DURATION = 1; // 1 second
  const MAX_DURATION = 14460; // 241 minutes
  const MIN_DIMENSION = 120; // 120x120 pixels

  // Size validation
  if (file.size > MAX_SIZE) {
    return false;
  }

  // Type validation
  if (!VALID_TYPES.includes(file.type)) {
    return 'wrong_type';
  }

  if (file.type === 'image/gif') {
    return validateImageFile(file, MAX_SIZE, MIN_DIMENSION, MIN_DIMENSION);
  }

  // Metadata validation
  return new Promise(resolve => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';

    videoElement.onloadedmetadata = () => {
      URL.revokeObjectURL(videoElement.src);

      const duration = videoElement.duration;
      const { videoWidth: width, videoHeight: height } = videoElement;

      if (duration < MIN_DURATION || duration > MAX_DURATION || width < MIN_DIMENSION || height < MIN_DIMENSION) {
        resolve(false);
      } else {
        resolve({ width, height, duration });
      }
    };

    videoElement.onerror = () => resolve(false);

    videoElement.src = URL.createObjectURL(file);
  });
};

export const validateImageFile = async (file, maxSize, minWidth, minHeight) => {
  if (file.size > maxSize) {
    return false;
  }

  if (!['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(file.type)) {
    return false;
  }

  const image = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = entry => {
      const img = new Image();
      img.src = entry.target.result;
      img.onload = () => resolve(img);
      img.onerror = reject;
    };
    reader.readAsDataURL(file);
  });

  if (image.width < minWidth || image.height < minHeight) {
    return false;
  }
  return { width: image.width, height: image.height, isValid: true };
};

export const createVideoPreview = file =>
  new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    videoElement.muted = true; // Ensure no sound playback
    videoElement.src = URL.createObjectURL(file);

    videoElement.onloadedmetadata = () => {
      // Seek to the middle of the video
      const middleTime = videoElement.duration / 2;
      videoElement.currentTime = middleTime;
    };

    videoElement.onseeked = () => {
      // Create a canvas to capture the video frame
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Set canvas dimensions to match video frame
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      // Draw the middle frame of the video onto the canvas
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a File (with a name)
      canvas.toBlob(
        blob => {
          if (blob) {
            const fileName = `${file.name
              .split('.')
              .slice(0, -1)
              .join('.') || 'preview'}.jpg`;
            const previewFile = new File([blob], fileName, { type: 'image/jpeg' });
            resolve(previewFile); // Resolve with the File
          } else {
            reject(new Error('Failed to create preview image'));
          }
        },
        'image/jpeg', // Output format
        0.9 // Quality (90%)
      );

      // Cleanup
      URL.revokeObjectURL(videoElement.src);
    };

    videoElement.onerror = () => {
      URL.revokeObjectURL(videoElement.src);
      reject(new Error('Error loading video file'));
    };
  });

export const createGifPreview = file =>
  new Promise((resolve, reject) => {
    const imgElement = document.createElement('img');
    imgElement.src = URL.createObjectURL(file);

    imgElement.onload = () => {
      // Create a canvas to capture the first frame of the GIF
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Set canvas dimensions to match GIF dimensions
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;

      // Draw the first frame of the GIF onto the canvas
      context.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a File (with a name)
      canvas.toBlob(
        blob => {
          if (blob) {
            const fileName = `${file.name
              .split('.')
              .slice(0, -1)
              .join('.') || 'preview'}.jpg`;
            const previewFile = new File([blob], fileName, { type: 'image/jpeg' });
            resolve(previewFile); // Resolve with the File
          } else {
            reject(new Error('Failed to create preview image'));
          }
        },
        'image/jpeg', // Output format
        0.9 // Quality (90%)
      );

      // Cleanup
      URL.revokeObjectURL(imgElement.src);
    };

    imgElement.onerror = () => {
      URL.revokeObjectURL(imgElement.src);
      reject(new Error('Error loading GIF file'));
    };
  });

export function uploadVideoWithProgress(presignedUrl, file, fileChecksum, onProgress) {
  const xhr = new window.XMLHttpRequest();

  const uploadVideoPromise = new Promise((resolve, reject) => {
    xhr.open('PUT', presignedUrl);

    // Set headers
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.setRequestHeader('Content-MD5', fileChecksum);

    // Track progress
    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        onProgress(progress); // Call the provided callback with the progress
      }
    };

    // Handle upload completion
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(`Upload failed with status: ${xhr.status}`));
      }
    };

    // Handle errors
    xhr.onerror = () => reject(new Error('Network error occurred during upload'));

    // Send the file
    xhr.send(file);
  });

  // Return both the Promise and an abort function
  return {
    uploadVideoPromise,
    uploadVideoAbort: () => xhr.abort(),
  };
}

export const truncateFilename = (filename, maxLength) => {
  if (!filename || maxLength < 3) return filename;

  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex <= 0 || lastDotIndex === filename.length - 1) {
    // No extension or filename ends with a dot
    return filename.length > maxLength ? `${filename.slice(0, maxLength - 3)}...` : filename;
  }

  const namePart = filename.slice(0, lastDotIndex);
  const extension = filename.slice(lastDotIndex);

  if (extension.length + 3 > maxLength) {
    return extension.length > maxLength ? `${extension.slice(0, maxLength - 3)}..` : extension;
  }

  if (filename.length <= maxLength) {
    return filename; // No truncation needed
  }

  const truncatedName = namePart.slice(0, maxLength - 3 - extension.length).replace(/\.+$/, ''); // Remove trailing dots
  return `${truncatedName}..${extension}`;
};
